.elastic-header {
  /* margin-top: 10px; */
  padding: 10px 10px;
  display: inline-block;
  line-height: 1.3;
  margin-bottom: 3px;
  font-family: arial, sans-serif;
  font-size: 20px !important;
  font-weight: 100;
  color: #526fd7 !important;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  text-transform: capitalize;
  box-shadow: 0px 1px 0px rgb(0 0 0 / 20%);
  width: 100%;
}

.elastic-header p {
  color: #526fd7 !important;
  font-weight: 100;
  font-family: arial, sans-serif;
  line-height: 1.3;
  font-size: 16px;
}

.elastic-header2 {
  margin-top: 10px;
  padding: 0px 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 0px rgb(0 0 0 / 20%);
  width: 100%;
}

.elastic-header2 input {
  display: inline-block;
  line-height: 1.3;
  margin-bottom: 3px;
  font-family: arial, sans-serif;
  font-size: 20px !important;
  font-weight: 100;
  color: #526fd7 !important;
  text-decoration: none;
  text-transform: capitalize;
}

.elastic-para {
  color: #4d5156;
  line-height: 1.58;
  text-align: left;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.mainComment2 {
  background-color: #fff;
  overflow: hidden;
  height: calc(100vh - 190px);
  margin-bottom: 0;
  scroll-behavior: smooth;
}

.mainComment2.smrry {
  height: calc(100vh - 96px);
}

.main3 {
  border-radius: 5px;
  position: relative;
  height: 50px !important;
}

.messageEditorWrapper2 {
  background-color: rgb(255, 255, 255);
  position: absolute;
  bottom: -55px;
  width: 100%;
}

.rhap_container {
  border-radius: 0.6rem;
  box-shadow: 0 7px 36px #00000014 !important;
}

.rhap_progress-indicator,
.rhap_progress-filled {
  background: #526fd7 !important;
}

.rhap_download-progress {
  background-color: #526fd7 !important;
  opacity: 0.5;
}

.rhap_time.rhap_current-time {
  color: rgb(5, 41, 75);
  font-size: 16px;
}

.rhap_play-pause-button,
.rhap_forward-button,
.rhap_rewind-button,
.rhap_volume-button {
  color: #526fd7 !important;
}

.rhap_play-pause-button[aria-label="Pause"],
.rhap_volume-button[aria-label="Mute"] {
  color: #5746ec !important;
}

.rhap_volume-bar,
.rhap_volume-indicator {
  background: #526fd7 !important;
}

.rhap_volume-button[aria-label="Mute"] + .rhap_volume-bar,
.rhap_volume-button[aria-label="Mute"] .rhap_volume-indicator {
  background: #5746ec !important;
}

.share-modal {
  justify-content: space-between;
  margin-top: 8% !important;
  position: relative;
}

.rhap_repeat-button {
  display: none !important;
}

.diamod-btn {
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 16px;
}

.drawer-transcript {
  padding: 0 !important;
}

/* .ant-drawer-body {
  padding: 0 !important;
} */

.summary-content .smmry {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2rem;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #05294b;
}

.summary-content span {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #5d7284 !important;
  letter-spacing: 0.005em;
  border-radius: 0.2rem;
  white-space: nowrap;
}

.sdeejd li {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #5d7284 !important;
  letter-spacing: 0.005em;
  border-radius: 0.2rem;
  white-space: nowrap;
  align-items: center;
  margin-right: 20px;
  text-transform: uppercase;
}

#scriptBody p.highlighted {
  background-color: yellow;
  /* Set your desired highlight color */
}

/* Add this to your CSS or use a separate stylesheet */
.custom-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  overflow-y: auto;
  max-height: 200px;
}

.audio-player {
  z-index: 1;
}

.custom-dropdown {
  z-index: 2;
  /* Adjust the value based on your layout */
}

.custom-dropdown div {
  padding: 8px;
  cursor: pointer;
}

.custom-dropdown div:hover {
  background-color: #f5f5f5;
}

.sdfe {
}

.sdfe .cl {
  width: 100%;
}

.smrry {
}

.smrry ul {
}

.smrry ul li {
  box-shadow: 0px 1px 0px rgb(0 0 0 / 20%);
  padding-bottom: 15px;
  padding-left: 24px;
  padding-right: 24px;
}

.smrry .collapse-header {
  font-weight: 500;
  color: #000;
  font-size: 12px;
}

.smrry .collapse-header small {
  font-weight: 400;
  color: #5d7284;
}

.sdfe .nxtCl {
  width: 625px;
}

.collapseBid {
  background-color: #fff !important;
  border-bottom: 1px solid rgb(0 0 0 / 20%) !important;
}

.audio-player {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 0;
  font-family: inherit;
  width: 100%;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 0.6rem;
  box-shadow: 0 7px 36px #00000014 !important;
}

.audio-player .progress_section {
  display: flex;
  flex: 3 1 auto;
  align-items: center;
}

.audio-player .progress_section .progress-contaoiner {
  display: flex;
  align-items: center;
  height: 20px;
  flex: 1 0 auto;
  align-self: center;
  margin: 0 calc(10px + 1%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-button {
  background-color: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
  /* margin: 0 3px; */
  color: #526fd7 !important;
  font-size: 30px;
  width: 35px;
  height: 35px;
}

.seek-bar {
  width: 100%;
  margin-top: 20px;
}

.searchIconRecording {
  cursor: pointer;
  transition: width 0.5s;
  width: 130px;
}

.searchIconRecording.active {
  width: 400px;
}

.searchIconRecording .ant-input-affix-wrapper-lg {
  width: 100%;
  border: 1.5px solid #5746ec !important;
  border-radius: 8px;
}

/* .searchIconRecording.active .ant-input-affix-wrapper-lg {
    border-right: 0 !important;
} */

.searchIconRecording .ant-input-group-addon {
  border: 1.5px solid #5746ec !important;
}

.searchIconRecording.active .ant-input-group-addon {
  border-left: 0 !important;
  background-color: #fff !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.searchIconRecording .ant-input-affix-wrapper-lg,
.searchIconRecording input {
  background-color: #fff !important;
}

.searchIconRecording .ant-input-affix-wrapper:focus,
.searchIconRecording .ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.highlightsWords span {
  cursor: pointer;
}

.highlightsWords span:hover {
  color: #5746ec !important;
}

#scriptBody mark {
  background-color: yellow;
}

.highlightedControl span {
  font-size: 14px;
  display: block;
}

span.words {
  padding-left: 1px;
  padding-top: 1px;
  padding-bottom: 2px;
  border-radius: 4px;
}

.words.highlight {
  background-color: #526fd7 !important;
  color: #fff !important;
}

mark.selected {
  border: 1.5px solid #5746ec;
  border-radius: 4px;
}

.smry-para {
  overflow: auto;
  height: calc(100vh - 190px);
  margin-bottom: 0;
  scroll-behavior: smooth;
}

.css-codomq {
  overflow: auto;
  height: calc(100vh - 190px);
  margin-bottom: 0;
  scroll-behavior: smooth;
}

.full-control .audio-player {
  width: 100%;
  background: linear-gradient(45deg, #d7dbe8, #dceceb, #e1e8da, #ececde, #eadddd, #eee2f0, #e3e4f2, #dcd9ec) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.play-button,
.pause-button,
.stop-button {
  background-color: #5746ec;
  color: white;
}

audio::-webkit-media-controls-panel {
  box-sizing: border-box !important;
  font-family: inherit;
  width: 100%;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 0.6rem;
  box-shadow: 0 7px 36px #00000014 !important;
}

@media (max-width: 1350px) {
  .sdfe .nxtCl {
    width: 420px;
    position: fixed;
    right: -430px;
    transition: right 1s;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }

  .sdfe .nxtCl.active {
    right: 0px;
  }
}

@media (min-width: 1300px) {
  .diamod-btn {
    display: none;
  }
}

@media (max-width: 1350px) {
}
