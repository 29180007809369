.tp-list1 {
  margin-left: 0;
}

.tp-list1 li .las,
.next-list .las {
  font-size: 24px;
  color: #5746ec;
}

.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 2.55rem !important;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 7%);
  font-size: 14px;
  border: 1px solid #dfdfdf;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.dropdown-list {
  height: 300px !important;
  overflow-y: auto;
}

.react-clock {
  width: 115px !important;
  height: 115px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.react-clock__face {
  border: 1px solid rgb(82, 111, 215) !important; 
}

.time-clock {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .08);
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, .06);
  display: inline-block;
  padding: 15px;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all .5s;
  width: 100%;
}

.ant-card-head-title{ 
  color: #5746ec !important;
}

.viewButton {
  background-color: #5746ec;
  color: #fff;
  font-weight: 500;
}

.viewButton:hover {
  background-color: transparent;
  border-color: #5746ec !important;
  color: #5746ec !important;
}