.my-modal {
    width: 46%;
    max-width: 46%;
}

a{
    text-decoration: none;
}

.Colorchange{
    width: 10%;
    max-width: 10%;
}
.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    /* padding-left: 10px; */
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 40px !important;
    width: 100% !important;
    outline: none;
  }

  .react-tel-input .country-list{

  }

  .react-tel-input .country-list li{
    padding: 7px 20px !important;
  }

  .react-tel-input .country-list .search{
    display: flex;
    align-items: center;
  }

  .react-tel-input .country-list .search-box{
    width: 100% !important;
  }

.regitser-page-div {
    margin-top: 23px;
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    /* top:50% */
}

  .not-filled-btn {
    border: 1px solid #5746ec;
    color: #5746ec;
    border-radius: 7px;
    position: relative;
    z-index: 0;
}

.form-outline {
    text-align: left !important;
    font-size: 0.857rem;
    color: #5e5873;
  }
  
  .card-text {
    text-align: left !important;
    color: #6e6b7b;
  }
  
  .cards-title {
    text-align: left !important;
    color: #5e5873;
  }
  
  .main-col {
    max-width: 450px !important;
  }
  
  .inputs-field {
    border: 1px solid #d8d6de !important;
  }

  .calendly-badge-widget{
    position: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .calendly-badge-widget .calendly-badge-content
{
  padding: 14px 30px !important;
}

.search-box {
  text-transform: capitalize !important;
}
