.form-control:focus {
  box-shadow: none;
}

.profile-button {
  box-shadow: none;
  border: none;
}

.profile-button:hover {
  /* background: #682773 */
}

.profile-button:focus {
  box-shadow: none;
}

.profile-button:active {
  box-shadow: none;
}

.back:hover {
  cursor: pointer;
}

.labels {
  font-size: 11px;
}

.add-experience:hover {
  color: #fff;
  cursor: pointer;
}

.drop_area {
  min-width: 90px !important;
  border: 1px solid rgb(212, 217, 223) !important;
  margin-top: 20px;
  height: 50px !important;
}

.drop_area > .sc-dkzDqf > span {
  font-size: 14px !important;
  padding: 5px !important;
}

.drop_area > svg {
  display: none;
}

#text {
  display: none;
}

.fVfcRr {
  position: relative !important;
  margin-top: -26px !important;
}

.search-box {
  width: 250px !important;
}
