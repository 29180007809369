.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 40px !important;
    /* width: 230px !important; */
    outline: none;
  }
  
  .eHNgUP {
      background-color: #f7f7f7;
      padding: 10px !important;
      text-align: left;
      margin-bottom: 10px !important;
      height: auto !important;
      padding-top: 13px !important;
      border: radius 8px !important ;
  }
  
  .fGLnDN {
    margin-top: 7px !important;
    font-size: 10px;
  }
  
  .wrapper{
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to right, #cc2473, #d828c9);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .boxs{
    width: 500px;
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
  }
  
  .input-with-icon-div{
    display: flex;
  }
  
  .custom-input{
    width: 95%;
    border: none;
    outline: none;
  }
  
  .icon-span{
    cursor: pointer;
  }
  
  .tracker-box{
    background: rgb(232, 240, 254);
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: left;
    display: none;
  }
  
  .tracker-box.active{
    display: block;
  }
  
  
  .tracker-box div{
    margin: 5px 0;
    font-size: 12px;
  }
  
  .tracker-box .validated{
    /* color: rgba(255, 255, 255, 0.5); */
  }
  
  .list-icon{
    margin-right: 8px;
  }
  
  .list-icon.green{
    color: green;
  }
  