@charset "utf-8";
/* CSS Document */

@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600&display=swap");
@import url("https://holders.nightlifecoins.com/assets/bower-components/DataTables/datatables.min.css");

*,
::after,
::before {
  box-sizing: border-box;
}

#root.generatePDF {
  background: #fff;
  z-index: 9;
  position: relative;
}

a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
tt,
ul,
var {
  border: 0;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: none !important;
  padding: 0;
  vertical-align: baseline;
  font-family: "Work Sans", sans-serif;
}

.login-page .row > div:nth-child(2) {
  padding-left: 0;
}

.lable-input button {
  color: #000;
  border: none;
}

body {
  margin: 0;
  padding: 0;
  background: #fff;
  background-repeat: repeat-x;
  font-family: "Work Sans", sans-serif;
  font-size: 17px;
  color: #333333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 20px 0;
}

h1 {
  color: #181818;
  font-size: 40px;
  font-weight: 600;
}

h2 {
  font-size: 30px;
  font-weight: 400;
}

a {
  color: inherit;
  outline: none !important;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

button,
button:focus {
  outline: none !important;
}

ol {
  padding-left: 0;
}

ol li {
  list-style: inherit;
}

ul li {
  list-style: none;
}

ul {
  padding-left: 0;
}

li,
ul {
  list-style: none;
}

img {
  vertical-align: top;
  max-width: 100%;
  border: none;
  outline: none;
}

.login-page .container-fluid {
  padding-left: 0;
}

.logo {
  margin: 34px 0 0 40px;
  display: inline-block;
}

.login-left-part {
  background: url(assests/images/logo-bg.png) no-repeat;
  width: 100%;
  position: relative;
  min-height: 100vh;
}

.new-register-page .login-left-part {
  min-height: 100% !important;
  background-size: cover;
}

.back-rgs {
  position: absolute;
  top: 40px;
  left: 57%;
  color: #666666 !important;
  font-size: 18px !important;
  background: none !important;
}

.backs2 {
  color: #666666 !important;
  font-size: 18px !important;
}

.backs2 svg {
  position: relative;
  top: -2px;
}

.expert-login-page .login-left-part {
  min-height: 100vh !important;
}

.expert-login-page .landing-sec-rg {
  margin-top: 130px;
}

.comon-dt-picker .react-date-picker__wrapper {
  border: none !important;
}

.comon-dt-picker {
  width: 50%;
  margin-right: 20px;
  padding: 0;
  display: grid;
  align-content: center;
  justify-content: center;
}

.comon-dt-picker .react-date-picker {
  width: 100%;
  height: 31px;
}

.react-date-picker__clear-button {
  display: none;
}

.add-btn-divs .btn,
.remove-btn {
  padding: 0 !important;
  width: 45px;
  height: 45px;
  background: #f2f1ff !important;
  border: 1.5px solid rgba(87, 70, 236, 0.3) !important;
  border-radius: 10px;
}

.remove-btn {
  margin: 10px 0;
  color: #5746ec !important;
}

.main-re-from-divs textarea.form-control {
  height: 100px;
  resize: none;
}

.new-register-page .row > div:nth-child(2) {
  padding-left: 15px !important;
}

.services1 {
  width: 100%;
}

.back-rgs svg {
  position: relative;
  top: -2px;
}

.logo-in-div {
  margin-top: 40px;
}

.login-left-part h1 {
  font-weight: 500;
  font-size: 35px;
}

.modal-success .modal-header {
  border: none;
}

.success-div h2 {
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.modal-success .modal-dialog .modal-content {
  border-radius: 12px;
}

.success-div p {
  font-size: 18px;
  color: #5c5f62;
}

.modal-success .modal-lg {
  max-width: 680px;
  height: 370px;
}

.success-div {
  margin-bottom: 50px;
}

.modal-success .modal-title {
  visibility: hidden;
}

.img-box-1 {
  position: relative;
  width: 100%;
  display: inline-block;
  position: relative;
  top: -25px;
}

.img1 {
  position: absolute;
  right: 0;
  z-index: 1;
  width: 57%;
}

.register-img img {
  width: 87%;
  margin: 0;
  display: table;
}

.img2 {
  position: absolute;
  z-index: 2;
  margin: auto;
  left: 0;
  right: 0;
  width: 57%;
  top: 110px;
}

.img3 {
  position: absolute;
  z-index: 3;
  margin: auto;
  left: -13px;
  width: 57%;
  top: 30px;
  animation: turn 5s linear infinite;
}

.img4 {
  position: absolute;
  z-index: 4;
  margin: auto;
  right: -1px;
  width: 57%;
  top: 173px;
}

.img5 {
  position: absolute;
  z-index: 5;
  margin: auto;
  left: -13px;
  width: 57%;
  top: 220px;
}

/* aminmation js */

.up-down {
  animation: up-down linear 4s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: up-down linear 4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: up-down linear 4s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation: up-down linear 4s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation: up-down linear 4s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}

.login-page-div {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.comon-fild h4 {
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 500;
}

.comon-fild label {
  font-weight: 400;
  color: #666666;
  font-size: 16px;
  margin-bottom: 8px;
}

.fa-eye-slash:before {
  content: "\f070";
}

.lable-input {
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
}

.inside-login .form-control {
  border-radius: 8px;
}

.inside-login .form-control:focus {
  box-shadow: none !important;
}

.lable-input .form-control {
  border: none;
}

.sp-input > div:first-child {
  width: 13%;
}

.sp-input > div:last-child {
  width: 107%;
}

.field-icon {
  cursor: pointer;
  float: right;
  margin-left: -25px;
  margin-top: -1px;
  position: relative;
  z-index: 2;
}

.login-btn1 {
  color: #f12435;
  font-weight: 500;
}

.login-btn1:hover {
  color: #000;
}

.inside-login .form-group {
  margin-bottom: 20px;
}

.login-btn {
  background: #5746ec !important;
  color: #fff !important;
  width: 100%;
  height: 35px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 6px !important;
  transition: all 0.5s;
  /* line-height: 30px !important; */
}

.register-btn {
  font-size: 16px;
  font-weight: 500;
  color: #5746ec;
  transition: all 0.5s;
}

.login-btn:hover {
  background: #2913ee !important;
}

.register-btn:hover {
  color: #000 !important;
}

.dasb-div {
  padding-top: 20px;
  padding-bottom: 100px;
}

.user-acunt {
  position: relative;
  padding-left: 10px;
}

.user-acunt:after {
  position: absolute;
  right: 12px;
  top: 6px;
  content: "";
  /* dharmendra */
  /* background-image: url(assests/images/svg-bn1.svg); */
  background-repeat: no-repeat;
  width: 13px;
  height: 23px;
  background-size: 75%;
}

.ex-log-dp .dropdown-item {
  border: none !important;
}

.ex-log-dp li:nth-child(3) {
  margin: 10px 0;
}

.ex-log-dp .dropdown-item {
  font-size: 15px !important;
  color: #5c5f62 !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
}

.ex-log-dp .dropdown-item:hover {
  background: none !important;
  color: #5746ec !important;
}

.no-recod-found p {
  color: #5c5f62;
}

.ex-log-dp .dropdown-item span img {
}

.ex-log-dp .dropdown-item span {
  margin-right: 5px;
}

.ex-log-dp li hr {
  width: 88%;
  margin: auto !important;
}

.user-acunt figure {
  margin-right: 10px !important;
}

.user-acunt p {
  font-size: 16px;
  color: #000;
}

.user-acunt a {
  color: #5746ec;
  text-decoration: none;
  font-size: 14px;
}

.comon-select {
  display: flex;
  align-items: center;
  background: #fff;
  border: solid 1px #dfdfdf;
  border-radius: 8px;
  box-shadow: #e7e7e7 1px 3px 4px;
  padding: 0 0 0 16px;
  height: 50px;
  transition: all 0.5s;
  transform: scale(1);
}

.comon-select:hover {
  transform: scale(1.1);
}

.comon-select span {
  position: relative;
  top: -1px;
}

.comon-select .form-select {
  border: none !important;
  color: #4e505b;
  font-size: 14px;
  padding-top: 0;
  padding-bottom: 0;
}

.comon-select .form-select:focus {
  box-shadow: none !important;
}

.body-expart-div {
  padding-top: 10px;
}

.body-expart-div h2 {
  font-size: 30px;
  font-weight: 500;
  color: #000;
}

.body-expart-div h6 {
  color: #5c5f62;
  font-weight: 400;
  margin: 10px 0;
}

.auto-sec h6,
.comon-notes h6 {
  font-size: 14px;
}

.body-expart-div .nav-pills .nav-link.active,
.body-expart-div .nav-pills .nav-link:hover,
.body-expart-div .nav-pills .show > .nav-link {
  background: #fff !important;
  color: #5c5f62;
  font-weight: 500;
}

.body-expart-div .nav-pills {
  background: #e7e7e7;
  border-radius: 10px;
  padding: 0;
  justify-content: space-between;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: fit-content;
  text-align: center;
}

.body-expart-div .nav-pills .nav-link {
  font-weight: 500;
  color: #5c5f62;
  border-radius: 8px;
}

.call-history-table {
  display: inline-block;
  width: 95%;
}

.comon-divs-re {
  background: #fff;
  border-radius: 8px;
  box-shadow: #e7e7e7 1px 4px 8px;
  padding-left: 20px;
  margin-bottom: 20px;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.new-reguest {
  background: #fff !important;
  color: #5746ec !important;
  width: 100%;
  border: solid 1.4px #5746ec !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  height: 40px;
  text-align: center;
  transition: all 0.5s;
  line-height: 24px !important;
}

.new-reguest:hover {
  background: #5746ec !important;
  color: #fff !important;
}

.hover:hover {
  background: #5746ec !important;
  color: #fff !important;
}

.right-divs-2 {
  display: inline-block;
  width: 100%;
  position: relative;
}

.sp-hr {
  width: 90%;
  margin-left: 55px;
}

.notification-div li {
  display: flex;
  margin-bottom: 15px !important;
  width: 100%;
}

.notification-div li > div {
  align-items: center;
  /* border-bottom: 1px solid #f0f0f0; */
  padding-bottom: 10px;
}

.comon-select .form-select {
  color: #000;
}

.notification-div li > div:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.notificaion-dp h4 {
  font-size: 20px;
  font-weight: 600;
}

.number-message1 {
  color: #5746ec;
}

.call-history-table .comon-table-td {
  width: 56%;
  display: inline-block;
}

.call-history-table .auto-td h6 {
  margin: 0;
}

.call-history-table .auto-td h6 span {
  color: #000;
  font-weight: 600;
  font-size: 18px;
}

.call-history-table .option-td p {
  margin: 0;
}

.right-menu-sec ul li a {
  cursor: pointer;
}

/* .right-menu-sec ul li:nth-child(2) a {
  padding: 0 6px;
} */
.new-add-rg2 .row > div:nth-child(2) {
  padding-left: 15px !important;
}

.clear-bnt {
  color: #ea5455;
  font-size: 16px !important;
  text-decoration: none;
}

.account-div,
.cart-dropdown-ne {
  border: none;
  display: block !important;
  left: inherit !important;
  right: 0;
  top: 120%;
  visibility: hidden;
  width: 310px;
}

.ne-usd {
  display: flex;
  align-items: center;
}

.ne-usd span {
  display: flex;
}

.ne-usd span b {
  border-right: 1px solid #666666;
  padding-right: 10px;
  font-weight: 600 !important;
}

.file-sign {
  background: #f2f1ff !important;
  border: 1.5px solid rgba(87, 70, 236, 0.3) !important;
  border-radius: 10px !important;
  width: 100% !important;
  color: #5746ec !important;
}

.last-details {
  background: #ededf0;
  border-radius: 10px;
  padding: 20px;
}

.last-details figure {
  background: #c4c4c4;
  width: 125px;
  height: 67px;
  margin-bottom: 0;
}

.sign-modal-div .modal-title {
  visibility: hidden;
}

.sign-modal-div .modal-header {
  border-bottom: none !important;
}

.last-details h5 {
  color: #000 !important;
  font-size: 16px;
}

.comon-sign1 {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.comon-sign1 .form-control {
  border: none !important;
  background: none !important;
  width: 22% !important;
}

.ne-usd .form-control {
  border: none !important;
  background: none !important;
  height: 30px;
}

.file-upload {
  position: relative;
  background: rgba(87, 70, 236, 0.06);
  border: 1.2px dashed #5746ec;
  border-radius: 8px;
  width: 100%;
  height: 100px;
  cursor: pointer;
}

.file-upload input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  visibility: hidden;
  cursor: pointer;
}

.sign-modal-div .form-check-input {
  position: relative;
}

.sign-modal-div .form-check-input:focus {
  box-shadow: none !important;
}

.sign-modal-div .form-check-input:after {
  width: 22px;
  height: 21px;
  content: "";
  border: solid 1px #b8b8b8;
  overflow: hidden;
  position: absolute;
  left: -3px;
  top: -4px;
  background: #fff;
  border-radius: 50%;
}

.sign-modal-div .form-check-input:checked:after {
  background: #5746ec;
  color: #fff;
  content: "\f00c";
  border: solid 1px #5746ec;
  font-family: "FontAwesome";
  line-height: 20px;
  text-align: center;
  font-size: 14px;
}

.complets-td-divs .modal-header {
  border: none !important;
}

.complets-td-divs h2 {
  font-size: 24px;
  font-weight: 500;
}

.complets-td-divs .modal-title {
  visibility: hidden;
}

.text-a1 {
  color: #5746ec;
  font-weight: 500;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 81%;
}

.text-a1 p {
  margin-bottom: 0;
  margin-left: 10px;
}

.account-div.show,
.cart-dropdown-ne.show {
  top: 100%;
  visibility: visible;
}

.right-divs-2:after {
  position: absolute;
  content: "";
  left: -30px;
  width: 1px;
  height: 149px;
  background: #e7e7e7;
  top: -32px;
}

.main-re-from-divs .in-code {
  width: 15%;
}

.main-re-from-divs .full-number {
  width: 80%;
}

.right-divs-2 h5 {
  font-weight: 500;
  color: #000;
}

.right-divs-2 h5 span {
  color: #5c5f62;
  font-size: 14px;
}

.request-btn {
  background: #5746ec !important;
  color: #fff !important;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  height: 44px;
  text-align: center;
  transition: all 0.5s;
}

.request-btn:hover {
  background: #000 !important;
}

.tp-list1 {
  padding: 0;
  margin: 0 0 0 4px;
}

.next-list li {
  border: solid 1.2px #d9d9d9;
  border-radius: 5px;
  background: #fff;
  padding: 5px 8px;
  display: inline-block;
  font-size: 13px;
  margin-right: 13px;
  font-weight: 500;
  /* cursor: pointer; */
  transition: all 0.5s;
}

/* .next-list li:hover {
  border-color: #f33636;
  color: #f33636;
} */
.tp-list1 li {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  color: #5c5f62;
  margin-right: 15px;
}

.tp-list1 li span {
  margin-right: 10px;
}

/* sidebar */

.main-menu {
  height: auto;
  overflow-y: auto;
  position: fixed;
  /* width: 150px; */
  /* overflow-y: scroll; */
  top: 4rem;
  bottom: 0rem;
}

.content-wrapper {
  background: #f8f8f8;
  display: inline-block;
  min-height: 100vh;
  overflow: hidden;
  padding-left: 244px;
  transition: all 0.4s ease 0s;
  width: 100%;
  display: table-cell;
  vertical-align: bottom;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
}

.top-bar-menu {
  display: flex;
  height: 62px;
  position: fixed;
  right: 0;
  transition: all 0.5s;
  z-index: 94;
  background: #f8f8f8;
}

.notification-div {
  padding-left: 11px;
}

.notification-div h5 {
  font-size: 16px;
  font-weight: 500;
}

.notification-div h6 {
  color: #bbb;
  font-size: 14px;
  margin-left: 11px;
}

.notification-div span {
  font-weight: 400;
  color: #bbb;
  font-size: 13px;
  margin-top: 5px;
}

.bel-icon {
  padding: 0 !important;
  color: #5c5f62;
}

.bel-icon svg {
  color: #5c5f62;
  font-size: 24px;
}

.right-menu-sec ul li:nth-child(1) .nav-link {
  position: relative;
}

.right-menu-sec ul li {
  position: relative;
}

.right-menu-sec ul li:nth-child(1) .nav-link:after {
  background: #fe0000;
  border-radius: 50%;
  content: "";
  font-size: 10px;
  height: 8px;
  overflow: hidden;
  position: absolute;
  right: -5px;
  top: 1px;
  width: 8px;
}

.account-div .dropdown-item {
  /* border-bottom: 1px solid #dadada; */
  padding-bottom: 8px;
}

.account-div .dropdown-item,
.account-div li:nth-child(2) a.dropdown-item {
  /* color: #5c5f62; */
  font-size: 16px;
  /* font-weight: 600; */
}

.account-div {
  border: none !important;
  width: 230px !important;
}

.account-div.show {
  top: 40% !important;
}

.account-div:before {
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  height: 10px;
  position: absolute;
  right: 10px;
  top: -10px;
  width: 10px;
}

.account-div,
.cart-dropdown-ne {
  border: none;
  display: block !important;
  left: inherit !important;
  right: 0;
  top: 120%;
  visibility: hidden;
  width: 310px;
}

.right-menu-sec {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.right-menu-sec ul {
  align-items: center;
  /* width: 300px;
  height: 200px;
  overflow: auto; */
}

.right-menu-sec ul li {
  margin: 0 0;
}

.right-menu-sec .nav-link {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.main-menu ul li a {
  align-items: center;
  color: #5c5f62;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 1px;
  padding: 12px 0 12px 15px;
  text-decoration: none;
  transition: all 0.5s;
  align-items: center;
}

.main-menu ul li h5 {
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 10px;
}

.main-menu ul li {
  margin: 20px 0;
}

.top-bar-menu .serch-sec-div {
  border: solid 1px #f33636;
  height: 43px;
  border-radius: 10px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  overflow: hidden;
}

.top-bar-menu .serch-sec-div .form-control {
  border: none;
  font-size: 15px;
  font-weight: 400;
  background: none !important;
}

.user-div-top figure {
  width: 35px;
}

.form-control:focus {
  box-shadow: none !important;
}

.top-bar-menu .serch-sec-div svg {
  color: #f33636;
}

.main-menu ul li a .menu-icon-new img {
  opacity: 0.6;
  width: 18px;
}

.main-menu ul li a.active,
.main-menu ul li a:hover {
  background: #ededf0;
  border-radius: 10px;
  color: #000;
}

.main-menu ul li a.active .menu-icon-new img,
.main-menu ul li a:hover .menu-icon-new img {
  opacity: 9;
}

/*  swith 03-06-2022 */

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 48px;
  height: 30px;
  background: #9d9d9d;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 23px;
  height: 23px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

.comon-payments-ex {
  width: 95%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  height: 150px;
  display: grid;
  align-content: center;
  padding: 25px;
  margin-bottom: 30px;
  position: relative;
}

.comon-card-n {
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  display: grid;
  align-content: center;
  width: 100%;
  height: 194px;
  padding: 20px;
  transition: all 0.5s;
  transform: scale(1);
}

.comon-card-n:hover {
  transform: scale(1.1);
}

.all-complains {
  display: inline-block;
  width: 95%;
}

.comon-cplens {
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  background: #fff;
  display: inline-block;
  width: 100%;
  padding: 28px;
  margin-bottom: 30px;
  transition: all 0.5s;
}

.comon-cplens h5 {
  text-transform: uppercase;
}

.comon-cplens p {
  font-size: 16px;
  color: #5c5f62;
  margin: 15px 0;
}

.titels-new {
  text-decoration: none;
  color: #000;
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}

.com-li li {
  display: flex;
  align-items: center;
}

.com-li li span:first-child {
  width: 21px;
  display: inline-block;
  margin-right: 2px;
}

.ex-project-details .requset-1btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-ps-modal .modal-title {
  visibility: hidden;
}

.edit-ps-modal .modal-header {
  border: none !important;
}

.edit-ps-modal .add-btn {
  color: #5746ec;
}

.edit-ps-modal p {
  font-weight: 400;
  color: #666666;
  font-size: 15px;
}

.edit-ps-modal a {
  color: #5746ec;
}

.submit-save {
  background: #5746ec !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  height: 45px;
  color: #fff !important;
  transition: all 0.5s;
}

.edit-ps-modal .form-group {
  margin-bottom: 25px !important;
  display: inline-block;
  width: 100%;
}

.edit-ps-modal .form-group label {
  color: #000 !important;
}

.submit-save:hover {
  background: #000 !important;
}

.edit-ps-modal .form-select:focus {
  box-shadow: none !important;
}

.md-titel-link {
  color: #5746ec;
  cursor: pointer;
}

.edit-ps-modal .form-control {
  background: none !important;
}

.ex-project-details .requset-1btn span {
  position: relative;
  margin-right: 5px;
  top: -2px;
}

.text-email-modal .modal-title {
  visibility: hidden;
}

.text-email-modal .modal-header {
  border-bottom: 0 !important;
}

.text-email-modal .modal-dialog {
  max-width: 700px;
}

.area-email .jodit-status-bar {
  display: none;
}

.area-email {
  border: 1px solid #ced4da !important;
  border-radius: 5px;
  position: relative;
  height: 235px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}

.ed-btn {
  cursor: pointer;
}

.area-email .jodit-container:not(.jodit_inline) {
  border-radius: 3px;
  display: flex;
  flex-direction: column-reverse;
  border: none !important;
  min-height: 215px !important;
  height: 215px !important;
  top: 0;
  position: relative;
}

.area-email .jodit-toolbar__box {
  position: absolute !important;
  left: 50%;
  top: 80% !important;
  transform: translateX(-50%);
  margin: auto !important;
}

.totoal-re-div {
  display: inline-block;
  width: 100%;
}

.go2441762052 {
  display: none;
}

.landing-sec-rg {
  margin-top: 30px;
}

.landing-sec-rg h5 {
  color: #5746ec;
}

.landing-sec-rg ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.landing-sec-rg ul li svg {
  color: #5746ec;
  width: 11px;
  height: 11px;
  position: relative;
  top: -2px;
}

.in-btn1 {
  background: #2977b5 !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  width: 100%;
  height: 45px;
  color: #fff !important;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
}

.in-btn1 span {
  margin-right: 10px;
}

.or-sec {
  position: relative;
  text-transform: capitalize;
  color: #666666;
}

.or-sec span {
  background: #fff;
  padding: 10px 25px;
  font-size: 18px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.in-btn1 img {
  position: relative;
  top: -3px;
}

.main-re-from-divs label {
  margin-bottom: 8px;
}

.main-re-from-divs .form-group {
  margin-bottom: 20px;
}

.main-re-from-divs .form-control,
.main-re-from-divs .form-select {
  height: 45px;
  border-radius: 8px !important;
}

.conti-new {
  background: #5746ec !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  width: 500px;
  margin-left: 122px;
  color: #fff !important;
  margin-bottom: 50px;
}

.main-re-from-divs .form-select:focus {
  box-shadow: none !important;
}

.landing-sec-rg ul li span {
  width: 30px;
  display: inline-block;
}

.landing-sec-rg p {
  color: #202020;
  font-size: 16px;
  font-weight: 400;
}

.comon-re-texts {
  position: absolute;
  left: 10%;
  width: 30%;
  color: #fff;
  top: 50%;
  transform: translateY(-50%);
}

.com-li li span img {
  position: relative;
  top: -2px;
}

.delp-img {
  width: 90px;
  display: inline-block;
  margin-right: 10px;
}

.ex-project-details {
  display: inline-block;
  width: 95%;
}

.comon-cplens a {
  color: #5746ec;
  text-transform: uppercase;
  text-decoration: none;
}

.back-new-fun {
  position: absolute;
  top: 50px;
  background: #fff;
  width: 150px;
  height: 40px;
  text-decoration: none;
  left: 58%;
  z-index: 2;
  color: #666666 !important;
  font-size: 18px;
}

.comon-cplens h6 {
  color: #f33636;
  text-transform: uppercase;
}

.comon-card-n h5 {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.comon-card-n h5 span {
  color: #5c5f62;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
}

.payment-details-2 {
  display: inline-block;
  width: 95%;
}

.left-pm {
  display: flex;
  align-items: center;
  width: 100%;
}

.left-pm figure {
  margin-right: 20px;
  margin-bottom: 0;
}

.left-pm h5 {
  font-size: 22px;
  color: #000;
  font-weight: 500;
  line-height: 30px;
}

.left-pm h5 {
  font-size: 20px;
}

.comon-toggle {
  display: flex;
  align-items: center;
  color: #5c5f62;
}

.left-pm h5 span {
  font-size: 16px;
  font-weight: 400;
  color: #5c5f62;
  display: block;
}

/*  end 03-06-2022 */
.comon-ser-btn {
  background-color: #fff !important;
  border-radius: 8px !important;
  border: solid 1px #dfdfdf !important;
  box-shadow: #e7e7e7 1px 4px 8px !important;
  font-size: 15px !important;
  font-weight: 500;
  height: 47px;
  color: #5c5f62 !important;
  margin-right: 15px !important;
  transition: all 0.5s !important;
  transform: scale(1);
}

.comon-ser-btn:hover {
  transform: scale(1.1);
}

.search-section-optios {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}

.comon-ser-btn svg {
  width: 25px;
  height: 25px;
  margin-left: 5px;
}

/* request page */

.search-btn-back {
  font-size: 25px !important;
  font-weight: 500 !important;
  padding-left: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.search-btn-back:focus {
  box-shadow: none !important;
}

.search-btn-back svg {
  color: #5c5f62;
}

.request-page hr {
  margin-left: 0;
  width: 95%;
  background: #d7d4d4 !important;
  opacity: 9 !important;
  margin-top: 32px;
  margin-bottom: 22px;
}

.right-section-div .top-right-projects hr {
  width: 100%;
}

.client-teams-ul-li .dropdown li {
  margin-bottom: 8px;
}

.client-teams-ul-li .dropdown-item {
  font-size: 13px;
  margin-bottom: 0;
}

.projects-pages .comon-table-td figure img {
  width: 18px;
}

.projects-pages .comon-table-divs .number-list li img {
  width: 16px;
}

.projects-pages .comon-table-divs .number-list li span {
  margin-right: 2px;
}

.projects-pages .comon-table-divs .number-list li {
  margin: 0 8px;
}

.left-section-divs {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  /* border-radius: 12px; */
  /* display: inline-block; */
  width: 100%;
  /* padding: 25px; */
}

.left-section-divs h2 {
  color: #000;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}

.left-section-divs ul li {
  margin-right: 15px;
  color: #5c5f62;
  font-size: 15px;
  font-weight: 500;
}

.left-section-divs ul li span {
  position: relative;
  top: -1px;
}

.left-section-divs h5 {
  color: #5c5f62;
}

.left-section-divs p {
  font-size: 15px;
  font-weight: 400;
  color: #5c5f62;
}

.right-section-div {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  display: inline-block;
  width: 100%;
  padding: 25px;
}

.right-section-div h2 {
  font-size: 22px;
  color: #000;
  font-weight: 500;
}

.right-section-div h2 span {
  color: #5c5f62;
  font-size: 16px;
  font-weight: 400;
}

.requset-1btn {
  background: #5746ec !important;
  color: #fff !important;
  border-radius: 7px;
  height: 44px;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  font-size: 16px !important;
  font-weight: 500 !important;
  transition: all 0.5s !important;
  line-height: 28px !important;
}

.sp-are-sec .jodit-react-container {
  height: 131px;
  overflow: hidden;
}

.requset-1btn:hover {
  background: #000 !important;
}

.right-section-div ul li {
  display: flex;
  color: #5c5f62;
  font-size: 15px;
  font-weight: 400 !important;
}

.right-section-div ul li figure {
  margin: 0 !important;
  display: inline-block !important;
  width: 25px;
  text-align: center;
}

.text-ex {
  width: 301px;
  padding-left: 15px;
}

.list-btn3 {
  width: 100% !important;
}

.left-section-divs {
  width: 804px;
  margin-right: 15px;
}

.right-section-div {
  width: 501px;
}

/* projects page */
.btn:focus {
  box-shadow: none !important;
}

/* .m-queries {
  border: 1px solid;
} */
.add-ps-btn {
  background: #f8f8f8 !important;
  border: 1.5px solid #5746ec !important;
  border-radius: 10px !important;
  color: #5746ec !important;
}

.add-ps-btn svg {
  width: 12px;
  height: 12px;
  display: inline-block;
  position: relative;
  top: -1px;
  left: -2px;
}

.btn svg {
  width: 12px;
  height: 12px;
  display: inline-block;
  position: relative;
  top: -1px;
  left: -2px;
}

.dropdown-item {
  padding: 0.45rem 1rem !important;
}

.top-ps-seection .nav {
  background: #e7e7e7;
  border-radius: 10px;
  padding: 0;
  justify-content: space-between;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.projects-scope .top-ps-seection {
  width: 100%;
}

.top-ps-seection .nav-pills .nav-link {
  width: 180px;
}

.top-ps-seection .nav-pills .nav-link:first-child {
  margin-left: 0;
}

.top-ps-seection .nav-pills .nav-link:last-child {
  margin-right: 0;
}

.top-ps-seection .nav-pills .nav-link.active,
.top-ps-seection .nav-pills .nav-link:hover {
  color: #f33636 !important;
}

#ceo th {
  padding: 10px 0 10px 20px;
  display: inline-block;
  margin: 12px 0;
}

#ceo td {
  padding: 10px 0 10px 20px;
}

#ceo th {
  height: 50px;
  line-height: 29px;
}

.w-66 {
  width: 66px !important;
}

.comon-table-divs tbody tr td {
  vertical-align: top;
}

.comon-table-divs tbody tr .w-300 a {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}

.progress {
  height: 5px !important;
  border-radius: 10px !important;
  margin-bottom: 0px;
}

.progress-bar {
  background: linear-gradient(90.11deg, #03c4dc 6.71%, #5746ec 123.12%) !important;
  border-radius: 10px;
}

.number-list {
  justify-content: center;
}

.number-list li {
  font-size: 14px;
  font-weight: 500;
  margin: 0 5px;
  align-items: center;
}

.date {
  font-size: 14px;
}

.date h6 {
  color: #000;
  margin-bottom: 5px;
  margin: 0;
  white-space: nowrap !important;
}

.date p {
  color: #bbb;
}

.user-tabale-td span {
  background: #b8b8b8;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  line-height: 27px;
  border: solid 2px #fff;
}

.number-message {
  width: 25px;
  height: 25px;
  background: #f12435;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  color: #fff;
  font-size: 15px;
  line-height: 27px;
}

.nm1 p {
  font-size: 14px;
  color: #5c5f62;
}

.user-tabale-td a {
  background: #d1cbff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  line-height: 27px;
  border: solid 2px #fff;
  margin-left: -10px;
}

.number-member h6 {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin: 0;
}

.number-member a {
  color: #5746ec !important;
  font-size: 13px;
  font-weight: 600;
}

.user-tabale-td span:nth-child(2),
.user-tabale-td span:nth-child(3),
.user-tabale-td span:nth-child(4) {
  margin-left: -10px;
}

.number-list li img {
  margin-right: 5px;
  position: relative;
  top: -2px;
}

.comon-table-divs tbody tr {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  height: 145px;
  background: #fff;
}

.noafter:after {
  display: none !important;
}

#ceo th {
  color: #5c5f62;
  font-weight: 500;
  border-left: 0 !important;
  border-right: 0 !important;
  border: 1px solid #d7d4d4;
  border-bottom: 1px solid #d7d4d4;
  position: relative;
  text-transform: uppercase;
  font-size: 15px;
}

.w-300 {
  width: 257px;
}

.w-160 {
  width: 180px;
}

.w-1001 {
  width: 148px;
}

.w-2001 {
  width: 170px;
}

.addmodal #ceo th:after {
  display: inline-block;
  content: "";
  margin-left: 10px;
  background-image: url(assests/images/arrow-tabale-list.svg);
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 34%;
}

#ceo th.asc:after {
  display: inline-block;
  content: "";
  background-image: url(assests/images/arrow-tabale-list.svg);
  width: 15px;
  height: 15px;
  position: relative;
  top: 3px;
}

#ceo th.desc:after {
  display: inline-block;
  content: "";
  background-image: url(assests/images/arrow-tabale-list.svg);
  width: 15px;
  position: relative;
  top: 3px;
  height: 15px;
}

.number-list {
  padding-bottom: 20px;
}

.number-list li {
  display: flex;
}

.comon-table-divs table {
  border-collapse: collapse separate;
  border-spacing: 30px;
}

.comon-table-divs tbody {
}

.comon-table-divs tbody tr {
  padding-top: 10px;
  width: 100%;
  border-radius: 12px;
  display: block;
}

.comon-table-divs tbody tr td {
  background: #ffffff;
  margin: 15px 0;
}

.comon-table-divs tbody tr td:first-child {
  border-bottom-left-radius: 10px;
  border-color: #fff;
  border-collapse: collapse;
  border-radius: 12px 0 0 12px;
}

.comon-table-divs tbody tr td:last-child {
  border-bottom-left-radius: 10px;
  border-color: #fff;
  border-collapse: collapse;
  border-radius: 0 12px 12px 0;
}

.main-re-from-divs h5 {
  color: #5746ec;
}

.main-re-from-divs h2 {
  margin-bottom: 30px;
}

#ceo td {
}

.mainl {
  border: 1px solid #ced4da !important;
  border-radius: 5px;
  position: relative;
  height: 235px;
}

.main2 {
  /* border: 1px solid #ced4da !important; */
  border-radius: 5px;
  position: relative;
  height: 150px;
}

.jodit-container:not(.jodit_inline) .jodit-editor__resize svg {
  display: none !important;
}

.mainl .form-control {
  border: none !important;
  height: 100px !important;
  resize: none;
}

.main2 .form-control {
  border: none !important;
  height: 100px !important;
  resize: none;
}

.pro-jects1 .form-group {
  margin-bottom: 15px;
}

.pro-jects1 .modal-title {
  visibility: hidden;
}

.btnsm {
  background: #5746ec !important;
  color: #fff !important;
  height: 40px !important;
  display: table !important;
}

.btntransparent {
  border: 1px solid lightgrey;
  height: 40px !important;
  display: table !important;
}

/* .pro-jects1 .modal-header {
  border-bottom: none !important;
} */

.pro-jects1 label {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 16px;
  color: #000;
}

.pro-jects1 p {
  font-size: 14px;
}

.mainl-c {
  margin: auto;
  display: table;
  text-align: center;
  margin-bottom: 10px;
}

/* .avatarImg img {
  border: 2px solid #fff;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 20%);
  width: 30px;
  height: 30px;
} */

.main2-c {
  margin: auto;
  display: table;
  text-align: center;
  margin-bottom: 10px;
}

.number-member a,
.user-tabale-td a {
  cursor: pointer;
}

.new-input1 {
  border: 1px solid #ced4da;
  height: 54px;
  border-radius: 5px;
  align-items: center;
  padding: 0 15px;
}

.new-input1 input {
  border: none !important;
  outline: none !important;
  background: none !important;
  width: 90%;
  height: 54px;
}

.projects-modal-divs label {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
}

.projects-modal .modal-header {
  border: none !important;
}

.projects-modal h5 {
  visibility: hidden;
}

.projects-modal .back-n-bt {
  position: absolute;
  left: 0;
  top: 0;
}

.projects-modal-divs .form-group {
  margin-bottom: 15px;
}

.cm-modal-all .modal-header {
  border: none;
}

.cm-modal-all .modal-header h5 {
  /* visibility: hidden; */
}

.cm-modal-all .form-group {
  margin-bottom: 15px;
}

.cm-modal-all .form-group label {
  color: #000;
  margin-bottom: 10px;
}

.inside-comon-modal form {
  margin-top: 35px;
}

.cm-modal-all .modal-dialog {
  max-width: 700px !important;
}

.dropdown-item {
  cursor: pointer;
}

.cm-modal-all .submit-bn {
  background: #5746ec !important;
  font-size: 16px !important;
  color: #fff !important;
  font-weight: 500 !important;
  width: 400px;
  display: table;
  margin: auto;
  transition: all 0.5s;
}

.file-upload {
  border: 1px dashed #5746ec;
  background: rgba(87, 70, 236, 0.06);
  height: 108px;
  position: relative;
  border-radius: 5px;
}

.file-upload__label {
  border-radius: 30px;
  color: #5746ec !important;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  left: 50%;
  outline: none;
  padding: 15px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-user-select: none;
  white-space: nowrap;
  width: 100%;
  display: flex;
  justify-content: center;
}

.file-upload__label span {
  margin-right: 10px;
}

.file-upload__input {
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.cm-modal-all .submit-bn:hover {
  background: #000 !important;
}

.message-modal {
  border: 1px solid #ced4da;
  height: 235px;
  border-radius: 5px;
  position: relative;
}

.message-modal .form-control {
  border: none !important;
  resize: none !important;
  height: 180px;
}

.menmber-icons .mb-icons {
  margin-left: -20px;
}

.menmber-icons .mb-icons:first-child {
  margin-left: -10px;
}

.menmber-icons span {
  font-weight: 500;
  color: #000;
  font-size: 16px;
}

.message-text-area {
  width: 100%;
  display: inline-block;
  background: #fff;
  height: 560px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  margin-top: 30px;
}

.inside-message1 {
  height: 400px;
  width: 100%;
  padding: 0 80px;
  overflow-y: scroll;
}

.admin-text {
  width: 600px;
  display: grid;
  justify-content: flex-end;
  margin-left: auto;
}

.menmber-text {
  width: 600px;
  display: grid;
  justify-content: flex-end;
  margin-left: 0;
}

.inside-message1 > div {
  margin-bottom: 30px;
}

.body-expart-div .expert-tables .nav-pills .nav-link.active,
.body-expart-div .expert-tables .nav-pills .nav-link:hover,
.body-expart-div .expert-tables .nav-pills .show > .nav-link {
  background: #5746ec !important;
  color: #fff;
  font-weight: 500;
}

.body-expart-div .expert-tables .nav {
  justify-content: center;
}

.body-expart-div .expert-tables .comon-table-divs table {
  width: 100%;
}

.body-expart-div .expert-tables .comon-table-divs table tbody tr {
  height: 81px;
  padding-top: 7px;
}

.comon-tabs-expert th:first-child,
.body-expart-div .expert-tables .comon-table-divs table tbody td:first-child {
  width: 66px;
}

.comon-tabs-expert th:nth-child(2),
.body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(2) {
  width: 109px;
}

.comon-tabs-expert th:nth-child(3),
.body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(3) {
  width: 336px;
}

.comon-tabs-expert th:nth-child(4),
.body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(4) {
  width: 170px;
}

.comon-tabs-expert th:nth-child(5),
.body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(5) {
  width: 143px;
}

.comon-tabs-expert th:nth-child(6),
.body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(6) {
  width: 143px;
}

.submited-text {
  background: #18ce0f;
  border-radius: 8px;
  font-size: 13px;
  color: #dee6e0;
  font-weight: 400;
  width: 90px;
  height: 31px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
}

.requested-text {
  background: rgba(44, 168, 255, 0.1);
  border-radius: 8px;
  font-size: 13px;
  color: rgba(44, 168, 255, 1);
  font-weight: 400;
  width: 90px;
  height: 31px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  border: 1px solid rgba(44, 168, 255, 1);
}

.scheduled-text {
  background: rgba(255, 178, 54, 0.1);
  border-radius: 8px;
  font-size: 13px;
  color: rgba(255, 178, 54, 1);
  font-weight: 400;
  width: 90px;
  height: 31px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  border: 1px solid rgba(255, 178, 54, 1);
}

.completed-text {
  background: rgba(196, 10, 54, 0.1);
  border-radius: 8px;
  font-size: 13px;
  color: rgba(196, 10, 54, 1);
  font-weight: 400;
  width: 90px;
  height: 31px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  border: 1px solid rgba(196, 10, 54, 1);
}

.declined-text {
  background: rgba(128, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 13px;
  color: rgba(128, 0, 0, 1);
  font-weight: 400;
  width: 90px;
  height: 31px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  border: 1px solid rgba(128, 0, 0, 1);
}

.serch-divs input {
  border: none;
  width: 90%;
  margin-left: 10px;
}

.converstion-divs .nav .nav-link {
  display: flex;
  align-items: center;
  margin-right: 0;
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
  background: #ffffff;
  border: 1.5px solid #fff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}

.comon-chats-divs .users-details {
  background: #ededf0;
  border-radius: 12px;
  padding: 10px;
}

.user-status h5 {
  font-size: 16px;
  font-weight: 500;
}

.one-ex-btn {
  background: #ffffff;
  border: 1.5px solid #5746ec !important;
  border-radius: 8px !important;
  color: #5746ec !important;
  font-size: 16px;
  font-weight: 500;
  width: 54%;
}

.more-ex-btn {
  background: #5746ec !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  font-size: 16px;
  font-weight: 500;
  width: 54%;
  margin-left: 15px;
  color: #fff !important;
}

.notes {
  display: flex;
  color: #5c5f62;
}

.ps-titel {
  text-decoration: none;
  color: #000000;
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}

.notes span:last-child {
  margin-left: 15px;
}

.type-chat-sec {
  position: absolute;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.14);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  padding: 0 25px;
}

.file-btn {
  width: 20px;
  height: 20px;
  cursor: pointer !important;
  position: relative;
}

.type-chat-sec .form-group {
  width: 85%;
}

.emojee-btn {
  cursor: pointer;
}

.emojee-btn img {
  width: 20px;
}

.file-btn input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  cursor: pointer !important;
  opacity: 0;
  appearance: none;
}

.projects-pages .top-ps-seection .nav {
  justify-content: center;
}

.expert-cell-page .top-ps-seection .nav {
  justify-content: center;
}

.expert-cell-page .top-ps-seection .nav-pills .nav-link {
  width: 235px;
  margin-right: 0;
}

.expert-cell-page .comon-table-divs th:first-child,
.expert-cell-page .comon-table-divs tr td:first-child {
  width: 400px;
}

.expert-cell-page .comon-table-divs th:nth-child(2),
.expert-cell-page .comon-table-divs tr td:nth-child(2) {
  width: 180px;
}

.expert-cell-page .comon-table-divs th:nth-child(3),
.expert-cell-page .comon-table-divs tr td:nth-child(3) {
  width: 150px;
}

.expert-cell-page .comon-table-divs th:nth-child(3),
.expert-cell-page .comon-table-divs tr td:nth-child(3) {
  width: 150px;
}

.expert-cell-page .comon-table-divs th:last-child,
.expert-cell-page .comon-table-divs tr td:last-child {
  width: 124px;
}

.expert-cell-page .comon-table-divs th:last-child::after {
  /* display: none !important; */
}

.collab-table #ceo .file-name:after {
  background-image: url(assests/images/arrow-tabale-list.svg) !important;
  background-repeat: no-repeat;
  content: "";
  display: inline-block !important;
  height: 15px;
  margin-left: 10px;
  position: absolute;
  top: 34%;
  width: 15px;
}

.collab-table #ceo .comments:after {
  background-image: url(assests/images/arrow-tabale-list.svg) !important;
  background-repeat: no-repeat;
  content: "";
  display: inline-block !important;
  height: 15px;
  margin-left: 10px;
  position: absolute;
  top: 34%;
  width: 15px;
}

.collab-table #ceo .createdby:after {
  background-image: url(assests/images/arrow-tabale-list.svg) !important;
  background-repeat: no-repeat;
  content: "";
  display: inline-block !important;
  height: 15px;
  margin-left: 10px;
  position: absolute;
  top: 34%;
  width: 15px;
}

.expert-cell-page .comon-table-td {
  text-decoration: none;
  font-size: 15px;
  color: #000;
}

.expert-cell-page .comon-table-divs h6 {
  margin: 0;
  color: #000;
}

.expert-cell-page .comon-table-divs p {
  color: #5c5f62;
  font-weight: 400;
  font-size: 13px;
}

.expert-cell-page .comon-table-td span {
  width: 85%;
}

.expert-cell-page .comon-table-td ul {
  margin-top: 10px;
}

.expert-cell-page .comon-table-td ul li {
  margin-right: 10px;
}

.type-chat-sec .form-control {
  background: none !important;
  border: none !important;
}

.managment-table .comon-table-divs th:first-child,
.managment-table .comon-table-divs tr td:first-child {
  width: 240px;
}

.managment-table .comon-table-divs th:nth-child(2),
.managment-table .comon-table-divs tr td:nth-child(2) {
  width: 246px;
}

.managment-table .comon-table-divs th:nth-child(3),
.managment-table .comon-table-divs tr td:nth-child(3) {
  width: 160px;
}

.managment-table .comon-table-divs th:nth-child(4),
.managment-table .comon-table-divs tr td:nth-child(4) {
  width: 129px;
}

.managment-table .comon-table-divs th:nth-child(5),
.managment-table .comon-table-divs tr td:nth-child(5) {
  width: 120px;
}

.managment-table .comon-table-divs tr td:nth-child(5) svg {
  color: #299c46;
}

.managment-table .comon-table-divs th:nth-child(6),
.managment-table .comon-table-divs tr td:nth-child(6) {
  width: 66px;
}

.managment-table .comon-table-divs h6 {
  margin: 0 !important;
  font-size: 15px;
}

.managment-table .comon-table-divs tbody tr {
  height: 50%;
  padding-top: 0;
}

.managment-table .comon-table-divs tbody td {
  vertical-align: middle;
}

.user-name {
  text-decoration: none;
  color: #000;
  font-size: 15px;
}

.support-btn {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px !important;
  display: flex !important;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 0 15px !important;
  position: relative;
}

.faq-section {
  width: 95%;
  display: inline-block;
}

.search-mobile-s {
  display: none !important;
}

.faq-section .accordion-button:not(.collapsed) {
  background: none !important;
  color: #000 !important;
  box-shadow: none !important;
  border-top: solid 1px #fff !important;
}

.faq-section .accordion-item {
  border: none !important;
}

.faq-section .accordion > div:last-child .accordion-button {
  border-bottom: none !important;
}

.faq-section .accordion-button {
  border-bottom: solid 1px #d7d4d4;
}

.descrip-texarea {
  height: 150px;
  resize: none;
}

.comon-news {
  background: #fff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  height: 472px;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
}

.comon-news h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}

.comon-news p {
  font-size: 14px;
  font-weight: 400;
  color: #202020;
  margin: 20px 0;
}

.news-section {
  display: inline-block;
  width: 95%;
}

.comon-news figure {
  height: 180px;
  overflow: hidden;
}

.comon-news .tags {
  background: #e7e7e7;
  border-radius: 10px;
  /* height: 35px; */
  display: inline-block;
  text-align: center;
  /* width: 110px; */
  /* line-height: 35px; */
  margin: 0;
  padding: 7px 7px;
}

.comon-news figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.faq-section .accordion-button:focus {
  box-shadow: none !important;
}

.faq-section .accordion-button:after {
  transform: rotate(-89deg) !important;
}

.faq-section .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.show .accordion-body {
  border-bottom: solid 1px #d7d4d4;
}

.support-btn b {
  font-weight: 600;
  color: #000 !important;
  font-size: 16px !important;
}

.support-btn img {
  margin-right: 10px;
}

.support-btn span {
  text-align: left;
  font-size: 14px;
  color: #202020 !important;
}

.inside-support {
  display: inline-block;
  width: 100%;
}

.user-name img {
  margin-right: 10px;
}

.online {
  color: #299c46;
  font-size: 14px;
  display: flex;
  float: right;
  align-items: center;
  margin-left: 10px;
}

.all-chat-divs {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  height: 450px;
  margin-top: 25px;
  padding: 0 40px;
  overflow: auto;
  display: inline-block;
  width: 100%;
}

.all-chat-divs .admin-text {
  width: 380px;
}

.all-chat-divs .menmber-text {
  width: 380px;
}

.all-chat-divs > div {
  margin-bottom: 30px;
}

.all-chat-divs .content {
  font-size: 14px !important;
}

.comon-chats-divs .users-details figure {
  width: 40px;
}

.chat-divs-scetion {
  position: relative;
  height: 508px;
  display: inline-block;
  width: 100%;
}

.converstion-divs .nav .nav-link figure {
  margin: 0;
}

.converstion-divs .nav .nav-link h5 {
  text-align: left;
  font-size: 15px;
  color: #000;
  font-weight: 500;
  margin: 0;
  margin-left: 15px;
  padding-right: 5px;
  width: 90%;
}

.serch-divs .form-group {
  border: 1px solid rgba(92, 95, 98, 0.2) !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
  height: 50px;
  display: flex;
  align-items: center;
}

.converstion-divs .nav .nav-link h5 span {
  color: #5c5f62;
  font-weight: 400;
  font-size: 13px;
}

.converstion-divs .nav .nav-link h6 {
  width: 30px;
  height: 30px;
  background: #f33636;
  color: #fff;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  line-height: 30px;
  overflow: auto;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  margin: 0;
}

.admin-text .content {
  background: #efecec;
  border-radius: 15px 15px 0px 15px;
  padding: 10px 15px;
  color: #000;
  font-size: 16px;
  font-weight: 400 !important;
  display: inline-block;
  margin-bottom: 0 !important;
}

.text-area-type {
  width: 700px;
  border: 1px solid #ced4da;
  border-radius: 16px;
}

.text-area-type .form-control {
  border: none !important;
  background: none !important;
  height: 50px;
  resize: none;
  text-align: center;
  padding-top: 15px;
}

.menmber-text .content {
  background: #5746ec;
  border-radius: 15px 15px 15px 0;
  padding: 10px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 400 !important;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 0 !important;
  max-width: 60%;
}

.menmber-text h6 {
  margin-left: auto !important;
  display: table;
}

.admin-text h6,
.menmber-text h6 {
  font-size: 14px;
  margin: 8px 0 0;
  color: #5c5f62;
}

.more-btn-show {
  background: #d1cbff;
  border: 3px solid #ffffff;
  width: 44px;
  height: 44px;
  display: inline-block;
  line-height: 40px;
  border-radius: 50%;
  margin-left: -20px;
  text-align: center;
  text-decoration: none;
  position: relative;
  top: 3px;
  transition: all 0.5s !important;
  color: #5746ec !important;
}

.more-btn-show:hover {
  background: #5746ec !important;
  color: #fff !important;
}

.comon-history-expert {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  display: grid;
  width: 95%;
  height: 208px;
  align-content: center;
  padding-right: 40px;
}

.left-hisroty-expert {
  display: flex;
  flex-wrap: wrap;
}

.left-hisroty-expert h5 {
  margin-bottom: 20px;
}

.serhisiroy-right {
  height: 100%;
  justify-content: flex-end;
  position: relative;
}

.serhisiroy-right:after {
  position: absolute;
  left: -24px;
  top: 0;
  height: 151px;
  width: 1px;
  background: #d7d4d4;
  content: "";
}

.nes-tabs a {
  text-decoration: none;
  color: #000;
  line-height: 23.46px;
}

.nes-tabs ul {
  margin-top: 10px;
}

.nes-tabs ul li {
  margin: 0 10px;
  color: #5746ec;
  text-transform: capitalize;
}

.nes-tabs ul li img {
  position: relative;
  top: -2px;
}

.nes-tabs ul li:first-child {
  margin-left: 0;
}

.serhisiroy-right h5 {
  font-size: 21px;
  font-weight: 500;
  color: #000000;
}

.serhisiroy-right h5 span {
  font-size: 16px;
  font-weight: 400;
  color: #b8b8b8;
}

.serhisiroy-right .edite-bnt {
  background: #5746ec;
  border-radius: 7px;
  color: #fff !important;
  margin-left: 40px;
  font-size: 14px;
  width: 50%;
  height: 40px;
  line-height: 25px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.serhisiroy-right .edite-bnt:hover {
  background: #000;
}

.serhisiroy-right .edite-bnt img {
  margin-right: 5px;
}

.left-hisroty-expert figure {
  width: 18%;
  display: table;
  text-align: right;
  padding-right: 30px;
}

.detsils-secch-comon {
  width: 78%;
  display: inline-block;
}

.detsils-secch-comon ul li {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  color: #5c5f62;
  margin: 0 10px;
}

.detsils-secch-comon ul li span:first-child {
  margin-right: 5px;
  text-align: center;
  width: 23px;
  display: inline-block;
}

.detsils-secch-comon ul li img {
  position: relative;
  top: -2px;
}

.detsils-secch-comon ul li:first-child {
  margin-left: 0;
}

.doller {
  width: 15px !important;
  height: 19px;
}

@media (max-width: 1950px) {
  .w-300 {
    width: 339px;
  }
}

@media (max-width: 1900px) {
  .conti-new {
    width: 500px;
    margin-left: 120px;
  }

  .landing-banner,
  .home-banner {
    min-height: 601px;
  }

  .user-mg-page .comon-table-divs th:last-child,
  .user-mg-page .comon-table-divs tr td:last-child {
    width: 62px !important;
  }

  .user-mg-page .comon-table-divs th:nth-child(5),
  .user-mg-page .comon-table-divs tr td:nth-child(5) {
    width: 180px;
  }

  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(4) {
    width: 180px;
  }

  .user-mg-page .comon-table-divs th:nth-child(3),
  .user-mg-page .comon-table-divs tr td:nth-child(3) {
    width: 180px;
  }

  .expert-cell-page .comon-table-divs th:first-child,
  .expert-cell-page .comon-table-divs tr td:first-child {
    width: 460px;
  }

  .sp-hr {
    margin-left: 55px;
  }

  .sp-hr {
    margin-left: 80px;
  }

  .search-section-optios {
    padding-left: 80px;
  }

  .request-body-sec {
    margin-bottom: 50px;
  }

  .projects-details-page {
  }

  .right-small-icons li {
    align-items: baseline;
  }

  .right-small-icons .red-icon {
    width: 41px;
  }

  .projects-details-page .right-section-div {
    background: none;
    box-shadow: none;
    border: none;
    padding: 0;
  }

  .projects-details-page .top-right-projects {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    display: inline-block;
    width: 100%;
    padding: 25px;
  }

  .projects-details-page .middle-right {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    display: inline-block;
    width: 100%;
    padding: 25px;
  }

  .right-small-icons li span:last-child {
    font-size: 25px;
    font-weight: 600;
    color: #000;
  }

  .right-small-icons li span:last-child small {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #5c5f62;
  }

  /* 24-05-2022 */
  .table-sortable th {
    cursor: pointer;
  }

  .table-sortable .th-sort-asc::after {
    content: "\25b4";
  }

  .table-sortable .th-sort-desc::after {
    content: "\25be";
  }

  .table-sortable .th-sort-asc::after,
  .table-sortable .th-sort-desc::after {
    margin-left: 5px;
  }

  .table-sortable .th-sort-asc,
  .table-sortable .th-sort-desc {
    background: rgba(0, 0, 0, 0.1);
  }

  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 164px;
    margin: auto;
    text-align: center;
  }

  .client-temas-div {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    display: inline-block;
    width: 100%;
    padding: 25px;
  }

  .add-pls {
    background: #f8f8f8 !important;
    border: 1.5px solid #5746ec !important;
    border-radius: 10px !important;
    font-size: 15px !important;
    color: #5746ec !important;
  }

  .client-teams-ul-li ul {
    margin: 0;
  }

  .client-teams-ul-li ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .projects-details-page .client-teams-ul-li .teams-li figure {
    float: left;
    margin: 0 10px 0 0 !important;
  }

  .client-teams-ul-li .teams-li {
    margin-bottom: 15px;
    width: 100%;
  }

  .client-teams-ul-li .teams-li h5 {
    font-size: 16px;
    font-weight: 500;
    float: right;
  }

  .client-teams-ul-li .teams-li h5 span {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    padding-top: 5px;
  }

  .client-teams-ul-li .teams-li figure {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
  }

  .add-pls svg {
    width: 12px;
    height: 12px;
  }

  .sp-dots1 {
    width: 25px;
  }

  /* collaborate */

  .collaborate-page .top-ps-seection {
    width: 100%;
  }

  .comon-notes {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 24px;
    display: inline-block;
    width: 100%;
    transition: all 0.5s;
    transform: scale(1);
  }

  .comon-notes:hover {
    /* transform: scale(1.1); */
  }

  .comon-notes .note-titel {
    color: #5746ec;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
    text-decoration: none;
    display: inline-block;
  }

  .loading-btn {
    background: #e7e7e7 !important;
    border-radius: 10px !important;
    width: 203px;
    height: 49px;
    line-height: 32px !important;
    color: #5c5f62 !important;
    font-weight: 500;
    font-size: 16px !important;
    transition: all 0.5s !important;
  }

  .collaborate-page .add-ps-btn {
    background: #f2f1ff !important;
  }

  .recente-vew-divs {
  }

  .recente-vew-divs .comon-table-divs table {
    width: 100%;
  }

  .recente-vew-divs .comon-table-divs #ceo th {
    border-top: none !important;
  }

  .recente-vew-divs .comon-table-divs #ceo th:first-child {
    border: none !important;
  }

  .recente-vew-divs .collab-table #ceo th:first-child {
    border-bottom: 1px solid #d7d4d4 !important;
    margin-left: 3rem;
    max-width: 20.1rem;
  }

  .recente-vew-divs .comon-table-divs #ceo th:last-child {
    width: 260px !important;
  }

  .recente-vew-divs .comon-table-td {
    color: #5746ec;
    text-decoration: none;
  }

  .gp-anhcor a {
    margin: 0 15px;
  }

  .sp-td-sec {
    box-shadow: none !important;
    background: none !important;
  }

  /* .td-inside {
    height: 145px;
  } */
  .td-inside .icon-img {
    display: grid;
    align-content: center;
    margin-right: 10px;
  }

  .td-inside .bg-white {
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    height: 145px;
    align-items: center;
    border-radius: 12px;
    padding: 0 20px;
    width: 100%;
    /* 28-04 */
  }

  .loading-btn:hover {
    background: #5746ec !important;
    color: #fff !important;
  }

  .loading-btn svg {
    width: 12px;
    height: 12px;
  }

  .comon-notes p {
    color: #202020;
    font-size: 15px;
    font-weight: 400;
  }

  .text-td {
    width: 379px;
  }

  .auto-td {
    width: 270px;
  }

  .commment-td {
    width: 120px;
  }

  .option-td {
    width: 223px;
  }

  .recente-vew-divs .w-300 {
    width: 339px;
  }

  .recente-vew-divs .w-160 {
    width: 270px;
  }

  .recente-vew-divs .w-1001 {
    width: 120px;
  }

  .comon-notes .auto-sec p {
    background: #f1f2f6;
    border-radius: 40px;
    width: auto;
    display: inline-block;
    height: 28px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 26px;
  }

  .comon-notes .auto-sec p a {
    color: #5746ec;
    font-weight: 500;
    text-decoration: none;
  }

  /* 24-05-2022 */
  .list-btn3 a,
  .monr-btn {
    background: #ffffff !important;
    border: 1.2px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
    height: 35px;
    font-size: 12px !important;
    margin-right: 9px;
    margin-bottom: 10px;
    font-weight: 500 !important;
    color: #5c5f62 !important;
    height: 33px;
    font-size: 13px !important;
  }

  /* 27-05-06 */
  .comon-tabs-expert th:nth-child(3),
  .body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(3) {
    width: 400px;
  }

  .comon-tabs-expert th:nth-child(2),
  .body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(2) {
    width: 160px;
  }

  /* ends */
  .monr-btn {
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    display: inline-block;
    text-decoration: none !important;
    color: #000;
    margin-bottom: 0 !important;
    position: relative;
    top: -5px;
  }

  .top-ps-seection .nav-pills .nav-link {
    width: 162px;
  }

  .projects-pages .top-ps-seection .nav-pills .nav-link {
    width: 177px;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    width: 267px;
    margin: 0 2px;
  }

  .w-300 {
    width: 293px;
  }

  .w-160 {
    width: 210px;
  }

  .w-1001 {
    width: 165px;
  }

  .w-2001 {
    width: 200px;
  }

  .w-66 {
    width: 60px !important;
  }

  .collab-table tr th:nth-child(2),
  .collab-table .bg-white > .div:first-child {
    width: 374px;
  }

  .expert-cell-page .comon-table-divs th:last-child,
  .expert-cell-page .comon-table-divs tr td:last-child {
    width: 155px;
  }

  .expert-cell-page .comon-table-divs th:nth-child(3),
  .expert-cell-page .comon-table-divs tr td:nth-child(3),
  .expert-cell-page .comon-table-divs th:nth-child(4) {
    width: 145px;
  }

  .comon-table-divs .dropdown .dropdown-item {
    font-size: 15px;
  }

  .user-mg-page .comon-table-divs th:last-child,
  .user-mg-page .comon-table-divs tr th:last-child:after {
    background: none !important;
  }

  .speacial-expert .modal-dialog {
    max-width: 550px !important;
  }

  .speacial-expert h2 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .speacial-expert hr {
    width: 94%;
  }

  .speacial-expert .modal-header {
    border: none !important;
    margin-bottom: 0;
    padding: 15px 20px 0;
  }

  .collab-table tr th:first-child,
  .collab-table .bg-white > .div:first-child {
    width: 430px;
  }

  .collab-table tr th:nth-child(2),
  .collab-table .bg-white > .div:first-child {
    width: 270px;
  }

  .recente-vew-divs .collab-table tr th:last-child,
  .recente-vew-divs .collab-table .bg-white > .div:last-child {
    width: 40px;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    align-items: flex-start;
    padding-top: 25px;
  }

  .call-history-table tr th:first-child {
    width: 557px;
  }

  .call-history-table .td-inside {
    margin-bottom: 25px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 20px;
    width: 580px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .call-history-table tr th:nth-child(4) {
    width: 190px;
  }

  .call-history-table .td-inside .bg-white > div:nth-child(2) {
    width: 155px;
    padding-left: 0;
  }

  .call-history-table .td-inside .bg-white > div:last-child {
    width: 201px;
    padding-left: 27px;
  }

  .call-history-table .commment-td {
    width: 155px;
  }

  .main-re-from-divs label {
    font-size: 16px;
  }
}

@media (max-width: 1440px) {
  .main-re-from-divs {
    margin-top: 110px !important;
  }

  .landing-sec-rg {
    margin-top: 30px !important;
  }

  .projects-pages .top-ps-seection .nav-pills .nav-link {
    width: 167px;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    width: 251px !important;
    margin: 0 2px;
  }

  .projects-pages .w-1001 {
    width: 137px;
  }

  .expert-cell-page .comon-table-divs th:last-child,
  .expert-cell-page .comon-table-divs tr td:last-child {
    width: 97px;
  }

  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(4) {
    width: 119px;
  }

  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 149px;
    margin: 0 5px;
  }

  .right-small-icons li span:last-child small {
    font-size: 9px;
  }
}

@media (max-width: 1400px) {
  .projects-pages .top-ps-seection .nav-pills .nav-link {
    width: 161px;
  }

  .w-1001 {
    width: 113px;
  }

  .expert-cell-page .comon-table-divs th:last-child,
  .expert-cell-page .comon-table-divs tr td:last-child {
    width: 65px;
  }

  .user-mg-page .comon-table-divs th:nth-child(3),
  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs th:nth-child(5),
  .user-mg-page .comon-table-divs tr td:nth-child(3),
  .user-mg-page .comon-table-divs tr td:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(5) {
    width: 148px;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    margin: 0 2px;
    width: 241px;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    padding-top: 15px;
  }

  .call-history-table tr th:first-child {
    width: 442px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 0px;
    width: 437px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .w-1001 {
    width: 113px;
  }

  .expert-cell-page .comon-table-divs th:last-child,
  .expert-cell-page .comon-table-divs tr td:last-child {
    width: 65px;
  }

  .user-mg-page .comon-table-divs th:nth-child(3),
  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs th:nth-child(5),
  .user-mg-page .comon-table-divs tr td:nth-child(3),
  .user-mg-page .comon-table-divs tr td:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(5) {
    width: 148px;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    margin: 0 2px;
    width: 241px;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    padding-top: 15px;
  }

  .call-history-table tr th:first-child {
    width: 442px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 0px;
    width: 437px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .call-history-table tr th:nth-child(4) {
    width: 190px;
  }

  .call-history-table .td-inside .bg-white > div:nth-child(2) {
    width: 150px;
    padding-left: 0;
  }

  .call-history-table .td-inside .bg-white > div:last-child {
    width: 201px;
    padding-left: 27px;
  }
}

@media (max-width: 1366px) {
  .projects-pages .top-ps-seection .nav-pills .nav-link {
    width: 158px;
  }

  .main-re-from-divs label {
    font-size: 14px;
  }

  .conti-new {
    width: 450px;
    margin-left: 110px;
  }

  .landing-banner h1 {
    font-size: 53px;
    font-weight: 600;
  }

  .landing-banner,
  .home-banner {
    min-height: 645px;
  }

  .user-mg-page .comon-table-divs th:nth-child(5),
  .user-mg-page .comon-table-divs tr td:nth-child(5) {
    width: 130px;
  }

  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(4) {
    width: 140px;
  }

  .user-mg-page .comon-table-divs th:nth-child(3),
  .user-mg-page .comon-table-divs tr td:nth-child(3) {
    width: 145px;
  }

  .user-mg-page .comon-table-divs th:last-child,
  .user-mg-page .comon-table-divs tr td:last-child {
    width: 74px !important;
  }

  .expert-cell-page .comon-table-divs th:first-child,
  .expert-cell-page .comon-table-divs tr td:first-child {
    width: 400px;
  }

  .expert-cell-page .comon-table-divs th:nth-child(3),
  .expert-cell-page .comon-table-divs tr td:nth-child(3),
  .expert-cell-page .comon-table-divs th:nth-child(4) {
    width: 130px;
  }

  /* 27-05-06 */
  .recente-vew-divs .comon-table-divs #ceo th:last-child {
    width: 180px !important;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    width: 238px;
    margin: 0 2px;
  }

  /* .expert-cell-page .comon-table-divs th:last-child,
  .expert-cell-page .comon-table-divs tr td:last-child {
    width: 131px;
  } */
  .user-mg-page .comon-table-divs th:last-child,
  .user-mg-page .comon-table-divs tr td:last-child {
    width: 81px;
  }

  /* .collab-table tr th:first-child,
  .collab-table .bg-white > .div:first-child {
    width: 390px;
  } */
  .collab-table tr th:nth-child(2),
  .collab-table .bg-white > .div:first-child {
    width: 229px;
  }

  .td-inside .bg-white {
    width: 89%;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    padding-top: 24px;
  }

  .call-history-table tr th:first-child {
    width: 442px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 0px;
    width: 437px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .call-history-table tr th:nth-child(4) {
    width: 190px;
  }

  .call-history-table .td-inside .bg-white > div:nth-child(2) {
    width: 150px;
    padding-left: 0;
  }

  .call-history-table .td-inside .bg-white > div:last-child {
    width: 201px;
    padding-left: 27px;
  }

  .call-history-table .commment-td {
    width: 160px;
  }

  .collab-table .td-inside .bg-white {
    width: 82%;
    height: 116px;
  }

  .comon-tabs-expert th:nth-child(3),
  .body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(3) {
    width: 320px;
  }

  .comon-tabs-expert th:nth-child(2),
  .body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(2) {
    width: 120px;
  }

  /* ends */

  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 145px;
    margin: auto;
    text-align: center;
  }

  .right-small-icons li span:last-child {
    font-size: 17px;
    font-weight: 600;
    color: #000;
  }

  .right-small-icons li span:last-child small {
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    color: #5c5f62;
  }

  .comon-notes .auto-sec p {
    background: #f1f2f6;
    border-radius: 40px;
    /* width: 111px; */
    display: inline-block;
    height: 28px;
    text-align: center;
    font-size: 11px;
    line-height: 27px;
  }

  /* .select-option {
    margin-left: 53px;
  } */

  .sp-hr {
    margin-left: 53px;
  }

  .sp-hr {
    margin-left: 53px;
  }

  .top-bar-menu {
    padding-right: 45px;
  }

  .body-expart-div {
    padding-left: 53px;
  }

  .search-section-optios {
    padding-left: 53px;
  }

  .list-btn3 a,
  .monr-btn {
    background: #ffffff !important;
    border: 1.2px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
    height: 35px;
    font-size: 12px !important;
    margin-right: 3px;
  }

  .top-ps-seection .nav-pills .nav-link {
    width: 143px;
  }

  .w-300 {
    width: 257px;
  }

  .w-160 {
    width: 180px;
  }

  .w-1001 {
    width: 148px;
  }

  .w-2001 {
    width: 170px;
  }

  .w-66 {
    width: 66px !important;
  }
}

.collab-table .td-inside .bg-white {
  height: 116px;
}

.charts-sections1 {
  height: 330px;
  overflow: auto;
  width: 100%;
  padding: 15px 20px;
}

.charts-sections1::-webkit-scrollbar {
  visibility: hidden;
}

.mainl .jodit-container:not(.jodit_inline) {
  border-radius: 3px;
  display: flex;
  flex-direction: column-reverse;
  border: none !important;
  min-height: 215px !important;
  height: 215px !important;
  top: 0;
  position: relative;
}

.main2 .jodit-container:not(.jodit_inline) {
  border-radius: 3px;
  display: flex;
  flex-direction: column-reverse;
  border: none !important;
  min-height: 215px !important;
  height: 215px !important;
  top: 0;
  position: relative;
}

.cm-modal-all .jodit-container:not(.jodit_inline) {
  border-radius: 3px;
  display: flex;
  flex-direction: column-reverse;
  border: none !important;
  min-height: 215px !important;
  top: 0;
  height: 215px !important;
  position: relative;
}

.cm-modal-all .jodit-container:not(.jodit_inline) .jodit-workplace {
  min-height: 91px !important;
  height: 91px !important;
}

.mainl .jodit-container:not(.jodit_inline) .jodit-workplace {
  min-height: 91px !important;
  height: 91px !important;
}

.main2 .jodit-container:not(.jodit_inline) .jodit-workplace {
  min-height: 91px !important;
  height: 91px !important;
}

.jodit-toolbar__box:not(:empty) {
  background: none !important;
  border-bottom: none !important;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
  top: -10px;
  position: relative;
  height: 56px !important;
  border-top: solid 1px #202020 !important;
  width: 70% !important;
  margin: auto !important;
  border-radius: 0 !important;
}

/* 29-05 */
.mainl .jodit-toolbar__box {
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translateX(-50%);
  margin: auto;
}

.mainl .jodit-workplace {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
}

.main2 .jodit-toolbar__box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  margin: auto;
}

.main2 .jodit-workplace {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
}

.cm-modal-all .jodit-workplace {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
}

/* 29-05 */

.jodit-ui-group_line_true {
  justify-content: center !important;
}

.mainl .jodit-status-bar {
  display: none;
}

.main2 .jodit-status-bar {
  display: none;
}

.cm-modal-all .jodit-status-bar {
  display: none;
}

.charts-sections1 > div {
  margin-bottom: 12px;
  padding-top: 12px;
}

/* landing */

.top-part {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  min-height: 70px;
  display: inline-block;
  padding: 0;
  width: 100%;
}

.top-part .navbar {
  padding: 14px 0;
}

.top-part .login-web-btn {
  background: #ffffff;
  border: 1.5px solid #5746ec;
  border-radius: 7px;
  font-size: 18px;
  color: #5746ec !important;
  width: 130px;
  height: 44px;
  text-align: center;
  padding: 0;
  line-height: 38px;
  transition: all 0.5s;
  margin-left: 30px;
}

.top-part .login-web-btn:hover {
  background: #5746ec;
  color: #fff !important;
}

.top-part .register-login-btn {
  background: #5746ec;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 18px;
  color: #fff !important;
  width: 130px;
  height: 44px;
  text-align: center;
  padding: 0;
  line-height: 41px;
  transition: all 0.5s;
  margin-left: 15px;
}

.top-part .contact-btn-web {
  color: #727479 !important;
  font-weight: 500;
  font-size: 18px;
}

.footer-part .right-footer > div:last-child {
  display: grid;
  justify-content: flex-end;
}

.footer-part .right-footer > div:nth-child(2) {
  display: grid;
  justify-content: center;
}

.footer-part .right-footer h5 {
  font-size: 24px;
  font-weight: #012333;
  font-weight: 500;
}

.footer-part .right-footer li {
  margin-bottom: 10px;
}

.footer-part .right-footer li a {
  text-decoration: none;
  font-size: 20px;
  color: #5c5f62;
  font-weight: 500;
  transition: all 0.5s;
}

.footer-part .right-footer li a:hover {
  color: #5746ec;
}

.footer-part hr {
  background: rgba(0, 0, 0, 0.2);
}

.home-banner .find-btn {
  background: #5746ec;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: #fff;
  width: 277px;
  height: 54px;
  line-height: 38px;
  transition: all 0.5s;
  transform: scale(1);
}

.home-banner .contact-btn {
  background: #ffffff;
  border: 1.5px solid #5746ec;
  color: #5746ec;
  border-radius: 8px;
  width: 277px;
  height: 54px;
  line-height: 38px;
  transition: all 0.5s;
  transform: scale(1);
  margin-left: 30px;
}

.home-banner h3 {
  font-weight: 600;
  color: #000;
}

.home-banner .find-btn:hover,
.home-banner .contact-btn:hover {
  background: #000 !important;
  color: #fff !important;
}

.footer-part p {
  color: #5c5f62;
  font-size: 20px;
  font-weight: 500;
}

.industry-clients {
  background: #f8f8f8;
  display: inline-block;
  width: 100%;
  padding: 100px 0;
}

.landing-banner {
  background: #f8f8f8;
  display: grid;
  align-content: center;
}

.career-sec {
  position: relative;
  display: inline-block;
  width: 100%;
}

.career-infomations {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 70%;
  display: inline-block;
}

.career-infomations h2 {
  text-shadow: 8px -3px 17px rgba(0, 0, 0, 0.88);
  font-weight: 600;
  color: #fff;
  font-size: 56px;
}

.landing-banner h1,
.home-banner h1 {
  font-size: 45px;
  font-weight: 600;
  color: #000;
}

.get-st-btn {
  color: #fff !important;
  font-size: 20px;
  font-weight: 600;
  text-shadow: 8px -3px 17px rgb(0 0 0 / 88%);
  margin-top: 15px;
}

.landing-banner h1 span {
  display: block;
  background: linear-gradient(92.84deg, #5746ec -34.59%, #f55656 93.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-banner h1 span,
.industry-clients h2 {
  display: block;
  background: linear-gradient(92.84deg, #5746ec -34.59%, #f55656 93.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.comon-ads1 {
  text-decoration: none;
  width: 100%;
  display: inline-block;
}

.comon-ads1 figure {
  height: 344px;
  display: inline-block;
  overflow: hidden;
  width: 100%;
}

.comon-ads1 figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transform: scale(2);
  transition: all 0.5s;
}

.comon-ads1:hover figure img {
  transform: scale(2.1);
}

.slick-dots {
  display: none !important;
}

.slick-track > div:first-child {
  margin-left: 0;
  padding-left: 0;
}

.slick-prev:before {
  content: "" !important;
  /* dharmendra */
  /* background-image: url(assests/images/arrow-right.svg); */
  width: 39px;
  height: 17px;
  display: inline-block;
}

.News-blogs {
  display: inline-block;
  width: 100%;
  position: relative;
}

.slick-next,
.slick-prev {
  position: absolute;
  transform: none !important;
}

.slick-next {
  top: -100px !important;
  left: inherit !important;
  right: 24px !important;
}

.slick-prev {
  top: -100px !important;
  left: inherit !important;
  right: 80px !important;
}

.slick-next:before {
  content: "" !important;
  /* dharmendra */
  /* background-image: url(assests/images/next-arrow.svg); */
  width: 39px;
  height: 17px;
  display: inline-block;
}

.slick-slide {
  padding: 30px;
}

.news-blogs h2 {
  font-size: 45px;
  font-weight: 600;
  color: #000000;
}

p.sub-text-news {
  color: #5c5f62;
  font-size: 20px;
  font-weight: 500;
}

.comon-ads1 h5 {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  transition: all 0.5s;
}

.comon-ads1:hover h5 {
  color: #5746ec !important;
}

.comon-ads1 p {
  color: #202020;
  letter-spacing: -0.02em;
  font-weight: 400;
}

.industry-clients h2 {
  font-weight: 600;
  font-size: 50px;
}

.tgs {
  background: #e7e7e7;
  border-radius: 10px;
  width: 113px;
  height: 41px;
  text-align: center;
  padding: 10px;
  line-height: 22px;
  color: #5c5f62 !important;
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
}

.comon-ads1 h6 {
  color: #5c5f62;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}

.clients-in {
  background: #f8f8f8;
  width: 100%;
  height: 90px;
  text-align: center;
  display: table;
}

.form-sec-ban {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 6px rgba(180, 212, 221, 0.2);
  border-radius: 18px;
  height: 410px;
  width: 90%;
  padding: 30px;
  margin-left: auto;
}

.form-sec-ban h2 {
  font-size: 28px;
  font-weight: 500;
  color: #000;
}

.form-sec-ban .form-group {
  margin-bottom: 20px;
}

.form-sec-ban label {
  font-size: 16px;
  color: #666666;
  font-weight: 400;
  margin-bottom: 5px;
}

.form-sec-ban .form-control {
  height: 44px;
  border-radius: 10px;
}

.expert-cloud-div h1 {
  font-weight: 600;
  font-size: 45px;
  color: #000;
}

p.subtext1 {
  color: #5c5f62;
  font-size: 16px;
}

.comon-section-landing {
  padding-left: 40px;
}

.comon-section-landing h2 {
  background: linear-gradient(92.84deg, #5746ec -34.59%, #f55656 93.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 45px;
}

.home-banner {
  background: #fff;
  display: grid;
  align-content: center;
}

.comon-section-landing h5,
.comon-section-landing .click-btn {
  color: #5746ec;
  font-weight: 500;
  font-size: 18px;
  padding: 0;
}

.comon-section-landing ul {
  display: flex;
  flex-wrap: wrap;
}

.comon-section-landing ul li {
  margin: 14px 14px 0 0;
}

.comon-section-landing ul li .btn-comon-web {
  background: #ffffff;
  border: 1.5px solid #5746ec;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  color: #5c5f62;
  font-size: 15px;
  transition: all 0.5s;
  transform: scale(1);
}

.comon-section-landing ul li .btn-comon-web:hover {
  transform: scale(1.1);
}

.get-start-btn {
  background: #5746ec !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  color: #fff !important;
  height: 45px;
  width: 222px;
  line-height: 31px !important;
  margin-top: 30px;
  transition: all 0.5s !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.cosulting-page-d1 ul li {
  display: flex;
  align-items: baseline;
}

.cosulting-page-d1 ul li span {
  width: 90%;
  display: inline-block;
  margin-left: 17px;
}

.cosulting-page-d1 ul li svg {
  color: #5746ec;
  position: relative;
  top: 4px;
}

.get-start-btn svg {
  margin-left: 5px;
}

.get-start-btn:hover {
  background: #000 !important;
}

.comon-web-search-sec .container > div {
  margin-bottom: 78px;
}

.faq-part {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 100px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
}

.faq-part .accordion-button:not(.collapsed) {
  background: #fff !important;
}

.faq-part .accordion-header {
  margin: 0 !important;
}

.faq-part .accordion-item {
  border: none !important;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2) !important;
  border-radius: 0 !important;
}

.faq-part .accordion-button {
  font-size: 22px;
  font-weight: 500;
  color: #000;
  box-shadow: none !important;
}

.faq-part .accordion-button:not(.collapsed) {
  color: #000;
}

.faq-part .show .accordion-body {
  border: none !important;
}

.faq-part .accordion-body p {
  font-style: 16px;
  font-weight: 400;
  color: #5c5f62;
}

.faq-part .accordion > div:last-child {
  border-bottom: none !important;
}

.faq-part .accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.faq-sections1 .accordion-button:not(.collapsed)::after {
  /* dharmendra */
  /* background-image: url(assests/images/faq-icon.svg) !important; */
  border-radius: 0 !important;
  width: 30px;
  height: 4px;
  transform: none !important;
}

.faq-sections1 .accordion-button::after {
  /* dharmendra */
  /* background-image: url(assests/images/faq-icon2.svg) !important; */
  border-radius: 0 !important;
  width: 30px;
  height: 30px;
}

.subcribe-divs {
  /* dharmendra */
  /* background-image: url(assests/images/subscrib-bg.svg) !important; */
  padding: 60px 0;
  display: inline-block;
  margin: auto;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  min-height: 385px;
  display: grid;
  align-content: center;
}

.subcribe-divs:hover .conatct-btn-sub {
  background: #000 !important;
  color: #fff !important;
}

.form-sec-ban .form-control::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.form-sec-ban .submit {
  background: #5746ec;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 40px;
  width: 100%;
  color: #fff;
}

.contact-comn-web {
  margin-bottom: 50px;
  display: inline-block;
  width: 100%;
}

.contact-comn-web h2 {
  color: #fff;
  font-size: 48px;
}

.right-footer .comon-footer ul {
  margin-top: 27px;
}

.conatct-btn-sub {
  background: #ffffff !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  width: 34%;
  height: 44px;
  line-height: 34px !important;
  transition: all 0.5s;
}

/* landing page design */
@media (min-width: 1180px) {
  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 122px;
    margin: 0 2px;
  }
}

@media (min-width: 992px) {
  .sidebar-menu {
    background: #fff;
    box-sizing: border-box;
    height: 100vh;
    left: 0;
    margin-top: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 18%;
    /* padding: 25px 25px 0; */
  }
}

@keyframes up-down {
  0% {
    transform: translate(1px, 20px);
  }

  24% {
    transform: translate(1px, 30px);
  }

  50% {
    transform: translate(1px, 12px);
  }

  74% {
    transform: translate(1px, 22px);
  }

  100% {
    transform: translate(1px, 22px);
  }
}

@-moz-keyframes up-down {
  0% {
    -moz-transform: translate(1px, 20px);
  }

  24% {
    -moz-transform: translate(1px, 30px);
  }

  50% {
    -moz-transform: translate(1px, 12px);
  }

  74% {
    -moz-transform: translate(1px, 22px);
  }

  100% {
    -moz-transform: translate(1px, 22px);
  }
}

@-webkit-keyframes up-down {
  0% {
    -webkit-transform: translate(1px, 20px);
  }

  24% {
    -webkit-transform: translate(1px, 30px);
  }

  50% {
    -webkit-transform: translate(1px, 12px);
  }

  74% {
    -webkit-transform: translate(1px, 22px);
  }

  100% {
    -webkit-transform: translate(1px, 22px);
  }
}

@-o-keyframes up-down {
  0% {
    -o-transform: translate(1px, 20px);
  }

  24% {
    -o-transform: translate(1px, 30px);
  }

  50% {
    -o-transform: translate(1px, 12px);
  }

  74% {
    -o-transform: translate(1px, 22px);
  }

  100% {
    -o-transform: translate(1px, 22px);
  }
}

@-ms-keyframes up-down {
  0% {
    -ms-transform: translate(1px, 20px);
  }

  24% {
    -ms-transform: translate(1px, 30px);
  }

  50% {
    -ms-transform: translate(1px, 12px);
  }

  74% {
    -ms-transform: translate(1px, 22px);
  }

  100% {
    -ms-transform: translate(1px, 22px);
  }
}

@keyframes turn {
  0% {
    left: 0px;
  }

  50% {
    left: 90px;
  }

  100% {
    left: 0px;
  }
}

@media (max-width: 1180px) {
  .comon-divs-re {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .call-history-table tr th:first-child {
    width: 212px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 21px;
    width: 234px;
  }

  .nes-tabs ul li {
    margin: 10px 0;
  }

  .td-inside .bg-white,
  .td-inside {
    height: auto;
  }

  .comon-history-expert {
    padding: 30px;
    height: auto;
  }

  .detsils-secch-comon ul li {
    margin: 10px 0;
  }

  .serhisiroy-right .edite-bnt {
    width: 100%;
    margin-left: 0;
  }

  .comon-divs-re h5 {
    font-size: 16px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 0 !important;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .top-bar-menu .serch-sec-div {
    width: 100%;
  }

  .select-option,
  .sp-hr {
    margin-left: 23px;
  }

  .body-expart-div {
    padding-left: 23px;
  }

  .main-menu ul li a {
    font-size: 13px;
  }

  .notes-d-section .comon-notes {
    margin-bottom: 25px;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    width: 203px !important;
    width: 166px !important;
    margin: 0 3px;
  }

  .expert-cell-page .comon-table-divs,
  .projects-pages .comon-table-divs {
    width: 100%;
    overflow: auto;
  }

  .expert-cell-page .comon-table-divs table {
    table-layout: fixed;
    width: 990px;
  }

  .projects-pages .comon-table-divs table {
    table-layout: fixed;
    width: 990px;
  }

  .projects-pages .top-ps-seection .nav-pills .nav-link {
    width: 116px;
    width: 103px;
    margin-bottom: 0;
  }

  .landing-page-d1 .login-left-part {
    background-size: cover;
    min-height: 100%;
  }
}

.expert-cell-page .top-ps-seection .nav-pills .nav-link {
  width: 203px !important;
  margin: 0 0;
}

.expert-cell-page .comon-table-divs,
.projects-pages .comon-table-divs {
  width: 100%;
  overflow: auto;
}

.expert-cell-page .comon-table-divs table {
  table-layout: fixed;
  width: 990px;
}

.projects-pages .top-ps-seection .nav-pills .nav-link {
  max-width: 147px;
  margin-bottom: 0;
}

.projects-pages2 .top-ps-seection .nav-pills .nav-link {
  /* max-width: 147px; */
  margin-bottom: 0;
}

.landing-page-d1 .login-left-part {
  background-size: cover;
  min-height: 100%;
}

@media (max-width: 1024px) {
  .select-option,
  .sp-hr {
    margin-left: 0;
  }

  .body-expart-div {
    padding-left: 0;
  }

  .sidebar-menu {
    width: 21%;
  }

  .top-bar-menu {
    width: 78%;
  }
}

@media (max-width: 900px) {
  .sidebar-menu {
    display: none;
  }

  .landing-page-d1 .main-re-from-divs {
    margin: auto;
    width: 80%;
  }

  .comon-card-n {
    margin-bottom: 30px;
  }

  .new-register-page .main-re-from-divs,
  .new-add-rg2 .main-re-from-divs {
    margin: auto;
    width: 80%;
  }

  .new-register-page .conti-new {
    width: 80%;
    margin: auto;
    display: table;
  }

  .mob-call-sec .div-pogress h5 {
    font-size: 14px;
    color: #bbb;
  }

  .mob-call-sec .div-pogress h5 span {
    font-size: 18px;
    color: #000;
    font-weight: 600;
  }

  .mob-call-sec .date h5 {
    color: #5746ec;
    text-transform: capitalize;
  }

  .titels-new {
    font-size: 20px;
  }

  .mob-call-sec .nubers-div1 h5 {
    text-transform: capitalize;
    color: #000;
  }

  .detsils-secch-comon {
    width: 100%;
  }

  .landing-page-d1 .main-re-from-divs .logo,
  .new-register-page .main-re-from-divs .logo,
  .new-add-rg2 .main-re-from-divs .logo {
    margin-left: 0;
  }

  .comon-history-expert {
    width: 100%;
    margin: auto;
    height: auto;
    padding: 30px;
  }

  .serhisiroy-right:after {
    display: none;
  }

  .serhisiroy-right h5 {
    text-align: center;
  }

  .new-register-page .back-rgs {
    left: 8%;
  }

  .back-new-fun {
    left: 8%;
  }

  .serhisiroy-right .edite-bnt {
    margin: auto;
    width: 100%;
  }

  .left-hisroty-expert figure {
    width: 100%;
    text-align: left;
  }

  .detsils-secch-comon ul li {
    margin: 15px 0;
  }

  .serhisiroy-right {
    margin-top: 30px;
  }

  .mbile-divs-table {
    background: #ffffff;
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    padding: 30px;
    margin-top: 30px;
    display: inline-block;
    position: relative;
  }

  .inside-support,
  .news-section,
  .faq-section {
    width: 100%;
  }

  .body-expart-div h2 {
    font-size: 25px;
  }

  .converstion-divs .nav .nav-link h5 span {
    color: #5c5f62;
    font-weight: 400;
    font-size: 11px;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    width: 165px;
    margin: 0 2px;
  }

  .top-bar-menu {
    width: 100%;
  }

  .main-menu ul li a {
    font-size: 15px;
  }

  .comon-divs-re h5 {
    font-size: 18px;
  }

  .content-wrapper {
    padding-left: 0;
  }

  .top-bar-menu {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    background: #fff;
  }

  .top-bar-menu .serch-sec-div {
    width: 100%;
  }

  .mobile-serch-sec-div {
    border: solid 1px #f33636 !important;
    height: 43px !important;
    border-radius: 10px !important;
    padding-top: 0;
    padding-right: 10px;
    padding-bottom: 0;
    padding-left: 0;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff !important;
  }

  .mobile-serch-sec-div .form-control {
    border: none !important;
  }

  .mobile-serch-sec-div svg {
    color: #f33636;
  }

  .right-menu-sec > div:nth-child(2) {
    display: none !important;
  }

  .login-left-part,
  .regitser-page-div {
    padding-left: 30px;
  }

  .regitser-page-div {
    padding-right: 30px;
  }

  .select-option,
  .sp-hr,
  .body-expart-div {
    padding-left: 0;
  }

  .select-option,
  .sp-hr {
    margin-left: 0;
  }

  .comon-divs-re {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
  }

  .mobile-fill-width {
    width: 100%;
  }

  .mobile-canvas .offcanvas-title {
    visibility: hidden;
  }

  .comon-select {
    margin-bottom: 25px;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .tp-list1 {
    margin: 25px 0;
  }

  .new-reguest {
    line-height: 30px !important;
  }

  .right-menu-sec {
    justify-content: space-between;
  }

  .top-bar-menu > div {
    width: 100%;
  }

  .left-section-divs,
  .right-section-div {
    width: 100%;
  }

  .search-section-optios {
    padding-left: 0;
  }

  .search-section-optios .comon-ser-btn {
    margin-bottom: 10px;
  }

  .tab-content {
    overflow: auto;
    width: 100%;
  }

  .comon-table-divs table {
    table-layout: fixed;
    width: 1090px !important;
  }

  .top-ps-seection .nav {
    background: #e7e7e7;
    border-radius: 10px;
    padding: 5px 5px 1px;

    justify-content: space-between;
  }

  .collab-table {
    overflow: auto;
    width: 100%;
  }

  .collab-table .table {
    table-layout: fixed;
    width: 800px !important;
  }

  .request-page {
    width: 100%;
  }

  /* 02-06-2022 */
  .login-page-div {
    position: relative;
    width: 50%;
    padding-bottom: 50px;
  }

  .mobile-canvas .offcanvas-header,
  .mobile-canvas .logo-admin {
    display: none;
  }

  .mobile-canvas {
    top: 62px !important;
  }

  .dasb-div .select-option {
    width: 100%;
    overflow: auto;
  }

  .dasb-div .select-option .flex-column {
    flex-direction: row !important;
    width: 1366px;
  }

  .body-expart-div .nav-pills .nav-link {
    font-size: 13px;
    padding: 8px;
  }

  .body-expart-div .nav-item {
    margin: 0 8px;
  }

  .body-expart-div .ps-titel {
    font-size: 20px;
  }

  .projects-pages .top-ps-seection .nav,
  .projects-scope .top-ps-seection .nav {
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px 6px;
  }

  .projects-pages .top-ps-seection .nav-pills .nav-link,
  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 89px !important;
    margin-bottom: 0;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    width: 148px !important;
    margin-bottom: 0;
  }

  .projects-pages .nav-item:first-child,
  .projects-pages .nav-item:first-child {
    margin-left: 0;
  }

  .td-inside .d-flex {
    flex-wrap: wrap !important;
    width: 100% !important;
    justify-content: flex-start !important;
    height: auto !important;
    padding: 20px !important;
  }

  .td-inside .text-td {
    width: 100%;
  }

  .collaborate-page .td-inside {
    height: auto !important;
    margin-bottom: 30px;
    display: inline-block !important;
    width: 100%;
  }

  .file-btn {
    margin-right: 10px;
  }

  .body-expart-div h2 {
    font-size: 22px;
  }

  .user-mg-page h2 {
    margin-bottom: 0;
  }

  .top-ps-seection {
    width: 100%;
  }

  .support-btn {
    display: flex;
    flex-wrap: wrap !important;
    height: auto !important;
    padding: 20px !important;
    position: relative;
  }

  .support-btn > svg {
    position: absolute;
    right: 15px;
    top: 45px;
  }

  .support-page .accordion-button {
    font-size: 20px;
  }

  .support-page .accordion-button::after {
    width: 2.25rem;
  }

  .support-btn img {
    margin-bottom: 10px;
  }

  .request-body-sec ul.d-flex {
    flex-wrap: wrap !important;
  }

  .request-body-sec ul.d-flex li {
    width: 100%;
    margin-bottom: 15px;
  }

  .details-message-part .expert-tables .nav {
    flex-wrap: nowrap !important;
    justify-content: flex-start !important;
  }

  .expert-tables {
    width: 100%;
    overflow: auto;
  }

  .message-text-area .text-area-type {
    width: 90%;
  }

  .search-mobile-s {
    display: block !important;
  }

  .mboile-logo {
    text-decoration: none;
    height: 32px;
    overflow: hidden;
    top: 2px;
    position: relative;
    display: inline-block;
  }

  .comon-re-texts {
    display: none;
  }

  .expert-login-page .landing-sec-rg {
    display: table;
    width: 80%;
    margin: auto;
  }

  .expert-login-page .logo {
    margin-left: 0;
  }

  .all-complains {
    width: 100%;
  }

  .payment-details-2 {
    width: 100%;
  }

  .comon-payments-ex {
    width: 100%;
  }
}

@media (max-width: 667px) {
  .comon-select {
    margin-bottom: 25px;
  }

  .mob-call-sec .date h4 {
    color: #5c5f62;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }

  .comon-payments-ex .dropdown {
    position: absolute;
    top: 28px;
    right: 10px;
  }

  .request-body-sec ul li {
    margin-bottom: 10px;
  }

  .jodit-toolbar__box:not(:empty) {
    width: 92% !important;
  }

  .md-titel-link {
    display: block;
  }

  .request-body-sec ul li span,
  .request-body-sec .com-li li span:first-child {
    margin-right: 10px;
  }

  .online-st svg {
    color: #299c46;
  }

  .expert-login-page .main-re-from-divs {
    padding-left: 0;
    padding-right: 0;
  }

  .landing-page-d1 .main-re-from-divs {
    padding-right: 15px;
  }

  .login-page .row > div:nth-child(2) {
    padding: 0;
  }

  .all-chat-divs .menmber-text,
  .all-chat-divs .admin-text {
    width: 105%;
  }

  .expert-cell-page .comon-table-td {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .all-chat-divs {
    padding: 0;
  }

  .comon-toggle {
    flex-direction: row-reverse;
  }

  .cm-modal-all .submit-bn {
    width: 100%;
  }

  .comon-toggle p {
    position: relative;
    left: 10px;
  }

  .mob-call-sec .div-pogress h4 {
    color: #5c5f62;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }

  .mob-call-sec .date h5 {
    font-size: 18px;
  }

  .left-pm {
    align-items: baseline;
  }

  .mob-call-sec .nubers-div1 h5 {
    font-size: 18px;
  }

  .mob-call-sec .comon-table-td {
    font-size: 20px;
  }

  .mob-call-sec .nubers-div1 h4 {
    color: #5c5f62;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }

  .all-complains {
    width: 100%;
  }

  .serhisiroy-right {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 15px;
  }

  .payment-details-2 {
    width: 100%;
  }

  .left-pm figure {
    margin-bottom: 15px;
  }

  .comon-payments-ex {
    height: auto;
  }

  .comon-re-texts {
    display: none;
  }

  .left-hisroty-expert figure {
    width: 100%;
    text-align: left;
  }

  .expert-login-page .landing-sec-rg {
    display: table;
    width: 80%;
    margin: auto;
  }

  .expert-login-page .row > div:nth-child(2) {
    padding-right: 0;
  }

  .expert-login-page .logo {
    margin-left: 0;
  }

  .landing-page-d1 .logo {
    margin-left: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 15%);
    padding-bottom: 20px;
    text-align: center;
  }

  .converstion-divs .nav .nav-link h5 span {
    color: #5c5f62;
    font-weight: 400;
    font-size: 13px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }

  .top-ps-seection .nav {
    flex-direction: column;
  }

  .body-expart-div .nav-pills .nav-link {
    width: 100%;
  }

  .body-expart-div .nav-pills .nav-link.active,
  .body-expart-div .nav-pills .nav-link:hover,
  .body-expart-div .nav-pills .show > .nav-link {
    width: 100%;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .tp-list1 {
    margin: 25px 0;
  }

  .regitser-page-div {
    padding-right: 0;
    padding-left: 0;
  }

  .notes-d-section .comon-notes {
    margin-bottom: 25px;
  }

  .request-page {
    width: 100%;
    text-align: left;
  }

  .expert-login-page .landing-sec-rg {
    display: table;
    width: 80%;
    margin: auto;
  }

  .expert-login-page .row > div:nth-child(2) {
    padding-right: 0;
  }

  .expert-login-page .logo {
    margin-left: 0;
  }

  .landing-page-d1 .logo {
    margin-left: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 15%);
    padding-bottom: 20px;
    text-align: center;
  }

  .converstion-divs .nav .nav-link h5 span {
    color: #5c5f62;
    font-weight: 400;
    font-size: 13px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }

  .top-ps-seection .nav {
    flex-direction: column;
  }

  .body-expart-div .nav-pills .nav-link {
    width: 100%;
  }

  .body-expart-div .nav-pills .nav-link.active,
  .body-expart-div .nav-pills .nav-link:hover,
  .body-expart-div .nav-pills .show > .nav-link {
    width: 100%;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .tp-list1 {
    margin: 25px 0;
  }

  .regitser-page-div {
    padding-right: 0;
    padding-left: 0;
  }

  .notes-d-section .comon-notes {
    margin-bottom: 25px;
  }

  .request-page {
    width: 100%;
  }

  .login-page-div {
    height: 100%;
    width: 100%;
  }

  .login-page-div {
    height: 100%;
    width: 100%;
  }

  .login-page-div .inside-login {
    margin-bottom: 50px;
  }

  .mbile-divs-table {
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 30px;
    margin-top: 30px;
    display: inline-block;
    position: relative;
  }

  .mbile-divs-table h5 {
    text-transform: uppercase;
    color: #5c5f62;
  }

  .number-list {
    justify-content: flex-start;
  }

  .comon-table-td {
    color: #000000;
    text-decoration: none;
  }

  .dp-mobiles {
    position: absolute;
    right: 20px;
    top: 37px;
  }

  .m-queries {
    /* border: 1px solid; */
  }
}

@media (max-width: 480px) {
  .login-page-div {
    height: auto;
  }

  .login-page-div .inside-login {
    margin-bottom: 0;
  }

  .main-re-from-divs {
    width: 100% !important;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 60px !important;
  }

  .back-new-fun,
  .back-rgs {
    top: 84px;
    left: 6%;
  }

  .new-register-page .back-rgs {
    left: 3% !important;
  }

  .comon-dt-picker {
    margin-top: 10px;
  }

  .last-details h5 {
    text-align: left !important;
  }

  .dasb-div .select-option .flex-column {
    flex-direction: row !important;
    width: 50rem;
  }

  .dasb-div .select-option .flex-column > div {
    padding-right: 3px !important;
  }

  .next-list li {
    font-size: 16px;
    color: #5c5f62;
  }

  .next-list ul {
    display: flex;
    flex-wrap: wrap;
  }

  .next-list li {
    display: none;
  }

  .more-ex-btn {
    width: 100%;
    /* margin-top: 15px; */
    margin-left: 0;
  }

  .tp-list1 li {
    font-size: 16px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .one-ex-btn {
    width: 100%;
  }

  .notes {
    flex-wrap: wrap;
  }

  .speacial-expert h2 {
    font-size: 22px;
  }

  .notes span:last-child {
    padding-left: 0;
    margin-left: 0;
    margin-top: 8px;
  }

  .next-list li a {
    text-decoration: none;
    color: #5c5f62;
    font-size: 16px;
    font-size: 16px;
    color: #5c5f62;
  }

  .next-list ul {
    display: flex;
    flex-wrap: wrap;
  }

  .next-list li {
    display: none;
  }

  .more-ex-btn {
    width: 100%;
    /* margin-top: 15px; */
    margin-left: 0;
  }

  .tp-list1 li {
    font-size: 16px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .one-ex-btn {
    width: 100%;
  }

  .notes {
    flex-wrap: wrap;
  }

  .speacial-expert h2 {
    font-size: 22px;
  }

  .notes span:last-child {
    padding-left: 0;
    margin-left: 0;
    margin-top: 8px;
  }

  .next-list li a {
    text-decoration: none;
    color: #5c5f62;
    font-size: 16px;
  }

  .next-list li:first-child,
  .next-list li:last-child {
    display: block;
  }

  .add-btn-divs {
    margin-top: 10px;
  }

  .new-register-page .row > div:nth-child(2) {
    padding: 0;
  }

  .re-spi-logo {
    border-bottom: solid 1px rgba(0, 0, 0, 15%);
    padding-bottom: 20px;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 15px;
  }

  .new-register-page .conti-new {
    margin-bottom: 25px;
    width: 86%;
  }

  .regitser-page-div {
    width: 100%;
  }

  /* .regitser-page-div .logo,
  .login-page-div .logo,
  .landing-sec-rg .logo {
    margin: auto;
    text-align: center;
    border-bottom: solid 1px rgba(0, 0, 0, 15%);
    padding-bottom: 16px;
  } */
  .login-page-div {
    margin-top: 23px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }

  .login-btn1 {
    display: block;
  }

  .regitser-page-div .inside-login p {
    font-size: 20px;
  }

  .comon-fild label,
  .regitser-page-div label {
    color: #000000;
  }

  .regitser-page-div .form-control::-webkit-input-placeholder {
    color: #000;
  }

  .inside-login h4 {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    margin-top: 30px;
  }

  /* .login-page-div{
     transform: none;
     top:0;
     left: 0;
     right: 0;
     position: relative;
   } */

  .inside-login {
    /* display: grid;
    align-content: center;
    height: 100vh; */
    padding-left: 25px;
    padding-right: 15px;
  }

  .register-btn {
    display: block;
    font-size: 20px;
  }

  .inside-login p,
  .landing-sec-rg p {
    font-size: 20px;
  }

  .main-re-from-divs label {
    font-size: 18px;
  }

  .main-re-from-divs .form-control::-webkit-input-placeholder {
    color: #000;
  }

  .expert-login-page .landing-sec-rg {
    width: 100%;
  }

  .landing-sec-rg {
    margin-top: 23px !important;
  }

  .expert-login-page .login-fm-div {
    padding-left: 25px;
    padding-right: 15px;
  }

  .comon-select {
    margin-bottom: 25px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }
}

hr {
  background: rgba(0, 0, 0, 0.5) !important;
}

.comon-table-divs .dropdown .dropdown-menu {
  min-width: 6rem;
  right: 0 !important;
  transform: none !important;
  inset: auto !important;
  margin: 0 0 0 -90px !important;
}

.ais-Pagination-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}

.ais-Hits-item,
.ais-InfiniteHits-item {
  background: transparent;
  border: none;
  padding: 0px;
  outline: none;
  box-shadow: none;
}

.inputs-searchs li,
.card-dropdown {
  padding-left: 1rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
  font-size: 14px;
  border: 1px solid #dfdfdf;
}

Avatar {
  width: 35px;
  height: 35px;
  line-height: initial;
  text-align: center;
  color: #f33636;
  border-radius: 20px;
  background: #efefef;
}

.ais-CurrentRefinements-item {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 7%);
  border-radius: 6px;
  padding: 8px;
}

.ais-CurrentRefinements-delete {
  color: #5c5f62;
}

.ais-CurrentRefinements-label {
  color: #5c5f62;
}

.ais-CurrentRefinements-categoryLabel {
  color: #5c5f62;
}

.ais-CurrentRefinements-category {
  align-items: center;
  justify-content: center;
}

.inputs-searchs li,
input {
  /* height: 2.6rem; */
  /* width: 10.51rem; */
}

/* .ais-SearchBox-input-wrap {
  background-color: white;
  height: 300px !important;
  overflow-y: auto;
  border-radius: 5px;
  position: absolute;
  background-color: #fff;
  min-width: 370px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1; 
  float: none;
  color: black;
  text-decoration: none;
  display: block;
  text-align: left; */
/* } */

.ais-RefinementList-searchBox .ais-SearchBox .ais-SearchBox-form:nth-child(2) {
  background: transparent url(assests/images/location.svg) repeat scroll 0 0;
  content: "";
  height: 1rem;
  left: 1rem;
  margin-top: -0.5rem;
  position: absolute;
  top: 50%;
  width: 1rem;
  color: #f33636;
}

.ais-RefinementList-list {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 13.5rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  float: none;
  color: black;
  text-decoration: none;
  /* display: block; */
  text-align: left;
}

.ais-HierarchicalMenu-item,
.ais-Menu-item,
.ais-NumericMenu-label,
.ais-RatingMenu-item,
.ais-RefinementList-item,
.ais-ToggleRefinement-label {
  display: block;
  font-size: 0.875rem;
  line-height: 1.75rem;
}

.ais-RefinementList-item:hover {
  background: #ededf0;
}

.ais-SearchBox-form {
  border-radius: 8px;
}

/* .ais-RefinementList-list
  .ais-RefinementList-item
  .ais-RefinementList-label
  .ais-RefinementList-checkbox
  :hover {
  background-color: rgba(179, 172, 172, 0.846);
  transition: 0.3s ease-out;
} */

.ais-refinement-list--checkbox {
  background-color: #fff;
  background-position: 50%;
  background-size: 180%;
  border: 1px solid currentcolor;
  border-radius: 3px;
  box-shadow: inset 0 1px 4px 0 rgb(119 122 175 / 40%);
  color: #d6d6e7;
  cursor: inherit;
  height: 1rem;
  margin: 0 0.5rem 0 0;
  min-width: 1rem;
}

.ClearRefinements {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border: none;
}

.ais-RefinementList-item .ais-RefinementList-list {
  display: none;
}

.ais-RefinementList-item:hover .ais-RefinementList-list {
  display: block;
}

.ais-ClearRefinements-button {
  padding: 1px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.text-wrap-afte-two-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-wrap-afte-three-line {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* .css-b62m3t-container,
.css-1s2u09g-control {
  width: 80%;
}

.radio2 {
  border: 1px solid transparent;
  text-align: left;
  align-items: center;
}

.radio2 > input {
  margin-right: 10px;
}

input[type="radio"] {
  transform: scale(1.5);
}

.notificationContainer {
  border: 1px solid transparent;
  width: 90%;
  margin: auto;
  padding-top: 100px;
}

.notificationHeader {
  border: 1px solid transparent;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
}

.notifications {
  border: 1px solid transparent;
  background-color: white;
  border-radius: 12px;
  padding-bottom: 20px;
}

.notificationDiv {
  height: 100px;
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin: auto;
  border-bottom: 1px solid rgb(200, 203, 205);
}

.userIcon {
  border: 1px solid transparent;
  width: 10%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.notificationFrom {
  border: 1px solid transparent;
  width: 78%;
  padding-top: 2.5%;
}

.notificationFrom > div > p {
  color: #5c5f62;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.notificationtime {
  border: 1px solid transparent;
  padding-top: 2.5%;
}

.notificationtime > p {
  color: #5c5f62;
}

.headerProfilePic > img:nth-child(2) {
  margin-top: 8px;
  position: absolute;
  margin-top: 9px;
  right: 20px;
  font-weight: bolder;
  font-size: 20px;
  color: blue;
}
.serch-sec-div .ais-SearchBox .ais-SearchBox-form:nth-child(1) {
  border: solid 2px #f33636;
  height: 43px;
  border-radius: 30px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  /* width: 820px; */
/* overflow: hidden;
  text-decoration: none;
} */

.ais-SearchBox-input:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

/* li:before:nth-child(even) */

.ais-SearchBox-form:before {
  background: transparent url(assests/images/location.svg) repeat scroll 0 0 !important;
  content: "";
  height: 20;
  left: 1rem;
  margin-top: -0.5rem;
  position: absolute;
  top: 50%;
  width: 20;
  color: #f33636;
}

.ais-SearchBox-form:nth-child(2) .ais-SearchBox-form:before {
  background: transparent url(assests/images/company-icon.svg) repeat scroll !important;
  content: "";
  height: 20;
  left: 1rem;
  margin-top: -0.5rem;
  position: absolute;
  top: 50%;
  width: 20;
  color: #f33636;
}

/* .react-datepicker__week .react-datepicker__day--today {
  border-radius: 3rem;
  background-color: #ed1212;
  color: #fff;
} */

.react-datepicker__day-names .react-datepicker__day-name {
  color: #5c5f62;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

/* .react-datepicker__week .react-datepicker__day {
  color: #5C5F62;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
} */

.react-datepicker__header .react-datepicker__current-month {
  font-family: "Times New Roman", Times, serif;
}

.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: red;
  color: #fff;
}

/* .react-datepicker__week,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: red;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
} */

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: blue;
}

.react-datepicker__header {
  text-align: center;
  background-color: #3e82cf;
  border-bottom: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__today-button {
  background: #c21353;
  border-top: 0px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #a66;
  color: #000;
  border: 0px;
  border-radius: 0;
  display: inline-block;
  position: relative;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  /* border-radius: 1.3rem; */
  /* background-color: #216ba5; */
  /* color: #fff; */
  background-color: red;
  border-radius: 15px;
}

.css-iqd1hh-MuiButtonBase-root-MuiChip-root {
  margin-top: 10px !important;
}

@media (max-width: 576px) {
  .serch-sec-div {
    max-width: 100rem;
  }

  .comon-fild h4 {
    font-size: 24px !important;
    font-weight: 500 !important;
  }

  .logo-bottom,
  span {
    color: "#5746ec";
    font-size: 14px !important;
  }
}

.react-datepicker__tab-loop > .react-datepicker-popper {
  z-index: 1000;
}

.jodit-container {
  text-align: left;
}

.next-list li {
  margin-bottom: 10px;
}

.expertPanel_3Zo3_ {
  padding: 16px 18px 16px 2px;
  margin: 0 auto;
}

.section_3ByhA div:not(:last-child) {
  border-bottom: 1px solid #d4d4d4;
}

.detailsRowLeft_3h_zH {
  font-size: 14px;
  font-weight: 500;
  color: #444;
  padding: 0;
}

.detailsRowRight_3h_zH {
  font-size: 14px;
  font-weight: 400;
  color: #444;
  float: right;
}

.detailsRows_2038l {
  padding: 5px 0;
}

.detailsRowLeft_3h_zH {
  font-size: 14px;
  font-weight: 500;
  color: #444;
  padding: 0;
}

.detailsRowRight_3h_zH {
  font-size: 14px;
  font-weight: 400;
  color: #444;
  float: right;
}

.css-13cymwt-control {
  flex: 1 1 0% !important;
  margin-right: 8px !important;
}

.markup-para {
  font-weight: 400;
  font-size: 12px;
  color: #444;
  line-height: 1.61rem;
  width: 85%;
}

.form-check-input:focus {
  box-shadow: none;
}

.markup-para ul,
.markup-para ol {
  padding-left: 2rem;
}

.markup-para ul li {
  list-style: disc;
}

.markup-para ol li {
  list-style: decimal;
}

.markup-para strong {
  font-weight: 600;
}

.nextyn_list_items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nextyn_list_item:not(:last-child) {
  border-bottom: 1px solid #d4d4d4;
}

.nextyn_list_item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 8px 0;
  color: #3b384b;
  line-height: 0;
}

.expertCompany_2UyI2 {
  padding: 5px 0;
}

.nextyn_list_items {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: -20px;
}

.expertCompany_2UyI2 .title_1p_LH {
  line-height: 18px;
  font-weight: 450;
  margin-bottom: 8px;
  color: #000;
  font-size: 14px;
}

.expertCompany_2UyI2 .companyName_1dSwA {
  line-height: 100%;
  color: #444;
  font-weight: 400;
  font-size: 12px;
  margin-top: 7px;
  margin-bottom: 5px;
}

.careerDates_3sJ2b {
  color: #444;
  margin-left: auto;
  white-space: nowrap;
  padding-left: 16px;
  font-size: 14px;
}

.dropdown-menu .dropdown-item:active {
  background-color: #5746ec;
}

#header.active {
  border-left: none;
  /* border: 0 0 5px rgba(0, 0, 0, 0.4); */
  border-left: none;
  padding-bottom: 70px;
  top: 0;
  z-index: 999;
  /* background-color: #ffffff; */
  transition: top 0.5s ease-in-out;
}

.roles-table {
  border-spacing: 50px;
}

.roles-table > td {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.openfile {
  display: block;
  height: 50px;
  margin: 1px auto 0;
  width: 50px;
  /* background-color: #00a82d; */
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 212, 255, 1) 0%, rgba(9, 9, 121, 1) 100%);
  padding: 10px;
  border-radius: 25px;
}

.modal-header .btn-close {
  /* margin-top: -20px; */
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #5746ec !important;
}

.dropdown-item.active svg,
.dropdown-item svg:active {
  fill: #fff !important;
  text-decoration: none;
  background-color: #5746ec !important;
}

.ais-CurrentRefinements .ais-CurrentRefinements-list {
  display: flex;
  flex-wrap: wrap;
}

.ais-CurrentRefinements-item {
  display: flex;
  flex-wrap: wrap;
}

.logo-admin {
  padding: 0px 25px 0;
}

.mainMenuUL::-webkit-scrollbar {
  width: 3px;
}

.mainMenuUL::-webkit-scrollbar-track {
  background: #f33636;
}

.mainMenuUL::-webkit-scrollbar-thumb {
  background-color: #ededf0;
  /* outline: 1px solid slategrey; */
}

.ais-CurrentRefinements-list {
  margin-bottom: 10px;
  margin-top: 6px;
}

.ais-CurrentRefinements-category,
.ais-CurrentRefinements-delete {
  margin-top: 2px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .page-item.active .page-link {
  z-index: 3;
  color: #000;
  background-color: #0d6efd;
  border-color: #0d6efd;
} */

.page-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff !important;
  background-image: linear-gradient(-180deg, #fff, #fcfcfd) !important;
  border: 1px solid #d6d6e7 !important;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgb(35 38 59 / 5%);
  color: #23263b !important;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex !important;
  font-size: 0.875rem;
  font-weight: 400;
  height: 2rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.25rem;
  padding: 0 1rem !important;
  place-items: center;
  text-decoration: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0;
  margin-left: -1px;
}

.page-item.active .page-link {
  border-color: #d6d6e7;
  box-shadow: inset 0 1px 4px 0 rgb(119 122 175 / 40%), inset 0 1px 1px 0 rgb(119 122 175 / 40%), 0 1px 0 0 rgb(35 38 59 / 5%);
}

.pagination-background {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.pagination {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff !important;
  background-image: linear-gradient(-180deg, #fff, #fcfcfd) !important;
  border: 1px solid #d6d6e7 !important;
  border-radius: 3px !important;
  box-shadow: 0 1px 0 0 rgb(35 38 59 / 5%);
  color: #23263b !important;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex !important;
  font-size: 0.875rem;
  font-weight: 400;
  height: 2rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.25rem;
  padding: 0 0rem !important;
  place-items: center;
  text-decoration: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0;
  margin-left: -1px;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -ms-border-radius: 3px !important;
  -o-border-radius: 3px !important;
}

.pagination li a {
  padding: 7px 1rem !important;
  color: #23263b;
}

.pagination li.active a {
  border-color: #d6d6e7;
  box-shadow: inset 0 1px 4px 0 rgb(119 122 175 / 40%), inset 0 1px 1px 0 rgb(119 122 175 / 40%), 0 1px 0 0 rgb(35 38 59 / 5%);
  font-weight: 700;
}

.comon-table-td span {
  color: #5746ec;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.dataItem b {
  color: #5746ec;
}

.scrolling-wrapper {
  overflow-x: auto;
}

.css-12g71p8 {
  display: flex !important;
  flex-wrap: nowrap !important;
}

.dropdown-item:hover {
  fill: #fff !important;
  color: #fff !important;
  background-color: #5746ec !important;
}

/* .dropdown-item svg:hover {
  fill: #fff !important;
} */

/* .card-dropdown {
  background: url(./assests/images/location.svg) no-repeat scroll 7px 7px;
  padding-left: 30px;
} */

.video-player video {
  width: 100%;
  height: calc(100vh - 200px) !important;
  margin: auto;
}

.coverInnerBreadcum span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: auto;
  max-width: -webkit-fill-available;
  font-weight: 500;
}

.new-reguest {
  background: #fff !important;
  color: #5746ec !important;
  width: 100%;
  border: solid 1.4px #5746ec !important;
  /* font-size: 16px !important; */
  font-weight: 500 !important;
  height: 40px;
  text-align: center;
  transition: all 0.5s;
  line-height: 24px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.comon-tabs-expertcalls {
  border-top-left-radius: 10px !important;
  top: 70px !important;
  width: 50vw !important;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #5746ec !important;
  border-right-width: 1px;
}

.viewButton {
  background-color: #5746ec;
  color: #fff;
  font-weight: 500;
}

.search-result {
  display: block;
  text-decoration: none;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 16px;
  margin: 8px 0;
}

.ng-star-inserted h5 {
  color: #05294b;
  font-size: 16px;
  font-weight: 600;
  line-height: 2rem;
  font-family: AvertaStd;
}

.ng-star-inserted .timinig {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  font-family: AvertaStd;
  color: #5d7284;
}

.ng-star-inserted .para {
  font-size: 16px;
  font-weight: 400;
  line-height: 2rem;
  font-family: AvertaStd;
  color: #05294b;
}

/* .ant-space-item {
  font-weight: 500;
  border: 1px solid #dfdfdf;
  box-shadow: 0 3px 3px rgb(0 0 0 / 7%);
  border-radius: 6px;
  background: #ffff;
} */

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #5746ec !important;
  background: #fff;
  border-color: #5746ec !important;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: #5746ec !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #5746ec !important;
}

.ant-picker-header-view button:hover {
  color: #5746ec !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background-color: #5746ec !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: #5746ec !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #5746ec !important;
  color: #fff !important;
}

.ant-select-item-option-content a {
  color: #000 !important;
}

.ant-select-item-option-content a:hover {
  color: #5746ec !important;
}

.ant-select-item-group {
  margin-top: 10px;
}

@media screen and (max-width: 1200px) {
  .main-menu {
    height: auto;
    /* overflow-y: auto; */
    position: fixed;
    /* width: 150px; */
    overflow-y: scroll;
    top: 3.5rem;
    bottom: 0rem;
    padding: 25px 60px;
  }
}

/* media query */

@media (min-width: 1280px) {
  .select-option {
    max-width: 87% !important;
  }

  .right-menu-sec ul li {
    /* padding: 0.5rem 0.5rem !important; */
    margin-right: 7px;
  }

  .dataResult {
    width: 77% !important;
  }
}

@media (max-width: 992px) {
  .request-body-sec {
    margin-top: 10px;
  }

  /* .Item-inside {
		height: 340px;
		width: 300px;
	} */
  .top-bar-menu {
    display: flex;
    height: 62px;
    position: fixed;
    right: 0;
    transition: all 0.5s;
    z-index: 94;
    background: #f8f8f8;
    left: 0 !important;
    padding-left: 57px;
    padding-right: 42px;
    padding-top: 25px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 70px;
  }

  .table tr td .createdDate {
    width: 10rem;
  }

  /* .project-sec-2 {
    display: none !important;
  } */
}

@media (max-width: 768px) {
  .projects-scope .top-ps-seection {
    font-size: 5px;
    /* padding-top: 60px !important; */
  }

  /* .Item-inside {
		height: 340px;
		width: 300px;
	} */
  .top-bar-menu {
    display: flex;
    height: 62px;
    position: fixed;
    right: 0;
    transition: all 0.5s;
    z-index: 94;
    background: #f8f8f8;
    left: 0 !important;
    padding-left: 57px;
    padding-right: 42px;
    padding-top: 25px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 70px;
  }

  .number-list span {
    width: 10px;
    margin-right: 5px;
  }

  .search-btn-back {
    font-size: 18px !important;
    font-weight: 500 !important;
    padding-left: 0px !important;
  }

  .messageScreen button {
    padding: 0.5rem 0.5rem;
  }

  .projects-pages .top-ps-seection .nav,
  .projects-scope .top-ps-seection .nav {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 0;
  }

  .comon-table-td span {
    width: 10rem;
  }

  .notifications {
    padding: 5px 9px;
  }

  .notificationFrom {
    border: 1px solid transparent;
    width: 66%;
    padding-top: 2.5%;
  }

  /* .modal-backdrop {
    display: none!important;
  } */
  .regitser-page-div {
    width: 90% !important;
  }

  .logo {
    margin: 10px 0 0 1px !important;
    display: inline-block;
  }

  .login-page-div {
    width: 90% !important;
  }
}

/* .dropdown-item span:hover {
  color: #fff;
} */

@media (max-width: 576px) {
  .table tr th {
    font-size: 5px;
  }

  .top-ps-seection a {
    margin-top: 10px;
  }

  .top-ps-seection button {
    margin-top: 0 !important;
  }

  .projects-pages .top-ps-seection .nav-pills .nav-link,
  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 112px !important;
    margin-bottom: 0;
  }

  .number-list span {
    width: 10px;
    margin-right: 5px;
  }

  .top-ps-seection p {
    margin-top: 20px;
  }

  .top-bar-menu {
    display: flex;
    height: 62px;
    position: fixed;
    right: 0;
    transition: all 0.5s;
    z-index: 94;
    background: #f8f8f8;
    left: 0;
    padding-left: 57px;
    padding-right: 42px;
    padding-top: 25px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 70px;
  }

  .notificationFrom {
    border: 1px solid transparent;
    width: 66%;
    padding-top: 20px;
    padding-left: 16px;
  }

  .notificationFrom h5 {
    font-size: 16px;
  }

  .main-menu {
    padding: 0;
  }

  .select-option {
    max-width: 100% !important;
  }

  .select-option .ais-RefinementList-list {
    width: 43.7% !important;
  }

  .dasb-div .select-option .flex-column {
    margin-top: 10px;
  }

  .top-bar-menu {
    padding-top: 4% !important;
    padding-bottom: 10% !important;
  }

  .hour span {
    font-size: 18px;
  }

  .new-reguest {
    font-size: 14px !important;
  }

  .body-expart-div h5 {
    font-size: 16px !important;
  }

  .totalExperience {
    font-size: 13px !important;
    margin-bottom: 0;
  }

  .text-body-small {
    font-size: 0.775rem !important;
  }

  /* .modal-backdrop {
    display: none !important;
  } */
  .regitser-page-div {
    width: 86% !important;
  }

  .logo {
    margin: 10px 0 0 1px !important;
    display: inline-block;
    margin-bottom: 10px !important;
  }

  .login-page-div {
    width: 86% !important;
  }

  .top-ps-seection .nav {
    margin-top: 15px !important;
    margin-bottom: 10px !important;
  }

  .dasb-div {
    padding-top: 2px !important;
    padding-bottom: 100px !important;
  }

  .projects-pages {
    /* margin-top: 80px !important; */
  }

  .createBttnNotes {
    position: absolute !important;
    z-index: 9;
    font-size: 11px;
    top: 37rem !important;
    right: 18px;
    z-index: 9;
    width: 120px;
  }

  .createBttnNotes button {
    font-size: 10px;
  }

  .mobile-canvas {
    width: 284px !important;
  }

  .messageEditorWrapper {
    background-color: rgb(255, 255, 255);
    position: absolute;
    bottom: 10px;
    width: 100% !important;
  }

  .bi-send {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    background: rgb(87, 70, 236);
    text-align: center;
    color: rgb(255, 255, 255);
    border-radius: 50%;
    line-height: 2.3 !important;
    cursor: pointer;
    z-index: 9999;
    top: 112px !important;
    /* right: 1px; */
    height: 30px !important;
    width: 30px !important;
  }

  .request-body-sec {
    margin-top: -20px !important;
  }

  .comon-tabs-expert .offcanvas-end {
    width: 95vw !important;
  }

  .shareableLink {
    font-size: 12px !important;
  }

  .inputGroups .dropdownBtn {
    font-size: 12px !important;
  }

  .accessInfo .s1E8jgYqDWXyHdyBw59R {
    font-size: 14px !important;
  }

  .Yourr_9w71MjtL3S9I4G .dropdownBtn {
    font-size: 14px !important;
  }

  .nameInfo span {
    font-size: 14px !important;
  }

  .ZP9BDnxHhGA9rSLoFsa3 .cDCnsp1vcViX1pVJM7MV {
    font-size: 14px !important;
  }

  textarea.ant-input {
    font-size: 22px !important;
  }

  .comon-divs-re .right-divs-2 {
    margin-top: 5px !important;
    /* margin-bottom: 5px !important; */
  }

  .tab-pane .offcanvas-end {
    width: 90vw !important;
  }

  .dasb-div .projects-pages2 {
    margin-top: 60px !important;
  }

  .top-ps-seection .nav {
    padding: 1px 1px 1px !important;
  }

  .body-expart-div h2 {
    white-space: pre-line !important;
    font-size: 18px !important;
  }

  .table tr td {
    min-width: 4rem !important;
  }

  .project-sec-1 {
    display: none;
  }

  .notificationContainer {
    width: 100% !important;
  }

  .noResultFound img {
    width: 35% !important;
    height: 35% !important;
  }

  .noResultFound h3 {
    font-size: 14px !important;
  }

  .inputs-search {
    margin-top: 8px;
  }
}

.notificationtime p {
  white-space: nowrap;
}

@media screen and (min-width: 100px) and (max-width: 480px) {
  .top-bar-menu {
    padding-top: 4% !important;
    padding-bottom: 12% !important;
  }

  .dasb-div .select-option .flex-column {
    margin-top: 0;
  }

  .findExperts .find_companyName {
    font-size: 18px !important;
    white-space: break-spaces !important;
  }

  .findExperts .linkH5 {
    white-space: break-spaces !important;
  }

  .tp-list1 li {
    font-size: 15px !important;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .findExperts .expertRate {
    font-size: 22px !important;
  }

  .hour span {
    font-size: 18px;
  }

  .jodit-toolbar__box:not(:empty) {
    width: 92% !important;
  }

  .projects-pages .top-ps-seection .nav-pills .nav-link,
  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 100px !important;
    margin-bottom: 0;
  }

  .noResultFound img {
    width: 90% !important;
    height: 90% !important;
  }

  /* .jodit-ui-group {
    display: flex !important;
    flex-wrap: nowrap !important;
} */
}

@media screen and (min-width: 100px) and (max-width: 430px) {
  .pos-relative .box {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1366px) {
  .select-option {
    width: 100% !important;
  }

  .right-menu-sec ul li {
    padding: 0.5rem 0.5rem !important;
    margin-right: 7px;
  }

  .top-bar-menu .serch-sec-div {
    width: 96%;
  }

  .dataResult {
    width: 76.9% !important;
  }
}

@media (max-width: 1280px) {
  .top-bar-menu .serch-sec-div {
    width: 96%;
  }
}

/* @media (max-width: 1366px) {
  .select-option {
    width: 83.3% !important;
  }
  .right-menu-sec ul li {
    padding: 0.5rem 0.5rem !important;
  }
} */

@media screen and (min-width: 1500px) and (max-width: 1680px) {
  .select-option {
    max-width: 98% !important;
  }

  .right-menu-sec ul li {
    /* padding: 0.5rem 0rem !important; */
  }

  .top-bar-menu .serch-sec-div {
    width: 96% !important;
  }

  .dataResult {
    width: 82.2% !important;
  }
}

@media only screen and (max-width: 1440px) {
  .select-option {
    max-width: 100% !important;
  }

  .top-bar-menu .serch-sec-div {
    width: 96% !important;
  }
}

@media (min-width: 1920px) {
  .select-option {
    max-width: 97.5% !important;
  }

  .top-bar-menu .serch-sec-div {
    width: 96%;
  }

  .dataResult {
    width: 84.2% !important;
  }
}

/* Foote */
/* .adminMessage .sdee .msgContent .avatarImg {
  border: 2px solid #fff;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 20%);
  width: 30px;
  height: 30px;
  color: #f33636;
  background-color: #efefef;
  font-size: 10px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
} */

/* .total-notes {
  color: #000000;
  padding: 12px 16px;
  text-align: center;
} */

/* expert->CSS */
/* @charset "utf-8"; */
/* CSS Document */

@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

*,
::after,
::before {
  box-sizing: border-box;
}

a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
tt,
ul,
var {
  border: 0;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: none !important;
  padding: 0;
  vertical-align: baseline;
  font-family: "Work Sans", sans-serif;
}

.login-page .row > div:nth-child(2) {
  padding-left: 0;
}

.lable-input button {
  color: #000;
  border: none;
}

body {
  margin: 0;
  padding: 0;
  background: #fff;
  background-repeat: repeat-x;
  font-family: "Work Sans", sans-serif;
  font-size: 17px;
  color: #333333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 20px 0;
}

h1 {
  color: #181818;
  font-size: 40px;
  font-weight: 600;
}

h2 {
  font-size: 30px;
  font-weight: 400;
}

a {
  color: inherit;
  outline: none !important;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

button,
button:focus {
  outline: none !important;
}

ol {
  padding-left: 0;
}

ol li {
  list-style: inherit;
}

ul li {
  list-style: none;
}

ul {
  padding-left: 0;
}

li,
ul {
  list-style: none;
}

img {
  vertical-align: top;
  max-width: 100%;
  border: none;
  outline: none;
}

.login-page .container-fluid {
  padding-left: 0;
}

.logo {
  margin: 34px 0 0 40px;
  display: inline-block;
}

.new-register-page .login-left-part {
  min-height: 100% !important;
  background-size: cover;
}

.back-rgs {
  position: absolute;
  top: 40px;
  left: 57%;
  color: #666666 !important;
  font-size: 18px !important;
  background: none !important;
}

.backs2 {
  color: #666666 !important;
  font-size: 18px !important;
}

.backs2 svg {
  position: relative;
  top: -2px;
}

.expert-login-page .login-left-part {
  min-height: 100vh !important;
}

.expert-login-page .landing-sec-rg {
  margin-top: 130px;
}

.comon-dt-picker .react-date-picker__wrapper {
  border: none !important;
}

.comon-dt-picker {
  width: 50%;
  margin-right: 20px;
  padding: 0;
  display: grid;
  align-content: center;
  justify-content: center;
}

.comon-dt-picker .react-date-picker {
  width: 100%;
  height: 31px;
}

.react-date-picker__clear-button {
  display: none;
}

.add-btn-divs .btn,
.remove-btn {
  padding: 0 !important;
  width: 45px;
  height: 45px;
  background: #f2f1ff !important;
  border: 1.5px solid rgba(87, 70, 236, 0.3) !important;
  border-radius: 10px;
}

.remove-btn {
  margin: 10px 0;
  color: #5746ec !important;
}

.main-re-from-divs textarea.form-control {
  height: 100px;
  resize: none;
}

.new-register-page .row > div:nth-child(2) {
  padding-left: 15px !important;
}

.services1 {
  width: 100%;
}

.back-rgs svg {
  position: relative;
  top: -2px;
}

.logo-in-div {
  margin-top: 40px;
}

.login-left-part h1 {
  font-weight: 500;
  font-size: 2vw;
}

.modal-success .modal-header {
  border: none;
}

.success-div h2 {
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.modal-success .modal-dialog .modal-content {
  border-radius: 12px;
}

.success-div p {
  font-size: 18px;
  color: #5c5f62;
}

.modal-success .modal-lg {
  max-width: 680px;
  height: 370px;
}

.success-div {
  margin-bottom: 50px;
}

.modal-success .modal-title {
  visibility: hidden;
}

.img-box-1 {
  position: relative;
  width: 100%;
  display: inline-block;
  position: relative;
  top: -25px;
}

/* aminmation js */

.up-down {
  animation: up-down linear 4s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: up-down linear 4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: up-down linear 4s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation: up-down linear 4s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation: up-down linear 4s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}

.login-page-div {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.comon-fild h4 {
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 500;
}

.comon-fild label {
  font-weight: 400;
  color: rgb(132 147 166);
  font-size: 14px !important;
  margin-bottom: 8px;
}

.fa-eye-slash:before {
  content: "\f070";
}

.lable-input {
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
}

.inside-login .form-control {
  border-radius: 8px;
}

.inside-login .form-control:focus {
  box-shadow: none !important;
}

.lable-input .form-control {
  border: none;
}

.sp-input > div:first-child {
  width: 13%;
}

.sp-input > div:last-child {
  width: 84%;
}

.field-icon {
  cursor: pointer;
  float: right;
  margin-left: -25px;
  margin-top: -1px;
  position: relative;
  z-index: 2;
}

.login-btn1 {
  color: #f12435;
  font-weight: 500;
}

.login-btn1:hover {
  color: #000;
}

.inside-login .form-group {
  margin-bottom: 20px;
}

.inside-login p {
  font-size: 20px;
  margin-bottom: 30px;
  color: rgb(132 147 166);
}

.register-btn {
  font-size: 16px;
  font-weight: 500;
  color: #5746ec;
  transition: all 0.5s;
}

.register-btn:hover {
  color: #000 !important;
}

.dasb-div {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.dasb-div2 {
  padding-top: 100px !important;
  /* padding-bottom: 100px !important; */
}

.user-acunt {
  position: relative;
  padding-left: 10px;
}

.user-acunt:after {
  position: absolute;
  right: 12px;
  top: 6px;
  content: "";
  /* background-image: url(../public/images/svg-bn1.svg); */
  background-repeat: no-repeat;
  width: 13px;
  height: 23px;
  background-size: 75%;
}

.ex-log-dp .dropdown-item {
  border: none !important;
}

.ex-log-dp li:nth-child(3) {
  margin: 10px 0;
}

.ex-log-dp .dropdown-item {
  font-size: 15px !important;
  color: #5c5f62 !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
}

.ex-log-dp .dropdown-item:hover {
  background: none !important;
  color: #5746ec !important;
}

.no-recod-found p {
  color: #5c5f62;
}

.ex-log-dp .dropdown-item span {
  margin-right: 5px;
}

.ex-log-dp li hr {
  width: 88%;
  margin: auto !important;
}

.user-acunt figure {
  margin-right: 10px !important;
}

.user-acunt p {
  font-size: 16px;
  color: #000;
}

.user-acunt a {
  color: #5746ec;
  text-decoration: none;
  font-size: 14px;
}

.body-expart-div {
  padding-top: 20px;
}

.body-expart-div h2 {
  font-size: 20px !important;
  font-weight: 500;
  color: #000;
}

.body-expart-div h6 {
  color: #5c5f62;
  font-weight: 400;
  margin: 10px 0 10px 0;
}

.auto-sec h6,
.comon-notes h6 {
  font-size: 14px;
}

.call-history-table {
  display: inline-block;
  width: 95%;
}

.comon-divs-re {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  margin-bottom: 30px;
  width: 95%;
}

.comon-divs-re h5 {
  font-weight: 300;
  margin-bottom: 10px;
}

.new-reguest {
  background: #fff !important;
  color: #5746ec !important;
  width: 100%;
  border: solid 1.4px #5746ec !important;
  /* font-size: 16px !important; */
  font-weight: 500 !important;
  height: 40px;
  text-align: center;
  transition: all 0.5s;
  line-height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-reguest:hover {
  background: #5746ec !important;
  color: #fff !important;
}

.right-divs-2 {
  display: inline-block;
  width: 100%;
  position: relative;
}

.sp-hr {
  width: 90%;
  margin-left: 55px;
}

.notification-div li {
  display: flex;
  margin-bottom: 15px !important;
  width: 100%;
}

.notification-div li > div {
  align-items: center;
  /* border-bottom: 1px solid #f0f0f0; */
  padding-bottom: 10px;
}

.comon-select .form-select {
  color: #000;
}

.notification-div li > div:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.notificaion-dp h4 {
  font-size: 20px;
  font-weight: 600;
}

.number-message1 {
  color: #5746ec;
}

.call-history-table .comon-table-td {
  width: 56%;
  display: inline-block;
}

.call-history-table .auto-td h6 {
  margin: 0;
}

.call-history-table .auto-td h6 span {
  color: #000;
  font-weight: 600;
  font-size: 18px;
}

.call-history-table .option-td p {
  margin: 0;
}

.right-menu-sec ul li a {
  cursor: pointer;
}

.new-add-rg2 .row > div:nth-child(2) {
  padding-left: 15px !important;
}

.clear-bnt {
  color: #ea5455;
  font-size: 16px !important;
  text-decoration: none;
}

.account-div,
.cart-dropdown-ne {
  border: none;
  display: block !important;
  left: inherit !important;
  right: 0;
  top: 120%;
  visibility: hidden;
  width: 310px;
}

.ne-usd {
  display: flex;
  align-items: center;
}

.ne-usd span {
  display: flex;
}

.ne-usd span b {
  border-right: 1px solid #666666;
  padding-right: 10px;
  font-weight: 600 !important;
}

.file-sign {
  background: #f2f1ff !important;
  border: 1.5px solid rgba(87, 70, 236, 0.3) !important;
  border-radius: 10px !important;
  width: 100% !important;
  color: #5746ec !important;
}

.last-details {
  background: #ededf0;
  border-radius: 10px;
  padding: 20px;
}

.last-details figure {
  background: #c4c4c4;
  width: 125px;
  height: 67px;
  margin-bottom: 0;
}

.sign-modal-div .modal-title {
  visibility: hidden;
}

.sign-modal-div .modal-header {
  border-bottom: none !important;
}

.last-details h5 {
  color: #000 !important;
  font-size: 16px;
}

.comon-sign1 {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.comon-sign1 .form-control {
  border: none !important;
  background: none !important;
  width: 22% !important;
}

.ne-usd .form-control {
  border: none !important;
  background: none !important;
  height: 30px;
}

.file-upload {
  position: relative;
  background: rgba(87, 70, 236, 0.06);
  border: 1.2px dashed #5746ec;
  border-radius: 8px;
  width: 100%;
  height: 100px;
  cursor: pointer;
}

.file-upload input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  visibility: hidden;
  cursor: pointer;
}

.sign-modal-div .form-check-input {
  position: relative;
}

.sign-modal-div .form-check-input:focus {
  box-shadow: none !important;
}

.sign-modal-div .form-check-input:after {
  width: 22px;
  height: 21px;
  content: "";
  border: solid 1px #b8b8b8;
  overflow: hidden;
  position: absolute;
  left: -3px;
  top: -4px;
  background: #fff;
  border-radius: 50%;
}

.sign-modal-div .form-check-input:checked:after {
  background: #5746ec;
  color: #fff;
  content: "\f00c";
  border: solid 1px #5746ec;
  font-family: "FontAwesome";
  line-height: 20px;
  text-align: center;
  font-size: 14px;
}

.complets-td-divs .modal-header {
  border: none !important;
}

.complets-td-divs h2 {
  font-size: 24px;
  font-weight: 500;
}

.complets-td-divs .modal-title {
  visibility: hidden;
}

.text-a1 {
  color: #5746ec;
  font-weight: 500;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 81%;
}

.text-a1 p {
  margin-bottom: 0;
  margin-left: 10px;
}

.account-div.show,
.cart-dropdown-ne.show {
  top: 100%;
  visibility: visible;
}

.right-divs-2:after {
  position: absolute;
  content: "";
  left: -21px;
  width: 1px;
  height: 192px !important;
  background: #e7e7e7;
  top: -32px;
}

.main-re-from-divs .in-code {
  width: 15%;
}

.main-re-from-divs .full-number {
  width: 80%;
}

.right-divs-2 h5 {
  font-weight: 500;
  color: #000;
}

.right-divs-2 h5 span {
  color: #5c5f62;
  font-size: 14px;
}

.request-btn {
  background: #5746ec !important;
  color: #fff !important;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  height: 44px;
  text-align: center;
  transition: all 0.5s;
}

.request-btn:hover {
  background: #000 !important;
}

.tp-list1 {
  padding: 0;
  margin: 0 0 0 4px;
}

.next-list li {
  border: solid 1.2px #d9d9d9;
  border-radius: 7px;
  background: #fff;
  padding: 5px 12px;
  display: inline-block;
  font-size: 13px;
  margin-right: 13px;
  font-weight: 500;
  /* cursor: pointer; */
  transition: all 0.5s;
  color: #5c5f62;
}

/* .next-list li:hover {
  border-color: #f33636;
  color: #f33636;
} */
.tp-list1 li {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  color: #5c5f62;
  margin-right: 15px;
}

.tp-list1 li span {
  margin-right: 10px;
}

/* sidebar */

.main-menu {
  height: auto;
  position: fixed;
  top: 4rem;
  bottom: 0rem;
  /* min-width: 18%; */
}

.main-menu .mainMenuUL {
  padding: 25px 25px 0;
  width: 250px;
  max-height: calc(100vh - 170px);
  overflow-y: auto;
}

.top-bar-menu {
  display: flex;
  height: 62px;
  position: fixed;
  right: 0;
  transition: all 0.5s;
  z-index: 94;
  background: #f8f8f8;
  left: 250px;
  padding-left: 57px;
  padding-right: 42px;
  padding-top: 25px;
  /* border-bottom: 1px solid lightgrey; */
  padding-bottom: 70px;
}

.notification-div {
  padding-left: 11px;
}

.notification-div h5 {
  font-size: 16px;
  font-weight: 500;
}

.notification-div h6 {
  color: #bbb;
  font-size: 14px;
  margin-left: 11px;
}

.notification-div span {
  font-weight: 400;
  color: #bbb;
  font-size: 13px;
  margin-top: 5px;
}

.bel-icon {
  padding: 0 !important;
  color: #5c5f62;
}

.bel-icon svg {
  color: #5c5f62;
  font-size: 24px;
}

.right-menu-sec ul li:nth-child(1) .nav-link {
  position: relative;
}

.right-menu-sec ul li {
  position: relative;
}

.right-menu-sec ul li:nth-child(1) .nav-link:after {
  background: #fe0000;
  border-radius: 50%;
  content: "";
  font-size: 10px;
  height: 8px;
  overflow: hidden;
  position: absolute;
  right: -5px;
  top: 1px;
  width: 8px;
}

.account-div .dropdown-item {
  /* border-bottom: 1px solid #dadada; */
  padding-bottom: 8px;
}

.account-div .dropdown-item,
.account-div li:nth-child(2) a.dropdown-item {
  /* color: #5c5f62; */
  font-size: 16px;
  /* font-weight: 600; */
}

.account-div {
  border: none !important;
  width: 230px !important;
}

.account-div.show {
  top: 40% !important;
}

.account-div:before {
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  height: 10px;
  position: absolute;
  right: 10px;
  top: -10px;
  width: 10px;
}

.account-div,
.cart-dropdown-ne {
  border: none;
  display: block !important;
  left: inherit !important;
  right: 0;
  top: 120%;
  visibility: hidden;
  width: 310px;
}

.right-menu-sec {
  align-items: center;
  display: flex;
}

.right-menu-sec ul {
  align-items: center;
}

/* .right-menu-sec ul li {
  margin: 0 0;
} */
.right-menu-sec .nav-link {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 7px;
}

@media only screen and (min-width: 1300px) and (max-width: 1366px) {
  .right-menu-sec .nav-link {
    padding-left: 0;
  }
}

.main-menu ul li a {
  align-items: center;
  color: #5c5f62;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 1px;
  padding: 12px 0 12px 15px;
  text-decoration: none;
  transition: all 0.5s;
  align-items: center;
}

.main-menu ul li h5 {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 10px;
}

.main-menu ul li {
  margin: 20px 0;
}

.main-menu ul li a {
  display: flex;
  align-items: center;
}

.main-menu ul li a .menu-icon-new {
  font-size: 18px;
  margin-right: 10px;
}

.main-menu ul li a .menu-text {
  font-size: 14px;
}

.top-bar-menu .serch-sec-div {
  border: solid 2px #f33636;
  height: 43px;
  border-radius: 30px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  overflow: hidden;
}

.top-bar-menu .serch-sec-div .form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
  /* box-shadow: inset 0 1px 4px 0 rgb(119 122 175 / 30%); */
  caret-color: #5a5e9a;
  color: #23263b;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  font: inherit;
  max-width: 100%;
  /* padding-left: 2.5rem; */
}

.user-div-top figure {
  width: 35px;
}

.form-control:focus {
  box-shadow: none !important;
}

.top-bar-menu .serch-sec-div svg {
  color: #f33636;
}

.main-menu ul li a .menu-icon-new img {
  opacity: 0.6;
  width: 18px;
}

.main-menu ul li a.active,
.main-menu ul li a:hover {
  background: #ededf0;
  border-radius: 10px;
  color: #000;
}

.main-menu ul li a.active .menu-icon-new img,
.main-menu ul li a:hover .menu-icon-new img {
  opacity: 9;
}

/*  swith 03-06-2022 */

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 48px;
  height: 30px;
  background: #9d9d9d;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 23px;
  height: 23px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

.comon-payments-ex {
  width: 95%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  height: 150px;
  display: grid;
  align-content: center;
  padding: 25px;
  margin-bottom: 30px;
  position: relative;
}

.comon-card-n {
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  display: grid;
  align-content: center;
  width: 100%;
  height: 194px;
  padding: 20px;
  transition: all 0.5s;
  transform: scale(1);
}

.comon-card-n:hover {
  transform: scale(1.1);
}

.all-complains {
  display: inline-block;
  width: 95%;
}

.comon-cplens {
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  background: #fff;
  display: inline-block;
  width: 100%;
  padding: 28px;
  margin-bottom: 30px;
  transition: all 0.5s;
}

.comon-cplens h5 {
  text-transform: uppercase;
}

.comon-cplens p {
  font-size: 16px;
  color: #5c5f62;
  margin: 15px 0;
}

.titels-new {
  text-decoration: none;
  color: #000;
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}

.com-li li {
  display: flex;
  align-items: center;
}

.com-li li span:first-child {
  width: 21px;
  display: inline-block;
  margin-right: 2px;
}

.ex-project-details .requset-1btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-ps-modal .modal-title {
  visibility: hidden;
}

.edit-ps-modal .modal-header {
  border: none !important;
}

.edit-ps-modal .add-btn {
  color: #5746ec;
}

/* li:hover a, */
li:hover .ant-checkbox-wrapper {
  color: #ffff !important;
}

.edit-ps-modal p {
  font-weight: 400;
  color: #666666;
  font-size: 15px;
}

.edit-ps-modal a {
  color: #5746ec;
}

.submit-save {
  background: #5746ec !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  height: 45px;
  color: #fff !important;
  transition: all 0.5s;
}

.edit-ps-modal .form-group {
  margin-bottom: 25px !important;
  display: inline-block;
  width: 100%;
}

.edit-ps-modal .form-group label {
  color: #000 !important;
}

.submit-save:hover {
  background: #000 !important;
}

.edit-ps-modal .form-select:focus {
  box-shadow: none !important;
}

.md-titel-link {
  color: #5746ec;
  cursor: pointer;
}

.edit-ps-modal .form-control {
  background: none !important;
}

.ex-project-details .requset-1btn span {
  position: relative;
  margin-right: 5px;
  top: -2px;
}

.text-email-modal .modal-title {
  visibility: hidden;
}

.text-email-modal .modal-header {
  border-bottom: 0 !important;
}

.text-email-modal .modal-dialog {
  max-width: 700px;
}

.area-email .jodit-status-bar {
  display: none;
}

.area-email {
  border: 1px solid #ced4da !important;
  border-radius: 5px;
  position: relative;
  height: 235px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}

.ed-btn {
  cursor: pointer;
}

.area-email .jodit-container:not(.jodit_inline) {
  border-radius: 3px;
  display: flex;
  flex-direction: column-reverse;
  border: none !important;
  min-height: 215px !important;
  height: 215px !important;
  top: 0;
  position: relative;
}

.area-email .jodit-toolbar__box {
  position: absolute !important;
  left: 50%;
  top: 80% !important;
  transform: translateX(-50%);
  margin: auto !important;
}

.totoal-re-div {
  display: inline-block;
  width: 100%;
}

.go2441762052 {
  display: none;
}

.landing-sec-rg {
  margin-top: 30px;
}

.landing-page-d1 .login-left-part {
  min-height: 100vh;
}

.landing-sec-rg h5 {
  color: #5746ec;
}

.landing-sec-rg ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.landing-sec-rg ul li svg {
  color: #5746ec;
  width: 11px;
  height: 11px;
  position: relative;
  top: -2px;
}

.in-btn1 {
  background: #2977b5 !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  width: 100%;
  height: 45px;
  color: #fff !important;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
}

.in-btn1 span {
  margin-right: 10px;
}

.or-sec {
  position: relative;
  text-transform: capitalize;
  color: #666666;
}

.or-sec span {
  background: #fff;
  padding: 10px 25px;
  font-size: 18px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.in-btn1 img {
  position: relative;
  top: -3px;
}

.main-re-from-divs label {
  margin-bottom: 8px;
}

.main-re-from-divs .form-group {
  margin-bottom: 20px;
}

.main-re-from-divs .form-control,
.main-re-from-divs .form-select {
  height: 45px;
  border-radius: 8px !important;
}

.conti-new {
  background: #5746ec !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  width: 500px;
  margin-left: 122px;
  color: #fff !important;
  margin-bottom: 50px;
}

.main-re-from-divs .form-select:focus {
  box-shadow: none !important;
}

.landing-sec-rg ul li span {
  width: 30px;
  display: inline-block;
}

.landing-sec-rg p {
  color: #202020;
  font-size: 16px;
  font-weight: 400;
}

.comon-re-texts {
  position: absolute;
  left: 10%;
  width: 30%;
  color: #fff;
  top: 50%;
  transform: translateY(-50%);
}

.com-li li span img {
  position: relative;
  top: -2px;
}

.delp-img {
  width: 90px;
  display: inline-block;
  margin-right: 10px;
}

.ex-project-details {
  display: inline-block;
  width: 95%;
}

.comon-cplens a {
  color: #5746ec;
  text-transform: uppercase;
  text-decoration: none;
}

.back-new-fun {
  position: absolute;
  top: 50px;
  background: #fff;
  width: 150px;
  height: 40px;
  text-decoration: none;
  left: 58%;
  z-index: 2;
  color: #666666 !important;
  font-size: 18px;
}

.comon-cplens h6 {
  color: #f33636;
  text-transform: uppercase;
}

.comon-card-n h5 {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.comon-card-n h5 span {
  color: #5c5f62;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
}

.payment-details-2 {
  display: inline-block;
  width: 95%;
}

.left-pm {
  display: flex;
  align-items: center;
  width: 100%;
}

.left-pm figure {
  margin-right: 20px;
  margin-bottom: 0;
}

.left-pm h5 {
  font-size: 22px;
  color: #000;
  font-weight: 500;
  line-height: 30px;
}

.left-pm h5 {
  font-size: 20px;
}

.comon-toggle {
  display: flex;
  align-items: center;
  color: #5c5f62;
}

.left-pm h5 span {
  font-size: 16px;
  font-weight: 400;
  color: #5c5f62;
  display: block;
}

/*  end 03-06-2022 */
.comon-ser-btn {
  background-color: #fff !important;
  border-radius: 8px !important;
  border: solid 1px #dfdfdf !important;
  box-shadow: #e7e7e7 1px 4px 8px !important;
  font-size: 15px !important;
  font-weight: 500;
  height: 47px;
  color: #5c5f62 !important;
  margin-right: 15px !important;
  transition: all 0.5s !important;
  transform: scale(1);
}

.comon-ser-btn:hover {
  transform: scale(1.1);
}

.search-section-optios {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}

.comon-ser-btn svg {
  width: 25px;
  height: 25px;
  margin-left: 5px;
}

/* .search-btn-back {
  font-size: 25px !important;
  font-weight: 500 !important;
  padding-left: 0 !important;
} */
.search-btn-back:focus {
  box-shadow: none !important;
}

.search-btn-back svg {
  color: #5c5f62;
}

.request-page hr {
  margin-left: 0;
  width: 95%;
  background: #d7d4d4 !important;
  opacity: 9 !important;
  margin-top: 32;
  margin-bottom: 15px;
}

.right-section-div .top-right-projects hr {
  width: 100%;
}

.client-teams-ul-li .dropdown li {
  margin-bottom: 8px;
}

.client-teams-ul-li .dropdown-item {
  /* font-size: 13px; */
  margin-bottom: 0;
}

.projects-pages .comon-table-td figure img {
  width: 18px;
}

.projects-pages .comon-table-divs .number-list li img {
  width: 16px;
}

.projects-pages .comon-table-divs .number-list li span {
  margin-right: 2px;
}

.projects-pages .comon-table-divs .number-list li {
  margin: 0 8px;
}

.left-section-divs {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  /* display: inline-block; */
  width: 100%;
  /* padding: 25px; */
}

.left-section-divs h2 {
  color: #000;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}

.left-section-divs ul li {
  margin-right: 15px;
  color: #5c5f62;
  font-size: 15px;
  font-weight: 500;
}

.left-section-divs ul li span {
  position: relative;
  top: -1px;
}

.left-section-divs h5 {
  color: #5c5f62;
}

.left-section-divs p {
  font-size: 15px;
  font-weight: 400;
  color: #5c5f62;
}

.right-section-div {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  display: inline-block;
  width: 100%;
  padding: 25px;
}

.right-section-div h2 {
  font-size: 22px;
  color: #000;
  font-weight: 500;
}

.right-section-div h2 span {
  color: #5c5f62;
  font-size: 16px;
  font-weight: 400;
}

.requset-1btn {
  background: #5746ec !important;
  color: #fff !important;
  border-radius: 7px;
  height: 44px;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  font-size: 16px !important;
  font-weight: 500 !important;
  transition: all 0.5s !important;
  line-height: 28px !important;
}

.sp-are-sec .jodit-react-container {
  height: 131px;
  overflow: hidden;
}

.requset-1btn:hover {
  background: #000 !important;
}

.right-section-div ul li {
  display: flex;
  color: #5c5f62;
  font-size: 15px;
  font-weight: 500;
}

.right-section-div ul li figure {
  margin: 0 !important;
  display: inline-block !important;
  width: 25px;
  text-align: center;
}

.text-ex {
  width: 301px;
  padding-left: 15px;
}

.list-btn3 {
  width: 100% !important;
}

.left-section-divs {
  width: 804px;
  margin-right: 15px;
  /* height: fit-content; */
}

.right-section-div {
  width: 501px;
}

.btn:focus {
  box-shadow: none !important;
}

#ceo th {
  padding: 10px 0 10px 20px;
  display: inline-block;
  margin: 12px 0;
}

#ceo td {
  padding: 10px 0 10px 20px;
}

#ceo th {
  height: 50px;
  line-height: 29px;
}

.w-66 {
  width: 66px !important;
}

.comon-table-divs tbody tr td {
  vertical-align: top;
}

.comon-table-divs tbody tr .w-300 a {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}

.number-list {
  justify-content: center;
}

.number-list li {
  font-size: 15px;
  font-weight: 500;
  padding: 0 5px;
  align-items: center;
}

.number-list li img {
  width: 15px;
}

.date {
  font-size: 14px;
}

.date h6 {
  color: #000;
  margin-bottom: 5px;
  margin: 0;
}

.date p {
  color: #bbb;
}

.nm1 p {
  font-size: 14px;
  color: #5c5f62;
}

.number-member h6 {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin: 0;
}

.number-member a {
  color: #5746ec !important;
  font-size: 13px;
  font-weight: 600;
}

.user-tabale-td span:nth-child(2),
.user-tabale-td span:nth-child(3),
.user-tabale-td span:nth-child(4) {
  margin-left: -10px;
}

.number-list li img {
  margin-right: 5px;
  position: relative;
  top: -2px;
}

.comon-table-divs tbody tr {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  height: 145px;
  background: #fff;
}

.noafter:after {
  display: none !important;
}

#ceo th {
  color: #5c5f62;
  font-weight: 500;
  border-left: 0 !important;
  border-right: 0 !important;
  border: 1px solid #d7d4d4;
  border-bottom: 1px solid #d7d4d4;
  position: relative;
  text-transform: uppercase;
  font-size: 15px;
}

.w-300 {
  width: 257px;
}

.w-160 {
  width: 180px;
}

.w-1001 {
  width: 148px;
}

.w-2001 {
  width: 170px;
}

.addmodal #ceo th:after {
  display: inline-block;
  content: "";
  margin-left: 10px;
  background-image: url(../public/images/arrow-tabale-list.svg);
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 34%;
}

#ceo th.asc:after {
  display: inline-block;
  content: "";
  background-image: url(../public/images/arrow-tabale-list.svg);
  width: 15px;
  height: 15px;
  position: relative;
  top: 3px;
}

#ceo th.desc:after {
  display: inline-block;
  content: "";
  background-image: url(../public/images/arrow-tabale-list.svg);
  width: 15px;
  position: relative;
  top: 3px;
  height: 15px;
}

.number-list {
  padding-bottom: 20px;
}

.number-list li {
  display: flex;
}

.comon-table-divs table {
  border-collapse: collapse separate;
  border-spacing: 30px;
  min-width: 100%;
  max-width: 1500px;
}

.comon-table-divs tbody tr {
  padding-top: 10px;
  width: 100%;
  border-radius: 12px;
  display: block;
}

.comon-table-divs tbody tr td {
  background: #ffffff;
  margin: 15px 0;
}

.comon-table-divs tbody tr td:first-child {
  border-bottom-left-radius: 10px;
  border-color: #fff;
  border-collapse: collapse;
  border-radius: 12px 0 0 12px;
}

.comon-table-divs tbody tr td:last-child {
  border-bottom-left-radius: 10px;
  border-color: #fff;
  border-collapse: collapse;
  border-radius: 0 12px 12px 0;
}

.main-re-from-divs h5 {
  color: #5746ec;
}

.main-re-from-divs h2 {
  margin-bottom: 30px;
}

#ceo td {
}

.mainl {
  border: 1px solid #ced4da !important;
  border-radius: 5px;
  position: relative;
  height: 235px;
}

.jodit-container:not(.jodit_inline) .jodit-editor__resize svg {
  display: none !important;
}

.mainl .form-control {
  border: none !important;
  height: 100px !important;
  resize: none;
}

.pro-jects1 .form-group {
  margin-bottom: 15px;
}

.pro-jects1 .modal-title {
  visibility: hidden;
}

.btnsm {
  background: #5746ec !important;
  color: #fff !important;
  height: 40px !important;
  display: table !important;
}

/* .pro-jects1 .modal-header {
  border-bottom: none !important;
} */

.pro-jects1 label {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 16px;
  color: #000;
}

.pro-jects1 p {
  font-size: 14px;
}

.mainl-c {
  margin: auto;
  display: table;
  text-align: center;
  margin-bottom: 10px;
}

.number-member a,
.user-tabale-td a {
  cursor: pointer;
}

.new-input1 {
  border: 1px solid #ced4da;
  height: 54px;
  border-radius: 5px;
  align-items: center;
  padding: 0 15px;
}

.new-input1 input {
  border: none !important;
  outline: none !important;
  background: none !important;
  width: 90%;
  height: 54px;
}

.projects-modal-divs label {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
}

.projects-modal .modal-header {
  border: none !important;
}

.projects-modal h5 {
  visibility: hidden;
}

.projects-modal .back-n-bt {
  position: absolute;
  left: 0;
  top: 0;
}

.projects-modal-divs .form-group {
  margin-bottom: 15px;
}

.cm-modal-all .modal-header {
  border: none;
}

.cm-modal-all .modal-header h5 {
  /* visibility: hidden; */
}

.cm-modal-all .form-group {
  margin-bottom: 15px;
}

.cm-modal-all .form-group label {
  color: #000;
  margin-bottom: 10px;
}

.inside-comon-modal form {
  margin-top: 35px;
}

.cm-modal-all .modal-dialog {
  max-width: 700px !important;
}

.dropdown-item {
  cursor: pointer;
}

.cm-modal-all .submit-bn {
  background: #5746ec !important;
  font-size: 16px !important;
  color: #fff !important;
  font-weight: 500 !important;
  width: 400px;
  display: table;
  margin: auto;
  transition: all 0.5s;
}

.file-upload {
  border: 1px dashed #5746ec;
  background: rgba(87, 70, 236, 0.06);
  height: 108px;
  position: relative;
  border-radius: 5px;
}

.file-upload__label {
  border-radius: 30px;
  color: #5746ec !important;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  left: 50%;
  outline: none;
  padding: 15px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-user-select: none;
  white-space: nowrap;
  width: 100%;
  display: flex;
  justify-content: center;
}

.file-upload__label span {
  margin-right: 10px;
}

.file-upload__input {
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.cm-modal-all .submit-bn:hover {
  background: #000 !important;
}

.message-modal {
  border: 1px solid #ced4da;
  height: 235px;
  border-radius: 5px;
  position: relative;
}

.message-modal .form-control {
  border: none !important;
  resize: none !important;
  height: 180px;
}

.menmber-icons .mb-icons {
  margin-left: -20px;
}

.menmber-icons .mb-icons:first-child {
  margin-left: -10px;
}

.menmber-icons span {
  font-weight: 500;
  color: #000;
  font-size: 16px;
}

.message-text-area {
  width: 100%;
  display: inline-block;
  background: #fff;
  height: 560px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  margin-top: 30px;
}

.inside-message1 {
  height: 400px;
  overflow: auto;
  width: 100%;
  padding: 0 30px;
  display: inline-block;
}

.admin-text {
  width: 100%;
  display: grid;
  justify-content: flex-start;
  margin-left: auto;
}

.menmber-text {
  width: 100%;
  display: grid;
  justify-content: flex-end;
  margin-left: 0;
}

.inside-message1 > div {
  margin-bottom: 30px;
}

.body-expart-div .expert-tables .nav-pills .nav-link.active,
.body-expart-div .expert-tables .nav-pills .nav-link:hover,
.body-expart-div .expert-tables .nav-pills .show > .nav-link {
  background: #fff !important;
  color: #f33636;
  font-weight: 500;
}

.body-expart-div .expert-tables .nav {
  justify-content: center;
}

.body-expart-div .expert-tables .comon-table-divs table {
  width: 100%;
}

.body-expart-div .expert-tables .comon-table-divs table tbody tr {
  height: 81px;
  padding-top: 7px;
}

.comon-tabs-expert th:first-child,
.body-expart-div .expert-tables .comon-table-divs table tbody td:first-child {
  width: 66px;
}

.comon-tabs-expert th:nth-child(2),
.body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(2) {
  width: 109px;
}

.comon-tabs-expert th:nth-child(3),
.body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(3) {
  width: 336px;
}

.comon-tabs-expert th:nth-child(4),
.body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(4) {
  width: 170px;
}

.comon-tabs-expert th:nth-child(5),
.body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(5) {
  width: 143px;
}

.comon-tabs-expert th:nth-child(6),
.body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(6) {
  width: 143px;
}

.submited-text {
  background: rgba(41, 156, 70, 0.1);
  border-radius: 8px;
  font-size: 13px;
  color: #299c46;
  font-weight: 400;
  width: 90px;
  height: 31px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  border: 1px solid #299c46;
}

.serch-divs input {
  border: none;
  width: 90%;
  margin-left: 10px;
}

.user-status h5 {
  font-size: 16px;
  font-weight: 500;
}

.one-ex-btn {
  background: #ffffff;
  border: 1.5px solid #5746ec !important;
  border-radius: 8px !important;
  color: #5746ec !important;
  font-size: 16px;
  font-weight: 500;
  width: 94%;
}

.more-ex-btn {
  background: #5746ec !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  font-size: 16px;
  font-weight: 500;
  width: 54%;
  margin-left: 15px;
  color: #fff !important;
}

.notes {
  display: flex;
  color: #5c5f62;
}

.ps-titel {
  text-decoration: none;
  color: #000000;
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}

.notes span:last-child {
  margin-left: 15px;
}

.type-chat-sec {
  position: absolute;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.14);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  padding: 0 25px;
}

.file-btn {
  width: 20px;
  height: 20px;
  cursor: pointer !important;
  position: relative;
}

.type-chat-sec .form-group {
  width: 85%;
}

.emojee-btn {
  cursor: pointer;
}

.emojee-btn img {
  width: 20px;
}

.file-btn input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  cursor: pointer !important;
  opacity: 0;
  appearance: none;
}

.collab-table #ceo th:after {
  background-image: url(../public/images/arrow-tabale-list.svg) !important;
  background-repeat: no-repeat;
  content: "";
  display: inline-block !important;
  height: 15px;
  margin-left: 10px;
  position: absolute;
  top: 34%;
  width: 15px;
}

.type-chat-sec .form-control {
  background: none !important;
  border: none !important;
}

.user-name {
  text-decoration: none;
  color: #000;
  font-size: 15px;
}

.faq-section {
  width: 100%;
  display: inline-block;
}

.search-mobile-s {
  display: none !important;
}

.faq-section .accordion-button:not(.collapsed) {
  background: none !important;
  color: #000 !important;
  box-shadow: none !important;
  border-top: solid 1px #fff !important;
}

.faq-section .accordion-item {
  border: none !important;
}

.faq-section .accordion > div:last-child .accordion-button {
  border-bottom: none !important;
}

.faq-section .accordion-button {
  border-bottom: solid 1px #d7d4d4;
}

.descrip-texarea {
  height: 150px;
  resize: none;
}

.user-name img {
  margin-right: 10px;
}

.admin-text h6,
.menmber-text h6 {
  font-size: 14px;
  margin: 8px 0 0;
  color: #5c5f62;
}

.more-btn-show {
  background: #d1cbff;
  border: 3px solid #ffffff;
  width: 44px;
  height: 44px;
  display: inline-block;
  line-height: 40px;
  border-radius: 50%;
  margin-left: -20px;
  text-align: center;
  text-decoration: none;
  position: relative;
  top: 3px;
  transition: all 0.5s !important;
  color: #5746ec !important;
}

.more-btn-show:hover {
  background: #5746ec !important;
  color: #fff !important;
}

.comon-history-expert {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  display: grid;
  width: 95%;
  height: 208px;
  align-content: center;
  padding-right: 40px;
}

.left-hisroty-expert {
  display: flex;
  flex-wrap: wrap;
}

.left-hisroty-expert h5 {
  margin-bottom: 20px;
}

.serhisiroy-right {
  height: 100%;
  justify-content: flex-end;
  position: relative;
}

.serhisiroy-right:after {
  position: absolute;
  left: -24px;
  top: 0;
  height: 151px;
  width: 1px;
  background: #d7d4d4;
  content: "";
}

.nes-tabs a {
  text-decoration: none;
  color: #000;
  line-height: 23.46px;
}

.nes-tabs ul {
  margin-top: 10px;
}

.nes-tabs ul li {
  margin: 0 10px;
  color: #5746ec;
  text-transform: capitalize;
}

.nes-tabs ul li img {
  position: relative;
  top: -2px;
}

.nes-tabs ul li:first-child {
  margin-left: 0;
}

.serhisiroy-right h5 {
  font-size: 21px;
  font-weight: 500;
  color: #000000;
}

.serhisiroy-right h5 span {
  font-size: 16px;
  font-weight: 400;
  color: #b8b8b8;
}

.serhisiroy-right .edite-bnt {
  background: #5746ec;
  border-radius: 7px;
  color: #fff !important;
  margin-left: 40px;
  font-size: 14px;
  width: 50%;
  height: 40px;
  line-height: 25px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.serhisiroy-right .edite-bnt:hover {
  background: #000;
}

.serhisiroy-right .edite-bnt img {
  margin-right: 5px;
}

.left-hisroty-expert figure {
  width: 18%;
  display: table;
  text-align: right;
  padding-right: 30px;
}

.detsils-secch-comon {
  width: 78%;
  display: inline-block;
}

.detsils-secch-comon ul li {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  color: #5c5f62;
  margin: 0 10px;
}

.detsils-secch-comon ul li span:first-child {
  margin-right: 5px;
  text-align: center;
  width: 23px;
  display: inline-block;
}

.detsils-secch-comon ul li img {
  position: relative;
  top: -2px;
}

.detsils-secch-comon ul li:first-child {
  margin-left: 0;
}

.doller {
  width: 15px !important;
  height: 19px;
}

.faq-sections1 {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 100px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 50px 30px;
}

.faq-sections1 .faq-part {
  border: none !important;
  border: none;
  box-shadow: none;
  padding: 0;
}

.faq-sections1 .faq-part .accordion-button {
  padding-left: 0;
  padding-right: 0;
}

.faq-sections1 .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 1950px) {
  .w-300 {
    width: 339px;
  }
}

@media (max-width: 1900px) {
  .conti-new {
    width: 500px;
    margin-left: 120px;
  }

  .landing-banner,
  .home-banner {
    min-height: 601px;
  }

  /* .top-bar-menu .serch-sec-div {
    width: 95%;
  } */
  .user-mg-page .comon-table-divs th:last-child,
  .user-mg-page .comon-table-divs tr td:last-child {
    width: 62px !important;
  }

  .user-mg-page .comon-table-divs th:nth-child(5),
  .user-mg-page .comon-table-divs tr td:nth-child(5) {
    width: 180px;
  }

  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(4) {
    width: 180px;
  }

  .user-mg-page .comon-table-divs th:nth-child(3),
  .user-mg-page .comon-table-divs tr td:nth-child(3) {
    width: 180px;
  }

  .expert-cell-page .comon-table-divs th:first-child,
  .expert-cell-page .comon-table-divs tr td:first-child {
    width: 460px;
  }

  .sp-hr {
    margin-left: 55px;
  }

  .sp-hr {
    margin-left: 80px;
  }

  .search-section-optios {
    padding-left: 80px;
  }

  .request-body-sec {
    margin-bottom: 50px;
  }

  .projects-details-page {
  }

  .right-small-icons li {
    align-items: baseline;
  }

  .right-small-icons .red-icon {
    width: 41px;
  }

  .projects-details-page .right-section-div {
    background: none;
    box-shadow: none;
    border: none;
    padding: 0;
  }

  .projects-details-page .top-right-projects {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    display: inline-block;
    width: 100%;
    padding: 25px;
  }

  .projects-details-page .middle-right {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    display: inline-block;
    width: 100%;
    padding: 25px;
  }

  .right-small-icons li span:last-child {
    font-size: 25px;
    font-weight: 600;
    color: #000;
  }

  .right-small-icons li span:last-child small {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #5c5f62;
  }

  /* 24-05-2022 */
  .table-sortable th {
    cursor: pointer;
  }

  .table-sortable .th-sort-asc::after {
    content: "\25b4";
  }

  .table-sortable .th-sort-desc::after {
    content: "\25be";
  }

  .table-sortable .th-sort-asc::after,
  .table-sortable .th-sort-desc::after {
    margin-left: 5px;
  }

  .table-sortable .th-sort-asc,
  .table-sortable .th-sort-desc {
    background: rgba(0, 0, 0, 0.1);
  }

  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 164px;
    margin: auto;
    text-align: center;
  }

  .client-temas-div {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    display: inline-block;
    width: 100%;
    padding: 25px;
  }

  .add-pls {
    background: #f8f8f8 !important;
    border: 1.5px solid #5746ec !important;
    border-radius: 10px !important;
    font-size: 15px !important;
    color: #5746ec !important;
  }

  .client-teams-ul-li ul {
    margin: 0;
  }

  .client-teams-ul-li ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .projects-details-page .client-teams-ul-li .teams-li figure {
    float: left;
    margin: 0 10px 0 0 !important;
  }

  .client-teams-ul-li .teams-li {
    margin-bottom: 15px;
  }

  .client-teams-ul-li .teams-li h5 {
    font-size: 16px;
    font-weight: 500;
    float: right;
  }

  .client-teams-ul-li .teams-li h5 span {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    padding-top: 5px;
  }

  .client-teams-ul-li .teams-li figure {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
  }

  .add-pls svg {
    width: 12px;
    height: 12px;
  }

  .sp-dots1 {
    width: 25px;
  }

  .recente-vew-divs .comon-table-divs table {
    width: 100%;
  }

  .recente-vew-divs .comon-table-divs #ceo th {
    border-top: none !important;
  }

  .recente-vew-divs .comon-table-divs #ceo th:first-child {
    border: none !important;
  }

  .recente-vew-divs .collab-table #ceo th:first-child {
    border-bottom: 1px solid #d7d4d4 !important;
  }

  .recente-vew-divs .comon-table-divs #ceo th:last-child {
    width: 260px !important;
  }

  .recente-vew-divs .comon-table-td {
    color: #5746ec;
    text-decoration: none;
  }

  .sp-td-sec {
    box-shadow: none !important;
    background: none !important;
  }

  .td-inside {
    height: 145px;
  }

  .td-inside .icon-img {
    display: grid;
    align-content: center;
    margin-right: 10px;
  }

  .td-inside .bg-white {
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    height: 145px;
    align-items: center;
    border-radius: 12px;
    padding: 0 20px;
    width: 100%;
    /* 28-04 */
  }

  .text-td {
    width: 379px;
  }

  .auto-td {
    width: 270px;
  }

  .commment-td {
    width: 120px;
  }

  .option-td {
    width: 223px;
  }

  .recente-vew-divs .w-300 {
    width: 339px;
  }

  .recente-vew-divs .w-160 {
    width: 270px;
  }

  .recente-vew-divs .w-1001 {
    width: 120px;
  }

  /* 24-05-2022 */
  .list-btn3 a,
  .monr-btn {
    background: #ffffff !important;
    border: 1.2px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
    height: 35px;
    font-size: 12px !important;
    margin-right: 9px;
    margin-bottom: 10px;
    font-weight: 500 !important;
    color: #5c5f62 !important;
    height: 33px;
    font-size: 13px !important;
  }

  /* 27-05-06 */
  .comon-tabs-expert th:nth-child(3),
  .body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(3) {
    width: 400px;
  }

  .comon-tabs-expert th:nth-child(2),
  .body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(2) {
    width: 160px;
  }

  /* ends */
  .monr-btn {
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    display: inline-block;
    text-decoration: none !important;
    color: #000;
    margin-bottom: 0 !important;
    position: relative;
    top: -5px;
  }

  .top-ps-seection .nav-pills .nav-link {
    width: 162px;
  }

  .w-300 {
    width: 293px;
  }

  .w-160 {
    width: 210px;
  }

  .w-1001 {
    width: 165px;
  }

  .w-2001 {
    width: 200px;
  }

  .w-66 {
    width: 60px !important;
  }

  .collab-table tr th:nth-child(2),
  .collab-table .bg-white > .div:first-child {
    width: 374px;
  }

  .expert-cell-page .comon-table-divs th:last-child,
  .expert-cell-page .comon-table-divs tr td:last-child {
    width: 155px;
  }

  .expert-cell-page .comon-table-divs th:nth-child(3),
  .expert-cell-page .comon-table-divs tr td:nth-child(3),
  .expert-cell-page .comon-table-divs th:nth-child(4) {
    width: 145px;
  }

  .comon-table-divs .dropdown .dropdown-item {
    font-size: 15px;
  }

  .user-mg-page .comon-table-divs th:last-child,
  .user-mg-page .comon-table-divs tr th:last-child:after {
    background: none !important;
  }

  .speacial-expert .modal-dialog {
    max-width: 550px !important;
  }

  .speacial-expert h2 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .speacial-expert hr {
    width: 94%;
  }

  .speacial-expert .modal-header {
    border: none !important;
    margin-bottom: 0;
    padding: 15px 20px 0;
  }

  .collab-table tr th:first-child,
  .collab-table .bg-white > .div:first-child {
    width: 430px;
  }

  .collab-table tr th:nth-child(2),
  .collab-table .bg-white > .div:first-child {
    width: 270px;
  }

  .recente-vew-divs .collab-table tr th:last-child,
  .recente-vew-divs .collab-table .bg-white > .div:last-child {
    width: 40px;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    align-items: flex-start;
    padding-top: 25px;
  }

  .call-history-table tr th:first-child {
    width: 557px;
  }

  .call-history-table .td-inside {
    margin-bottom: 25px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 20px;
    width: 580px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .call-history-table tr th:nth-child(4) {
    width: 190px;
  }

  .call-history-table .td-inside .bg-white > div:nth-child(2) {
    width: 155px;
    padding-left: 0;
  }

  .call-history-table .td-inside .bg-white > div:last-child {
    width: 201px;
    padding-left: 27px;
  }

  .call-history-table .commment-td {
    width: 155px;
  }

  .main-re-from-divs label {
    font-size: 16px;
  }
}

@media (max-width: 1440px) {
  .main-re-from-divs {
    margin-top: 110px !important;
  }

  .landing-sec-rg {
    margin-top: 30px !important;
  }

  .right-small-icons li span:last-child small {
    font-size: 12px;
    font-weight: 600;
  }
}

@media (max-width: 1400px) {
  .user-mg-page .comon-table-divs th:nth-child(3),
  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs th:nth-child(5),
  .user-mg-page .comon-table-divs tr td:nth-child(3),
  .user-mg-page .comon-table-divs tr td:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(5) {
    width: 148px;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    padding-top: 15px;
  }

  .call-history-table tr th:first-child {
    width: 442px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 0px;
    width: 437px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .call-history-table tr th:nth-child(4) {
    width: 190px;
  }

  .call-history-table .td-inside .bg-white > div:nth-child(2) {
    width: 150px;
    padding-left: 0;
  }

  .call-history-table .td-inside .bg-white > div:last-child {
    width: 201px;
    padding-left: 27px;
  }
}

@media (max-width: 1366px) {
  .main-re-from-divs label {
    font-size: 14px;
  }

  .conti-new {
    width: 450px;
    margin-left: 110px;
  }

  .landing-banner h1 {
    font-size: 53px;
    font-weight: 600;
  }

  .landing-banner,
  .home-banner {
    min-height: 645px;
  }

  /* 27-05-06 */
  .recente-vew-divs .comon-table-divs #ceo th:last-child {
    width: 180px !important;
  }

  .collab-table tr th:first-child,
  .collab-table .bg-white > .div:first-child {
    width: 390px;
  }

  .collab-table tr th:nth-child(2),
  .collab-table .bg-white > .div:first-child {
    width: 229px;
  }

  .td-inside .bg-white {
    width: 89%;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    padding-top: 24px;
  }

  .call-history-table tr th:first-child {
    width: 442px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 0px;
    width: 437px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .call-history-table tr th:nth-child(4) {
    width: 190px;
  }

  .call-history-table .td-inside .bg-white > div:nth-child(2) {
    width: 150px;
    padding-left: 0;
  }

  .call-history-table .td-inside .bg-white > div:last-child {
    width: 201px;
    padding-left: 27px;
  }

  .call-history-table .commment-td {
    width: 160px;
  }

  .collab-table .td-inside .bg-white {
    width: 82%;
    height: 116px;
  }

  .comon-tabs-expert th:nth-child(3),
  .body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(3) {
    width: 320px;
  }

  .comon-tabs-expert th:nth-child(2),
  .body-expart-div .expert-tables .comon-table-divs table tbody td:nth-child(2) {
    width: 120px;
  }

  /* ends */

  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 145px;
    margin: auto;
    text-align: center;
  }

  .right-small-icons li span:last-child {
    font-size: 17px;
    font-weight: 600;
    color: #000;
  }

  .right-small-icons li span:last-child small {
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    color: #5c5f62;
  }

  /* .select-option {
    margin-left: 51px;
  } */
  /* .top-bar-menu .serch-sec-div {
    width: 96%;
  } */
  .sp-hr {
    margin-left: 53px;
  }

  .sp-hr {
    margin-left: 53px;
  }

  .top-bar-menu {
    padding-right: 25px;
  }

  .body-expart-div {
    padding-left: 53px;
  }

  .search-section-optios {
    padding-left: 53px;
  }

  .list-btn3 a,
  .monr-btn {
    background: #ffffff !important;
    border: 1.2px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
    height: 35px;
    font-size: 12px !important;
    margin-right: 3px;
  }

  .top-ps-seection .nav-pills .nav-link {
    width: 143px;
  }

  .w-300 {
    width: 257px;
  }

  .w-160 {
    width: 180px;
  }

  .w-1001 {
    width: 148px;
  }

  .w-2001 {
    width: 170px;
  }

  .w-66 {
    width: 66px !important;
  }
}

@media (max-width: 1200px) {
  .main-menu ul li a {
    font-size: 13px;
  }
}

.collab-table .td-inside .bg-white {
  height: 116px;
}

/* 29-05 */
.mainl .jodit-toolbar__box {
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translateX(-50%);
  margin: auto;
}

.mainl .jodit-workplace {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
}

.cm-modal-all .jodit-toolbar__box {
  position: absolute;
  left: 0;
  top: 80%;
  right: 0;
  margin: auto;
}

.cm-modal-all .jodit-workplace {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
}

/* 29-05 */

.jodit-ui-group_line_true {
  justify-content: center !important;
}

.mainl .jodit-status-bar {
  display: none;
}

.cm-modal-all .jodit-status-bar {
  display: none;
}

.charts-sections1 > div {
  margin-bottom: 12px;
  padding-top: 12px;
}

/* landing */

.top-part {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  min-height: 70px;
  display: inline-block;
  padding: 0;
  width: 100%;
}

.top-part .navbar {
  padding: 14px 0;
}

.top-part .login-web-btn {
  background: #ffffff;
  border: 1.5px solid #5746ec;
  border-radius: 7px;
  font-size: 18px;
  color: #5746ec !important;
  width: 130px;
  height: 44px;
  text-align: center;
  padding: 0;
  line-height: 38px;
  transition: all 0.5s;
  margin-left: 30px;
}

.top-part .login-web-btn:hover {
  background: #5746ec;
  color: #fff !important;
}

.top-part .register-login-btn {
  background: #5746ec;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 18px;
  color: #fff !important;
  width: 130px;
  height: 44px;
  text-align: center;
  padding: 0;
  line-height: 41px;
  transition: all 0.5s;
  margin-left: 15px;
}

.top-part .contact-btn-web {
  color: #727479 !important;
  font-weight: 500;
  font-size: 18px;
}

.footer-part .right-footer > div:last-child {
  display: grid;
  justify-content: flex-end;
}

.footer-part .right-footer > div:nth-child(2) {
  display: grid;
  justify-content: center;
}

.footer-part .right-footer h5 {
  font-size: 24px;
  font-weight: #012333;
  font-weight: 500;
}

.footer-part .right-footer li {
  margin-bottom: 10px;
}

.footer-part .right-footer li a {
  text-decoration: none;
  font-size: 20px;
  color: #5c5f62;
  font-weight: 500;
  transition: all 0.5s;
}

.footer-part .right-footer li a:hover {
  color: #5746ec;
}

.footer-part hr {
  background: rgba(0, 0, 0, 0.2);
}

.home-banner .find-btn {
  background: #5746ec;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: #fff;
  width: 277px;
  height: 54px;
  line-height: 38px;
  transition: all 0.5s;
  transform: scale(1);
}

.home-banner .contact-btn {
  background: #ffffff;
  border: 1.5px solid #5746ec;
  color: #5746ec;
  border-radius: 8px;
  width: 277px;
  height: 54px;
  line-height: 38px;
  transition: all 0.5s;
  transform: scale(1);
  margin-left: 30px;
}

.home-banner h3 {
  font-weight: 600;
  color: #000;
}

.home-banner .find-btn:hover,
.home-banner .contact-btn:hover {
  background: #000 !important;
  color: #fff !important;
}

.footer-part p {
  color: #5c5f62;
  font-size: 20px;
  font-weight: 500;
}

.industry-clients {
  background: #f8f8f8;
  display: inline-block;
  width: 100%;
  padding: 100px 0;
}

.landing-banner {
  background: #f8f8f8;
  display: grid;
  align-content: center;
}

.career-sec {
  position: relative;
  display: inline-block;
  width: 100%;
}

.career-infomations {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 70%;
  display: inline-block;
}

.career-infomations h2 {
  text-shadow: 8px -3px 17px rgba(0, 0, 0, 0.88);
  font-weight: 600;
  color: #fff;
  font-size: 56px;
}

.landing-banner h1,
.home-banner h1 {
  font-size: 45px;
  font-weight: 600;
  color: #000;
}

.get-st-btn {
  color: #fff !important;
  font-size: 20px;
  font-weight: 600;
  text-shadow: 8px -3px 17px rgb(0 0 0 / 88%);
  margin-top: 15px;
}

.landing-banner h1 span {
  display: block;
  background: linear-gradient(92.84deg, #5746ec -34.59%, #f55656 93.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-banner h1 span,
.industry-clients h2 {
  display: block;
  background: linear-gradient(92.84deg, #5746ec -34.59%, #f55656 93.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.comon-ads1 {
  text-decoration: none;
  width: 100%;
  display: inline-block;
}

.comon-ads1 figure {
  height: 344px;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.feature-in {
  position: absolute;
  z-index: 2;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  line-height: 39px;
  top: 18px;
  right: 30px;
  color: #000;
  padding: 0 18px;
}

.webnews-div .loading-btn {
  background: #5746ec !important;
  color: #fff !important;
  margin-top: 100px !important;
}

.blog-pic1 {
  display: inline-block;
  width: 100%;
  height: 501px;
  overflow: hidden;
  margin: 20px 0;
}

.blog-pic1 img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.blogs-web-details ul li {
  margin-right: 25px;
  margin-bottom: 25px;
  color: #5c5f62;
}

.tags-btn {
  background: #e7e7e7 !important;
  border-radius: 10px !important;
  font-size: 18px !important;
  color: #5c5f62 !important;
  width: 130px;
  height: 41px;
}

.webnews-div .loading-btn:hover {
  background-color: #000;
}

.comon-ads1 figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transform: scale(2);
  transition: all 0.5s;
}

.comon-ads1:hover figure img {
  transform: scale(2.1);
}

.poular-bls-divs .slick-prev,
.poular-bls-divs .slick-next {
  top: -50px !important;
}

.blos-details-part p {
  margin: 25px 0;
}

.poular-bls-divs {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: 100px;
  margin-bottom: 70px;
}

.bottom-contact-us {
  margin-top: 120px;
}

.contact-from-ds {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 100px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: table;
  margin: 0 0 0 auto;
  width: 80%;
  height: 503px;
  padding: 35px;
}

.contact-from-ds textarea {
  height: 150px;
  resize: none;
}

.conatct-sm {
  display: inline-block;
  width: 100%;
  background: #5746ec !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  height: 45px;
  line-height: 50px;
  border: none;
  color: #fff !important;
}

.contact-from-ds .form-group {
  margin-bottom: 15px;
}

.contact-from-ds .form-group label {
  margin-bottom: 8px;
}

.bottom-contact-us span {
  margin-bottom: 14px;
}

.bottom-contact-us span svg {
  margin-right: 5px;
}

.cont-socal li {
  margin: 0 14px 0 0;
  display: inline-block;
}

.cont-socal li a {
  color: #000;
  display: inline-block;
}

.slick-dots {
  display: none !important;
}

.slick-track > div:first-child {
  margin-left: 0;
  padding-left: 0;
}

.slick-prev:before {
  content: "" !important;
  /* background-image: url(../public/images/arrow-right.svg); */
  width: 39px;
  height: 17px;
  display: inline-block;
}

.News-blogs {
  display: inline-block;
  width: 100%;
  position: relative;
}

.slick-next,
.slick-prev {
  position: absolute;
  transform: none !important;
}

.slick-next {
  top: -100px !important;
  left: inherit !important;
  right: 24px !important;
}

.slick-prev {
  top: -100px !important;
  left: inherit !important;
  right: 80px !important;
}

.slick-next:before {
  content: "" !important;
  /* background-image: url(../public/images/next-arrow.svg); */
  width: 39px;
  height: 17px;
  display: inline-block;
}

.slick-slide {
  padding: 30px;
}

.news-blogs h2 {
  font-size: 45px;
  font-weight: 600;
  color: #000000;
}

p.sub-text-news {
  color: #5c5f62;
  font-size: 20px;
  font-weight: 500;
}

.blogs-web-details {
  margin-top: 30px;
  margin-bottom: 88px;
}

.comon-ads1 h5 {
  font-size: 20px;
  font-weight: 500;
  color: #000 !important;
  transition: all 0.5s;
}

.back-btn1 {
  font-size: 25px !important;
  margin-left: -10px;
}

.back-btn1 svg {
  color: #5c5f62;
}

.comon-ads1:hover h5 {
  color: #5746ec !important;
}

.comon-ads1 p {
  color: #202020;
  letter-spacing: -0.02em;
  font-weight: 400;
  font-size: 18px;
}

.industry-clients h2 {
  font-weight: 600;
  font-size: 50px;
}

.tgs {
  background: #e7e7e7;
  border-radius: 10px;
  width: 113px;
  height: 41px;
  text-align: center;
  padding: 10px;
  line-height: 22px;
  color: #5c5f62 !important;
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
}

.comon-ads1 h6 {
  color: #5c5f62;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}

.clients-in {
  background: #f8f8f8;
  width: 100%;
  height: 90px;
  text-align: center;
  display: table;
}

.form-sec-ban {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 6px rgba(180, 212, 221, 0.2);
  border-radius: 18px;
  height: 410px;
  width: 90%;
  padding: 30px;
  margin-left: auto;
}

.form-sec-ban h2 {
  font-size: 28px;
  font-weight: 500;
  color: #000;
}

.form-sec-ban .form-group {
  margin-bottom: 20px;
}

.form-sec-ban label {
  font-size: 16px;
  color: #666666;
  font-weight: 400;
  margin-bottom: 5px;
}

.form-sec-ban .form-control {
  height: 44px;
  border-radius: 10px;
}

.expert-cloud-div h1 {
  font-weight: 600;
  font-size: 45px;
  color: #000;
}

p.subtext1 {
  color: #5c5f62;
  font-size: 16px;
}

.comon-section-landing {
  padding-left: 40px;
}

.comon-section-landing h2 {
  background: linear-gradient(92.84deg, #5746ec -34.59%, #f55656 93.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 45px;
}

.home-banner {
  background: #fff;
  display: grid;
  align-content: center;
}

.comon-section-landing h5,
.comon-section-landing .click-btn {
  color: #5746ec;
  font-weight: 500;
  font-size: 18px;
  padding: 0;
}

.comon-section-landing ul {
  display: flex;
  flex-wrap: wrap;
}

.comon-section-landing ul li {
  margin: 14px 14px 0 0;
}

.comon-section-landing ul li .btn-comon-web {
  background: #ffffff;
  border: 1.5px solid #5746ec;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  color: #5c5f62;
  font-size: 15px;
  transition: all 0.5s;
  transform: scale(1);
}

.comon-section-landing ul li .btn-comon-web:hover {
  transform: scale(1.1);
}

.get-start-btn {
  background: #5746ec !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  color: #fff !important;
  height: 45px;
  width: 222px;
  line-height: 31px !important;
  margin-top: 30px;
  transition: all 0.5s !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.cosulting-page-d1 ul li {
  display: flex;
  align-items: baseline;
}

.cosulting-page-d1 ul li span {
  width: 90%;
  display: inline-block;
  margin-left: 17px;
}

.cosulting-page-d1 ul li svg {
  color: #5746ec;
  position: relative;
  top: 4px;
}

.get-start-btn svg {
  margin-left: 5px;
}

.get-start-btn:hover {
  background: #000 !important;
}

.comon-web-search-sec .container > div {
  margin-bottom: 78px;
}

.faq-part {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 100px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
}

.faq-part .accordion-button:not(.collapsed) {
  background: #fff !important;
}

.faq-part .accordion-header {
  margin: 0 !important;
}

.faq-part .accordion-item {
  border: none !important;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2) !important;
  border-radius: 0 !important;
}

.faq-part .accordion-button {
  font-size: 22px;
  font-weight: 500;
  color: #000;
  box-shadow: none !important;
}

.faq-part .accordion-button:not(.collapsed) {
  color: #000;
}

.faq-part .show .accordion-body {
  border: none !important;
}

.faq-part .accordion-body p {
  font-style: 16px;
  font-weight: 400;
  color: #5c5f62;
}

.faq-part .accordion > div:last-child {
  border-bottom: none !important;
}

.faq-part .accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.faq-sections1 .accordion-button:not(.collapsed)::after {
  /* background-image: url(../public/images/faq-icon.svg) !important; */
  border-radius: 0 !important;
  width: 30px;
  height: 4px;
  transform: none !important;
}

.faq-sections1 .accordion-button::after {
  /* background-image: url(../public/images/faq-icon2.svg) !important; */
  border-radius: 0 !important;
  width: 30px;
  height: 30px;
}

.downloadPfg {
  height: 0;
  overflow: auto;
}

.subcribe-divs {
  /* background-image: url(../public/images/subscrib-bg.svg) !important; */
  padding: 60px 0;
  display: inline-block;
  margin: auto;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  min-height: 385px;
  display: grid;
  align-content: center;
}

.subcribe-divs:hover .conatct-btn-sub {
  background: #000 !important;
  color: #fff !important;
}

.form-sec-ban .form-control::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.form-sec-ban .submit {
  background: #5746ec;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 40px;
  width: 100%;
  color: #fff;
}

.contact-comn-web {
  margin-bottom: 140px;
  display: inline-block;
  width: 100%;
}

.blogs-web-details .contact-comn-web {
  margin-top: 0;
  margin-bottom: 50px;
}

.contact-comn-web h2 {
  color: #fff;
  font-size: 48px;
}

.right-footer .comon-footer ul {
  margin-top: 27px;
}

.conatct-btn-sub {
  background: #ffffff !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  width: 34%;
  height: 44px;
  line-height: 34px !important;
  transition: all 0.5s;
}

@media (min-width: 1180px) {
  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 140px;
    margin: 0 0;
  }
}

@media (min-width: 992px) {
  .sidebar-menu {
    background: #fff;
    box-sizing: border-box;
    height: 100vh;
    left: 0;
    margin-top: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 250px;
    z-index: 10;
    /* padding: 25px 25px 0; */
  }
}

@keyframes up-down {
  0% {
    transform: translate(1px, 20px);
  }

  24% {
    transform: translate(1px, 30px);
  }

  50% {
    transform: translate(1px, 12px);
  }

  74% {
    transform: translate(1px, 22px);
  }

  100% {
    transform: translate(1px, 22px);
  }
}

@-moz-keyframes up-down {
  0% {
    -moz-transform: translate(1px, 20px);
  }

  24% {
    -moz-transform: translate(1px, 30px);
  }

  50% {
    -moz-transform: translate(1px, 12px);
  }

  74% {
    -moz-transform: translate(1px, 22px);
  }

  100% {
    -moz-transform: translate(1px, 22px);
  }
}

@-webkit-keyframes up-down {
  0% {
    -webkit-transform: translate(1px, 20px);
  }

  24% {
    -webkit-transform: translate(1px, 30px);
  }

  50% {
    -webkit-transform: translate(1px, 12px);
  }

  74% {
    -webkit-transform: translate(1px, 22px);
  }

  100% {
    -webkit-transform: translate(1px, 22px);
  }
}

@-o-keyframes up-down {
  0% {
    -o-transform: translate(1px, 20px);
  }

  24% {
    -o-transform: translate(1px, 30px);
  }

  50% {
    -o-transform: translate(1px, 12px);
  }

  74% {
    -o-transform: translate(1px, 22px);
  }

  100% {
    -o-transform: translate(1px, 22px);
  }
}

@-ms-keyframes up-down {
  0% {
    -ms-transform: translate(1px, 20px);
  }

  24% {
    -ms-transform: translate(1px, 30px);
  }

  50% {
    -ms-transform: translate(1px, 12px);
  }

  74% {
    -ms-transform: translate(1px, 22px);
  }

  100% {
    -ms-transform: translate(1px, 22px);
  }
}

@keyframes turn {
  0% {
    left: 0px;
  }

  50% {
    left: 90px;
  }

  100% {
    left: 0px;
  }
}

@media (max-width: 1180px) {
  .comon-divs-re {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .call-history-table tr th:first-child {
    width: 212px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 21px;
    width: 234px;
  }

  .nes-tabs ul li {
    margin: 10px 0;
  }

  .td-inside .bg-white,
  .td-inside {
    height: auto;
  }

  .comon-history-expert {
    padding: 30px;
    height: auto;
  }

  .detsils-secch-comon ul li {
    margin: 10px 0;
  }

  .serhisiroy-right .edite-bnt {
    width: 100%;
    margin-left: 0;
  }

  .comon-divs-re h5 {
    font-size: 16px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 20px;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .top-bar-menu .serch-sec-div {
    width: 100%;
  }

  .select-option,
  .sp-hr {
    margin-left: 23px;
  }

  .body-expart-div {
    padding-left: 23px;
  }

  .main-menu ul li a {
    font-size: 13px;
  }

  .notes-d-section .comon-notes {
    margin-bottom: 25px;
  }
}

@media (max-width: 1024px) {
  .select-option,
  .sp-hr {
    margin-left: 0;
  }

  .body-expart-div {
    padding-left: 0;
  }

  .sidebar-menu {
    width: 21%;
  }

  .top-bar-menu {
    width: 78%;
  }
}

@media (max-width: 900px) {
  .sidebar-menu {
    display: none;
  }

  .landing-page-d1 .main-re-from-divs {
    margin: auto;
    width: 80%;
  }

  .comon-card-n {
    margin-bottom: 30px;
  }

  .new-register-page .main-re-from-divs,
  .new-add-rg2 .main-re-from-divs {
    margin: auto;
    width: 80%;
  }

  .new-register-page .conti-new {
    width: 80%;
    margin: auto;
    display: table;
  }

  .mob-call-sec .div-pogress h5 {
    font-size: 14px;
    color: #bbb;
  }

  .mob-call-sec .div-pogress h5 span {
    font-size: 18px;
    color: #000;
    font-weight: 600;
  }

  .mob-call-sec .date h5 {
    color: #5746ec;
    text-transform: capitalize;
  }

  .titels-new {
    font-size: 20px;
  }

  .mob-call-sec .nubers-div1 h5 {
    text-transform: capitalize;
    color: #000;
  }

  .detsils-secch-comon {
    width: 100%;
  }

  .landing-page-d1 .main-re-from-divs .logo,
  .new-register-page .main-re-from-divs .logo,
  .new-add-rg2 .main-re-from-divs .logo {
    margin-left: 0;
  }

  .comon-history-expert {
    width: 100%;
    margin: auto;
    height: auto;
    padding: 30px;
  }

  .serhisiroy-right:after {
    display: none;
  }

  .serhisiroy-right h5 {
    text-align: center;
  }

  .new-register-page .back-rgs {
    left: 8%;
  }

  .back-new-fun {
    left: 8%;
  }

  .serhisiroy-right .edite-bnt {
    margin: auto;
    width: 100%;
  }

  .left-hisroty-expert figure {
    width: 100%;
    text-align: left;
  }

  .detsils-secch-comon ul li {
    margin: 15px 0;
  }

  .serhisiroy-right {
    margin-top: 30px;
  }

  .mbile-divs-table {
    background: #ffffff;
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    padding: 30px;
    margin-top: 30px;
    display: inline-block;
    position: relative;
  }

  .inside-support,
  .news-section,
  .faq-section {
    width: 100%;
  }

  .body-expart-div h2 {
    font-size: 25px;
  }

  .top-bar-menu {
    width: 100%;
  }

  .main-menu ul li a {
    font-size: 15px;
  }

  .comon-divs-re h5 {
    font-size: 18px;
  }

  .content-wrapper {
    padding-left: 0;
  }

  .top-bar-menu {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    background: #fff;
  }

  .top-bar-menu .serch-sec-div {
    width: 100%;
  }

  .mobile-serch-sec-div {
    /* border: solid 1px #f33636 !important; */
    height: 43px !important;
    border-radius: 10px !important;
    padding-top: 0;
    padding-right: 10px;
    padding-bottom: 0;
    padding-left: 0;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff !important;
  }

  .mobile-serch-sec-div .form-control {
    border: none !important;
  }

  .mobile-serch-sec-div svg {
    color: #f33636;
  }

  .right-menu-sec > div:nth-child(2) {
    display: none !important;
  }

  .login-left-part,
  .regitser-page-div {
    padding-left: 30px;
  }

  .regitser-page-div {
    padding-right: 30px;
  }

  .select-option,
  .sp-hr,
  .body-expart-div {
    padding-left: 0;
  }

  .select-option,
  .sp-hr {
    margin-left: 0;
  }

  .comon-divs-re {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
  }

  .mobile-fill-width {
    width: 100%;
  }

  .mobile-canvas .offcanvas-title {
    visibility: hidden;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .tp-list1 {
    margin: 25px 0;
  }

  .new-reguest {
    line-height: 30px !important;
  }

  .right-menu-sec {
    justify-content: space-between;
  }

  .top-bar-menu > div {
    width: 100%;
  }

  .left-section-divs,
  .right-section-div {
    width: 100%;
  }

  .search-section-optios {
    padding-left: 0;
  }

  .search-section-optios .comon-ser-btn {
    margin-bottom: 10px;
  }

  .tab-content {
    overflow: auto;
    width: 100%;
  }

  .comon-table-divs table {
    table-layout: fixed;
    width: 1090px !important;
  }

  .top-ps-seection .nav {
    background: #e7e7e7;
    border-radius: 10px;
    padding: 5px 5px 1px;

    justify-content: space-between;
  }

  .collab-table {
    overflow: auto;
    width: 100%;
  }

  .collab-table .table {
    table-layout: fixed;
    width: 800px !important;
  }

  .request-page {
    width: 100%;
  }

  /* 02-06-2022 */
  .login-page-div {
    position: relative;
    width: 50%;
    padding-bottom: 50px;
  }

  .mobile-canvas .offcanvas-header,
  .mobile-canvas .logo-admin {
    display: none;
  }

  .mobile-canvas {
    top: 62px !important;
  }

  .dasb-div .select-option {
    width: 100%;
    overflow: auto;
  }

  .dasb-div .select-option .flex-column {
    flex-direction: row !important;
    width: 1366px;
  }

  .body-expart-div .nav-pills .nav-link {
    font-size: 13px;
    padding: 8px;
  }

  .body-expart-div .nav-item {
    margin: 0 1px;
  }

  .body-expart-div .ps-titel {
    font-size: 20px;
  }

  .td-inside .d-flex {
    flex-wrap: wrap !important;
    width: 100% !important;
    justify-content: flex-start !important;
    height: auto !important;
    padding: 20px !important;
  }

  .td-inside .text-td {
    width: 100%;
  }

  .collaborate-page .td-inside {
    height: auto !important;
    margin-bottom: 30px;
    display: inline-block !important;
    width: 100%;
  }

  .file-btn {
    margin-right: 10px;
  }

  .body-expart-div h2 {
    font-size: 22px;
  }

  .user-mg-page h2 {
    margin-bottom: 0;
  }

  .top-ps-seection {
    width: 100%;
  }

  .request-body-sec ul.d-flex {
    flex-wrap: wrap !important;
  }

  .request-body-sec ul.d-flex li {
    width: 100%;
    margin-bottom: 15px;
  }

  .details-message-part .expert-tables .nav {
    flex-wrap: nowrap !important;
    justify-content: flex-start !important;
  }

  .expert-tables {
    width: 100%;
    overflow: auto;
  }

  .message-text-area .text-area-type {
    width: 90%;
  }

  .search-mobile-s {
    display: block !important;
  }

  .mboile-logo {
    text-decoration: none;
    height: 32px;
    overflow: hidden;
    top: 2px;
    position: relative;
    display: inline-block;
  }

  .comon-re-texts {
    display: none;
  }

  .expert-login-page .landing-sec-rg {
    display: table;
    width: 80%;
    margin: auto;
  }

  .expert-login-page .logo {
    margin-left: 0;
  }

  .all-complains {
    width: 100%;
  }

  .payment-details-2 {
    width: 100%;
  }

  .comon-payments-ex {
    width: 100%;
  }
}

@media (max-width: 667px) {
  .comon-select {
    margin-bottom: 25px;
  }

  .mob-call-sec .date h4 {
    color: #5c5f62;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }

  .comon-payments-ex .dropdown {
    position: absolute;
    top: 28px;
    right: 10px;
  }

  .request-body-sec ul li {
    margin-bottom: 10px;
  }

  .jodit-toolbar__box:not(:empty) {
    width: 92% !important;
  }

  .md-titel-link {
    display: block;
  }

  .request-body-sec ul li span,
  .request-body-sec .com-li li span:first-child {
    margin-right: 10px;
  }

  .online-st svg {
    color: #299c46;
  }

  .expert-login-page .main-re-from-divs {
    padding-left: 0;
    padding-right: 0;
  }

  .landing-page-d1 .main-re-from-divs {
    padding-right: 15px;
  }

  .login-page .row > div:nth-child(2) {
    padding: 0;
    height: 100vh;
  }

  .all-chat-divs .menmber-text,
  .all-chat-divs .admin-text {
    width: 105%;
  }

  .expert-cell-page .comon-table-td {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .all-chat-divs {
    padding: 0;
  }

  .comon-toggle {
    flex-direction: row-reverse;
  }

  .cm-modal-all .submit-bn {
    width: 100%;
  }

  .comon-toggle p {
    position: relative;
    left: 10px;
  }

  .mob-call-sec .div-pogress h4 {
    color: #5c5f62;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }

  .mob-call-sec .date h5 {
    font-size: 18px;
  }

  .left-pm {
    align-items: baseline;
  }

  .mob-call-sec .nubers-div1 h5 {
    font-size: 18px;
  }

  .mob-call-sec .comon-table-td {
    font-size: 20px;
  }

  .mob-call-sec .nubers-div1 h4 {
    color: #5c5f62;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }

  .all-complains {
    width: 100%;
  }

  .serhisiroy-right {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 15px;
  }

  .payment-details-2 {
    width: 100%;
  }

  .left-pm figure {
    margin-bottom: 15px;
  }

  .comon-payments-ex {
    height: auto;
  }

  .comon-re-texts {
    display: none;
  }

  .left-hisroty-expert figure {
    width: 100%;
    text-align: left;
  }

  .expert-login-page .landing-sec-rg {
    display: table;
    width: 80%;
    margin: auto;
  }

  .expert-login-page .row > div:nth-child(2) {
    padding-right: 0;
  }

  .expert-login-page .logo {
    margin-left: 0;
  }

  .landing-page-d1 .logo {
    margin-left: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 15%);
    padding-bottom: 20px;
    text-align: center;
  }

  .converstion-divs .nav .nav-link h5 span {
    color: #5c5f62;
    font-weight: 400;
    font-size: 13px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 0 !important;
  }

  .top-ps-seection .nav {
    flex-direction: column;
  }

  .body-expart-div .nav-pills .nav-link {
    width: 100%;
  }

  .body-expart-div .nav-pills .nav-link.active,
  .body-expart-div .nav-pills .nav-link:hover,
  .body-expart-div .nav-pills .show > .nav-link {
    width: 100%;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .tp-list1 {
    margin: 25px 0;
  }

  .regitser-page-div {
    padding-right: 0;
    padding-left: 0;
  }

  .notes-d-section .comon-notes {
    margin-bottom: 25px;
  }

  .request-page {
    width: 100%;
  }

  .login-page-div {
    height: 100%;
    width: 100%;
  }

  .login-page-div .inside-login {
    margin-bottom: 50px;
  }

  .mbile-divs-table {
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 30px;
    margin-top: 30px;
    display: inline-block;
    position: relative;
  }

  .mbile-divs-table h5 {
    text-transform: uppercase;
    color: #5c5f62;
  }

  .number-list {
    justify-content: flex-start;
  }

  .comon-table-td {
    color: #000000;
    text-decoration: none;
  }

  .dp-mobiles {
    position: absolute;
    right: 20px;
    top: 37px;
  }
}

@media (max-width: 480px) {
  .login-page-div {
    height: auto;
  }

  .login-page-div .inside-login {
    margin-bottom: 0;
  }

  .main-re-from-divs {
    width: 100% !important;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 60px !important;
  }

  .back-new-fun,
  .back-rgs {
    top: 84px;
    left: 6%;
  }

  .new-register-page .back-rgs {
    left: 3% !important;
  }

  .comon-dt-picker {
    margin-top: 10px;
  }

  .last-details h5 {
    text-align: left !important;
  }

  .dasb-div .select-option .flex-column {
    flex-direction: row !important;
    width: 762px;
  }

  .dasb-div .select-option .flex-column > div {
    padding-right: 3px !important;
  }

  .next-list li {
    font-size: 12px;
    color: #5c5f62;
  }

  .next-list ul {
    display: flex;
    flex-wrap: wrap;
  }

  .next-list li {
    display: none;
  }

  .more-ex-btn {
    width: 100%;
    /* margin-top: 15px; */
    margin-left: 0;
  }

  .tp-list1 li {
    font-size: 16px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .one-ex-btn {
    width: 100%;
  }

  .notes {
    flex-wrap: wrap;
  }

  .speacial-expert h2 {
    font-size: 22px;
  }

  .notes span:last-child {
    padding-left: 0;
    margin-left: 0;
    margin-top: 8px;
  }

  .next-list li a {
    text-decoration: none;
    color: #5c5f62;
    font-size: 16px;
  }

  .next-list li:first-child,
  .next-list li:last-child {
    display: block;
  }

  .add-btn-divs {
    margin-top: 10px;
  }

  .new-register-page .row > div:nth-child(2) {
    padding: 0;
  }

  .re-spi-logo {
    border-bottom: solid 1px rgba(0, 0, 0, 15%);
    padding-bottom: 20px;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 15px;
  }

  .new-register-page .conti-new {
    margin-bottom: 25px;
    width: 86%;
  }

  .regitser-page-div {
    width: 100%;
  }

  /* .regitser-page-div .logo,
  .login-page-div .logo,
  .landing-sec-rg .logo {
    margin: auto;
    text-align: center;
    border-bottom: solid 1px rgba(0, 0, 0, 15%);
    padding-bottom: 16px;
  } */
  .login-page-div {
    margin-top: 23px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }

  .login-btn1 {
    display: block;
  }

  .regitser-page-div .inside-login p {
    font-size: 16px;
  }

  .comon-fild label,
  .regitser-page-div label {
    color: #000000;
  }

  .regitser-page-div .form-control::-webkit-input-placeholder {
    color: #000;
  }

  .inside-login h4 {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    margin-top: 30px;
  }

  .inside-login {
    padding-left: 25px;
    padding-right: 15px;
  }

  .register-btn {
    display: block;
    font-size: 20px;
  }

  .inside-login p,
  .landing-sec-rg p {
    font-size: 20px;
  }

  .main-re-from-divs label {
    font-size: 18px;
  }

  .main-re-from-divs .form-control::-webkit-input-placeholder {
    color: #000;
  }

  .expert-login-page .landing-sec-rg {
    width: 100%;
  }

  .landing-sec-rg {
    margin-top: 23px !important;
  }

  .expert-login-page .login-fm-div {
    padding-left: 25px;
    padding-right: 15px;
  }

  .comon-select {
    margin-bottom: 25px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }

  .ant-btn-default {
    /* width: 198% !important; */
    /* height: 80px !important; */
    border: 3px dashed rgb(136, 124, 242) !important;
  }
}

/* .modal-body {
  padding-top: 0 !important;
} */

.comon-table-divs .dropdown .dropdown-menu {
  min-width: 6rem;
  right: 0 !important;
  transform: none !important;
  inset: auto !important;
  margin: 0 0 0 -90px !important;
}

.modalHeader {
  color: #5746eb;
  font: 18px;
  font-weight: 500;
}

.errorMsg {
  border: 1px solid transparent;
  text-align: center;
  /* margin-top: 10vh; */
}

.mainComment {
  background-color: #000;
  position: relative !important;
}

.ais-RefinementList-count {
  display: none;
}

.ais-RefinementList-list {
  height: 300px !important;
  overflow-y: auto;
  border-radius: 5px;
  margin-top: -10px;
}

.ais-RefinementList-item {
  padding: 14px;
}

.ant-upload.ant-upload-drag {
  border: 3px dashed rgb(136, 124, 242) !important;
}

.sc-bczRLJ {
  max-width: 100% !important;
  height: 130px !important;
  display: flex !important;
  flex-direction: column !important;
  padding-top: 34px;
}

.sc-bczRLJ {
  padding-top: 34px !important;
}

.ais-SearchBox-input-wrap5 .ais-RefinementList-list {
  transform: translate(-202px, 0px);
}

/* svg {
  width: 100% !important;
} */

.right-menu-sec ul li:nth-child(1) .nav-link:after {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.modal-content {
  border-radius: 1rem !important;
}

.storageInfo {
  font-size: 12px;
  margin-top: 8px;
  font-weight: 300;
  margin-left: 15px;
}

.statusBtnOffCanvas span {
  margin-right: 10px;
}

.offcanvas-header .btn-close {
  border: 2px solid;
  border-radius: 50%;
  font-size: 13px;
  padding: 0.35rem !important;
}

.comon-tabs-expert .offcanvas-end {
  border-top-left-radius: 10px;
  top: 70px;
  width: 50vw;
}

.ltsStrd {
  background: rgb(87, 70, 236, 0.1);
  padding: 10px 15px 10px 45px;
  position: relative;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  color: rgb(87, 70, 236);
  cursor: pointer;
  border: 1px solid transparent;
}

.ltsStrd.selectAll {
  color: #8c92ac;
  background: RGB(140, 146, 172, 0.1);
}

.ltsStrd:before {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid rgb(87, 70, 236, 0.3);
  position: absolute;
  left: 12px;
  top: 12px;
  border-radius: 5px;
}

.categoryInput:checked + .ltsStrd {
  background: rgb(87, 70, 236, 0.9);
  color: #fff;
  border-color: rgb(87, 70, 236);
}

.categoryInput:checked + .ltsStrd.selectAll {
  background: RGB(140, 146, 172, 0.9);
  color: #fff;
  border-color: RGB(140, 146, 172);
}

.categoryInput:checked + .ltsStrd:before {
  background-color: rgb(87, 70, 236);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
}

.categoryInput:checked + .ltsStrd.selectAll:before {
  background-color: RGB(140, 146, 172);
}

.css-codomq {
  height: 100% !important;
}

.Toastify__toast {
  border-radius: 12px !important;
  min-height: 50px !important;
}

.ck-blurred {
  height: 111px !important;
  position: relative !important;
}

.btn-light {
  background-color: #ffffff !important;
  border-color: #d9d9d9 !important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02) !important;
}

.txtGredient {
  background: -webkit-linear-gradient(120deg, #07db75 50%, #5746ec 110%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.transcriptDetails .ant-card-body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.transcriptDetails .ant-card-head {
  border-bottom: none;
  min-height: 49px !important;
  padding-left: 10px;
}

.searchPageTranscript .ant-list-items,
.searchPageTranscript .transcriptDetails,
.searchPageTranscript .sd_Sde_ {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}
.transcriptDetails {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}

.chat-container {
  height: calc(100vh - 360px) !important;
}

.chat-messages {
  height: 90%;
  max-height: 100%;
  /* max-width: 90%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-container1 {
  overflow: auto;
  height: calc(100vh - 272px);
  max-width: 700px;
  margin-bottom: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-message {
  /* margin-bottom: 10px; */
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
}

.user-message {
  background-color: #f0f5ff;
  padding: 8px;
  border-radius: 8px;
  margin-left: auto;
  /* word-break: break-all; */
  max-width: 70%;
  /* Push the AI message to the right */
}

.team-info [title] {
  pointer-events: none;
}

.bot-avatar {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  border-radius: 100%;
}
.ai-message {
  background-color: #e6f7ff85;
  padding: 8px;
  border-radius: 8px;
  margin-right: auto;
  width: 74%;
  font-size: 12px;
  /* Push the user message to the left */
}

.chat-bubble {
  background-color: #f0f5ff;
  padding: 16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

.typing {
  align-items: center;
  display: flex;
  /* height: 17px; */
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #407aee;
  /* //rgba(20,105,69,.7); */
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

.message-container {
  border-top: 1px solid #e3e8ef !important;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 0px;
  position: sticky;
  bottom: 50px;
  background-color: white;
}

.message-input {
  border-radius: 8px;
}

.send-button {
  margin-left: 10px;
}

.message-input {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding-right: 10px;
  height: 40px;
  padding: 8px 12px;
  background-color: transparent !important;
  border: 1px solid #2545d3 !important;
}

.send-button {
  background-color: #5746ec !important;
  transition: background-color 0.3s ease;
  color: #fff !important;
  border: 0;
  border-radius: 8px;
  height: 40px;
  padding: 8px 12px;
}

.chat-empty-card {
  border: 1px solid #dfe0f8;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  transition: transform 0.2s ease-in-out, border 0.2s ease-in-out;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
}

/* .chat-card .ant-card-head {
  padding: 0 15px !important;
}

.chat-card .ant-card-body {
  padding: 0 15px !important;

} */
.password-border {
  border-radius: 8px !important;
}
.chat-empty-card:hover {
  transform: scale(1);
  border: 1px solid #2545d3 !important;
}
.ck-editor__editable ul,
.ck-editor__editable ol,
#messagesEndRef ul,
#messagesEndRef ol {
  padding-left: 30px !important;
}

.ck-editor__editable ul li,
#messagesEndRef ul li {
  list-style: disc !important;
}

.ck-editor__editable ol li,
#messagesEndRef ol li {
  list-style: decimal !important;
}

.ckeditor_client .ck-editor__editable {
  height: 235px !important;
}

.ck-editor__editable {
  border-top: none !important;
}

.ck-toolbar_grouping {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ck-toolbar__items {
  box-shadow: 0 2px 2px -2px rgba(34, 47, 62, 0.1), 0 8px 8px -4px rgba(34, 47, 62, 0.07) !important;
  margin-bottom: 3px !important;
}

.ck.ck-powered-by {
  display: none !important;
}

.ck-balloon-panel {
  z-index: 9999 !important;
}

.ex_sm_wrppr ul,
.ex_sm_wrppr ol {
  padding-left: 2rem;
}

.ex_sm_wrppr ul li {
  list-style: disc;
}

.ex_sm_wrppr ol li {
  list-style: decimal;
}

.ex_sm_wrppr li {
  margin-bottom: 5px;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal-dialog-scrollable .modal-body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.labelTop .ant-row {
  display: flex;
  flex-direction: column;
}

.ant-card-head-title {
  color: #5746ec !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.projectOverview .ant-card {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 16px;
  border-radius: 8px;
}

.projectOverView p {
  font-weight: 300;
}

.projectOverView strong {
  font-weight: 500;
}

.projectOverView ul,
.projectOverView ol,
.ck-editor__editable ul,
.ck-editor__editable ol {
  padding-left: 30px;
}

.projectOverView ol li,
.projectOverView ul li,
.ck-editor__editable ul li,
.ck-editor__editable ol li {
  margin-top: 5px;
  margin-bottom: 10px;
}

.projectOverView ol li,
.ck-editor__editable ol li {
  list-style-type: numbers;
}

.projectOverView ul li,
.ck-editor__editable ul li {
  list-style-type: disc !important;
}

.ant-upload.ant-upload-drag {
  border: 1px solid lightgrey !important;
}
.custom_style ul li {
  list-style: disc;
}
