.searchInputs {
  margin-top: 105px;
  display: flex;
}

.search input {
  background-color: white;
  border: 0;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;
  padding: 15px;
  height: 30px;
  width: 300px;
}

/* .searchIcon {
  height: 60px;
  width: 50px;
  background-color: white;
  display: grid;
  place-items: center;
} */

input:focus {
  outline: none;
}
/* .searchIcon svg {
  font-size: 35px;
} */

.dataResult {
  width: 51.5rem;
  height: 200px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 8px;
  position: absolute;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 40px;
  padding-top: 13px;
  display: flex;
  align-items: center;
  color: black;
}

.dataItem p {
  margin-left: 10px;
}
.dataItem {
  text-decoration: none;
}

.dataItem:hover {
  background-color: lightgrey;
}

#clearBtn {
  cursor: pointer;
}

.higlight-text {
  color: #5746ec;
}

.dataItem b {
  color: #5746ec;
  font-weight: normal;
  font-style: italic;
}

/* .ant-select-dropdown {
  min-width: 910px !important;
} */