.linkedinBody {
  max-width: 98%;
  margin: auto;
}

.pv-top-card-profile-picture__image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.LHS {
  border: 1px solid transparent;
  width: 80%;
  word-wrap: break-word;
}

.card {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.card-body {
}

.less_profile_info {
}

.text-heading-xlarge {
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.text-heading-large {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.text-body-medium {
  font-size: 1rem;
}

.text-body-small {
  font-size: 0.875rem;
}

.t-black--light {
  color: rgba(0, 0, 0, 0.5);
}

.companies {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
}

.companies:last-child {
  border-bottom: 1px solid transparent;
  margin-bottom: 0px;
}

.companyLogo {
  display: flex;
}

.companyLogo1 {
  display: block;
}

.companyLogo img {
  width: 60px;
  height: 60px;
}

.companyLogo1 img {
  width: 60px;
  height: 60px;
}

.companyName {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  color: #2c2c2c;
}

.totalExperience {
  font-size: 14px;
  margin-bottom: 0;
}

.experienceWrapper {
  padding-left: 30px;
}

.experienceWrapper .experienceInfo {
  position: relative;
  padding-left: 50px;
  padding-bottom: 15px;
}

.experienceWrapper .experienceInfo:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  top: 8px;
  left: 0px;
}

.experienceWrapper .experienceInfo:after {
  content: "";
  height: calc(100% - 24px);
  width: 2px;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 24px;
  left: 4px;
}

.experienceWrapper .experienceInfo:last-child:after {
  content: "";
  height: calc(100% - 24px);
  width: 2px;
  background: transparent;
  position: absolute;
  top: 24px;
  left: 4px;
}

.experienceContent {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  word-break: break-word;
  white-space: break-spaces;
}

.tagInput,
.profileInds {
  align-items: flex-start;
  justify-content: space-between;
}

.tagNam {
  border: 1px solid;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 4px;
  margin-bottom: 5px;
  display: inline-block;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #fff;
  border-color: #c40a36;
  font-size: 12px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #fff;
  background-color: #c40a36;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #c40a36;
}

