.tp-list1 {
  margin-left: 0;
}

.tp-list1 li .las,
.next-list .las {
  font-size: 24px;
  color: #5746ec;
}

.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 2.55rem !important;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.trans-search {
  border: solid 2px #f33636 !important;
  height: 43px !important;
  border-radius: 30px !important;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  overflow: hidden;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 7%);
  font-size: 14px;
  border: 1px solid #dfdfdf;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.dropdown-list {
  height: 300px !important;
  overflow-y: auto;

}

.clock-wrapper {
  overflow-y: auto;
}

.clock-wrapper .clock-row, .clock-wrapper .clock-row .card {
    width: 180px;
}