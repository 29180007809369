.ex_sm_wrppr_pa {
  background-color: #fff;
  padding: 0 60px 60px;
  font-family: "EB Garamond", serif;
}

.ex_sm_wrppr .nx_logo {
  max-width: 300px;
  width: 100%;
}

.ex_sm_wrppr .tbl_wrpr {
  border: 1px solid #c6c6c6;
  font-family: "EB Garamond", serif;
}

.ex_sm_wrppr .tbl_wrpr .tbl .col:first-child {
  border-right: 1px solid #c6c6c6;
  font-family: "EB Garamond", serif;
}

.ex_sm_wrppr .tbl_wrpr .tbl {
  font-weight: 400;
  font-size: 16px;
}

.ex_sm_wrppr .tbl_wrpr .tbl .flex_Hd {
  color: #c41c36;
  font-weight: 600;
  background-color: #f2f2f2;
  flex: 0 0 auto;
  width: 300px;
  font-family: "EB Garamond", serif;
}

.ex_sm_wrppr .tbl_wrpr .tbl .flex_SHD {
  flex: 0 0 auto;
  width: 200px;
}

.ex_sm_wrppr .tbl_wrpr .tbl {
  border-bottom: 1px solid #c6c6c6;
  margin: 0 !important;
}

.ex_sm_wrppr .tbl_wrpr .tbl .col {
  /* padding: 10px */
}

.ex_sm_wrppr .tbl_wrpr .tbl:last-child {
  border-bottom: 0;
}

.ex_sm_wrppr_pa .ftr_lg {
  width: 40px;
  position: absolute;
  margin-left: auto;
  right: 0;
  top: 10px;
}

/* .ex_sm_wrppr_pa td {
  font-family: "EB Garamond", serif;
}

.ex_sm_wrppr_pa h3,
.ex_sm_wrppr_pa h5 {
  font-family: "EB Garamond", serif;
}

.ex_sm_wrppr_pa h3 {
  font-size: 18px;
  margin-top: 20px;
}

.ex_sm_wrppr_pa h5 {
  font-size: 15px;
}

.ex_sm_wrppr_pa p,
.ex_sm_wrppr_pa strong,
.ex_sm_wrppr_pa span,
.ex_sm_wrppr_pa li {
  font-family: "EB Garamond", serif;
  font-size: 14px;
} */
