@media (min-width: 991px){
    .clock-wrapper .clock-row, .clock-wrapper .clock-row .card {
        width: 100%;
    }
}

@media (max-width: 1440px){
    .ais-Menu-searchBox .ais-SearchBox-form,
    .ais-RefinementList-searchBox .ais-SearchBox-form {
        height: 2.6rem;
        width: 10.51rem;
    }
}

@media (max-width: 1200px) {
    .content-wrapper {
        padding-left: 0px;
    }

    .mainMenuUL li {
        margin-bottom: 20px;
    }

    .mainMenuUL li h5 {
        font-size: 16px;
    }

    .mobileMenuBody .mainMenuUL li a {
        display: block;
        margin-bottom: 10px;
        width: 100%;
        color: #000;
    }

    .mobileMenuBody .mainMenuUL li a .menu-icon-new{
        font-size: 22px;
        margin-right: 10px;
    }

    .mobileMenuBody .mainMenuUL li a .menu-text {
        font-size: 16px;
    }
    
    .body-expart-div,
    .customized-app,
    .select-option {
        padding-left: 20px;
        padding-right: 20px;
    }

    .mainComment2 {
        height: calc(100vh - 220px);
    }
}