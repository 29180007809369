a {
  text-decoration: none !important;
}

.findExperts.comon-divs-re {
  width: 100%;
  padding-right: 20px;
}

.findExperts.comon-divs-re .linkH5 {
  width: 100%;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-item:focus,
.dropdown-item:active {
  background-color: #e9ecef !important;
}

.coverInnerBreadcum {
  display: flex;
  width: 70%;
}

.coverInnerBreadcum h3 {
  font-size: 20px;
  margin-bottom: 0;
}

.coverInnerBreadcumH3:hover {
  color: #5746ec;
  cursor: pointer;
}

.offcanvas-header .btn-close {
  margin: unset !important;
}

.width20 {
  width: 20px;
  height: 20px;
}

.findExperts .next-list li {
  margin-right: 6px;
}

.find-expert .table {
  min-width: 100%;
  max-width: 1500px;
  border-collapse: separate;
  border-spacing: 0px;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #d7d4d4;
}

.table > :not(caption) > * > * {
  border-bottom-width: 10px !important;
  border-bottom-color: #f8f8f8 !important;
}

.table tr th {
  /* border-top: 1px solid #d7d4d4; */
  border-bottom: 1px solid #d7d4d4;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
}

.table tr td {
  vertical-align: middle;
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.table tr td:first-child {
  padding-left: 30px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 20px;
}

.table tr th:first-child {
  padding-left: 30px;
}

.table tr td:last-child {
  padding-right: 30px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 20px;
}

.table tr th:last-child {
  padding-right: 30px;
}

.table tr td .projectTitle {
  margin-top: 0;
  margin-bottom: 7px;
  font-weight: 300;
  font-size: 18px;
  color: #000;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.table tr td .createdDate {
  color: #5746ec;
  font-size: 13px;
  font-weight: 300;
}

.table tr td ul {
  padding-bottom: 8px;
  margin-bottom: 0;
}

.table tr td p {
  margin-bottom: 0;
}

.table .number-member h6 {
  font-weight: 400;
}

.table tr td .dropdown img {
  width: 3px;
}

.notesParent {
}

.notesParent .comon-notes {
  padding: 15px;
  height: 25.5rem;
}

.notesParent .paragraph {
  height: 207px;
  font-size: 14px;
  margin-top: 20px;
  overflow: hidden;
}

.notesParent .createdByName,
.folderSection .createdByName {
  color: #5746ec;
  text-transform: capitalize;
}

.notesParent .note-titel,
.folderSection .note-titel {
  height: 55px;
  margin-bottom: 0;
  font-size: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 28px;
}

.folderSection {
}

.folderSection .paragraph {
  height: 200px;
  margin-top: 10px;
}

.commentsT {
  width: 35px;
  height: 35px;
  background-color: #f33636;
  border-radius: 50%;
  position: relative;
}

.commentsT span {
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 600;
  top: 50%;
  transform: translate(0px, -50%);
}

.actionNav {
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
}

.actionNav img {
  width: 18px;
}

.linkH5 {
  cursor: pointer;
  font-size: 16px !important;
  line-height: 22px;
}

.findExperts .expertRate {
  font-size: 24px;
}

.linkH5:hover {
  color: #5746ec;
}

.projectTitle {
  cursor: pointer;
}

.projectTitle:hover {
  color: #5746ec;
}

.mainComment {
  background-color: #fff;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  /* overflow: auto; */
  height: calc(100vh - 290px);
  margin-bottom: 0;
}

.mainComment .adminMessage {
  width: 100%;
}

.messageScreen {
  position: relative;
  height: 100vh;
  padding-top: 20px;
}

.mainComment .adminMessage .sdee {
  margin-left: 14px;
  width: 70%;
  position: relative;
  margin-bottom: 20px;
  float: left;
}

.mainComment .adminMessage .msgContent img {
  position: absolute;
  left: 0;
  width: 35px;
  top: 0px;
}

.mainComment .adminMessage .msgContent .markup-para {
  margin-left: 50px;
  text-align: justify;
  background-color: #a4a5a929;
  padding: 10px;
  border-radius: 10px;
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
}

.mainComment a {
  color: #5746ec;
  text-decoration: none;
}

.mainComment .adminMessage .msgContent p {
  margin-bottom: 0;
}

.mainComment .adminMessage small {
  margin-left: 50px;
}

.mainComment .clientMessage {
  width: 100%;
}

.mainComment .clientMessage .sdee {
  position: relative;
  /* grid-template: 239px; */
  width: 70%;
  float: right;
  margin-bottom: 20px;
  max-width: 80%;
  width: auto;
}

.mainComment .clientMessage .msgContent .senderName {
  position: absolute;
  right: 20px;
  width: 35px;
  top: 0px;
}

.mainComment .clientMessage .msgContent .SentByClient {
  margin-right: 70px;
  text-align: justify;
  background-color: #c40a360f;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  margin-bottom: 5px;
  color: #000;
  display: block;
}

.mainComment .clientMessage .msgContent p {
  margin-bottom: 0;
}

.modal-title h4 {
  font-size: 1.25rem;
}

/* .modal-body{
    padding-bottom: 0 !important;
} */

.modal-body p {
  font-size: 1rem !important;
}

/* Share Modal Css */

.modalWrapper {
  background: #ededf0;
  border-radius: 4px;
  color: #000;
  padding: 12px 16px;
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkChild {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 5px;
}

.linkTitle {
}

.copyLink {
}

.shareableLink {
  width: 100%;
  height: 42px;
  border: 1px solid #d7d4d4;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 5px;
}

.inviteSection .inviteTitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.inviteSection .inputGroups {
  background: #fff !important;
  border: none;
  padding: 0;
}

.inviteSection .dropdownBtn {
  background: transparent;
  border: none;
  color: #000;
  font-size: 14px;
  padding: 0 20px;
}

.inviteSection .dropdownBtn:active,
.inviteSection .dropdownBtn:focus,
.inviteSection .dropdownBtn:hover {
  background: transparent;
  color: #000;
}

.inviteSection .dropdown-item {
  color: #000;
  font-size: 14px;
}

.inviteSection .dropdownBtn:after {
  margin-left: 1.255em;
}

.inviteSection .inputGroupWrppr {
  width: 100%;
  height: 42px;
  border: 1px solid #d7d4d4;
  font-size: 14px;
  border-radius: 5px;
}

.inviteSection .inputGroupInput {
  height: 40px;
  border: unset;
  font-size: 14px;
}

.accessInfo {
  display: flex;
  border: none;
  background: transparent;
  font-size: 15px;
  align-items: center;
  padding: 8px 0;
  width: 100%;
  border-bottom: 1px solid #d7d4d4;
}

.sharedInfoWrapper {
}

.sharedInfoWrapper .sharedInfoChild {
}

.sharedInfoWrapper .sharedInfoProfile {
}

.sharedInfoWrapper .emailSection {
}

.sharedInfoWrapper .nameInfo {
  font-size: 15px;
  font-weight: 500;
}

.sharedInfoWrapper .emailInfo {
  font-size: 13px;
  color: #5c5f62;
}

.sharedInfoWrapper .profileIconWrppr {
  display: flex;
  align-items: center;
}

.sharedInfoWrapper .profileIcon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-size: 40px;
  background-position: center;
}

.sharedInfoWrapper .shareWrppr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #d7d4d4;
}

/* .Yourr_9w71MjtL3S9I4G .dropdownBtn,
.Yourr_9w71MjtL3S9I4G .dropdownBtn:active,
.Yourr_9w71MjtL3S9I4G .dropdownBtn:focus,
.Yourr_9w71MjtL3S9I4G .dropdownBtn:hover,
.show .btn-primary {
  background-color: transparent !important;
  border: none !important;
  color: #000 !important;
} */

.Yourr_9w71MjtL3S9I4G .dropdown-toggle::after {
  position: relative;
  top: 3px;
}

.Yourr_9w71MjtL3S9I4G .dropdown-item {
  font-size: 15px;
}

.ZP9BDnxHhGA9rSLoFsa3 {
  padding: 10px;
  margin-bottom: -14px;
  text-align: right;
}

.ZP9BDnxHhGA9rSLoFsa3 .cDCnsp1vcViX1pVJM7MV {
  background: transparent;
  border: none;
  color: #5746ec;
}

.findExperts .find_companyName {
  font-size: 19px;
  width: 100%;
  font-weight: 500;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize; */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.noResultFound {
  max-width: 700px;
  margin: auto;
}

.noResultFound img {
}

.noResultFound h3 {
}

.browseTopRated {
  font-size: 18px;
  font-weight: 300;
}

.number-list {
  padding-top: 6px;
}

.messageEditorWrapper {
  background-color: rgb(255, 255, 255);
  position: absolute;
  /* bottom: 10px; */
  width: 100%;
}

.offcanvas-body {
  padding: 1rem 2rem !important;
}

.offcanvas-header {
  padding: 1rem 2rem 1rem 1.5rem !important;
}

.topmenu1 {
  width: 100%;
  position: relative;
  display: block;
}

.rangeSliderRefinement {
  height: 200px !important;
}

[class*="sendbird-label"] {
  font-family: "Work Sans", sans-serif !important;
}

.sendbird-theme--light .sendbird-text-message-item-body.outgoing {
  background-color: #c40a360f !important;
  color: #000;
  line-height: 25px;
}

.sendbird-label--h-2 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.sendbird-label--button-1,
.sendbird-label--subtitle-2 {
  font-weight: 500 !important;
}

.sendbird-label--subtitle-1 {
  font-size: 14px !important;
}

.sendbird-badge .sendbird-badge__text {
  margin: -3px 6px;
}

.sendbird-icon-toggle-off svg .icon-toggleoff_svg__fill {
  fill: #e0e0e0 !important;
}

.sendbird-icon-toggle-off .icon-toggleoff_svg__fill {
  fill: #e0e0e0 !important;
}

.sendbird-channel-settings__accordion-icon svg,
.sendbird-channel-settings__panel-icon-left svg {
  height: 80% !important;
}

.sendbird-chat-header {
  background-color: #ededf0 !important;
  height: 55px !important;
}

.sendbird-channel-preview {
  margin-top: 12px !important;
  height: 64px !important;
  box-shadow: none !important;
  padding: 12px 12px 12px 7px !important;
  border: none !important;
}

.sendbird-channel-preview__content__upper {
  width: unset !important;
}

.sendbird-channel-header__title__left {
  padding: 4px 0 !important;
}

.sendbird-channel-header__title:hover {
  background-color: transparent !important;
}

.sendbird-avatar-img,
.sendbird-avatar,
.sendbird-avatar-img--default,
.sendbird-channel-preview .sendbird-channel-preview__avatar,
.sendbird-avatar-img .sendbird-image-renderer__image {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  min-width: 40px !important;
}

.sendbird-channel-preview
  .sendbird-channel-preview__content
  .sendbird-channel-preview__content__lower {
  margin-top: 4px !important;
}

.sendbird-theme--light .sendbird-avatar .sendbird-avatar-img {
  background-color: transparent !important;
}

.sendbird-channel-preview__content {
  margin-left: 8px !important;
}

.sendbird-text-message-item-body__message.edited {
  color: darkgray !important;
}

.sendbird-channel-preview--active {
  border: 1px solid #5746ec !important;
}

.sendbird-conversation__footer {
  padding: 10px 0px 0px 0px !important;
}

.sendbird-theme--light .sendbird-chat-header {
  box-shadow: unset !important;
}

.sendbird-place-holder,
.sendbird-conversation__messages .sendbird-conversation__messages-padding {
  background-color: #fff !important;
  margin-top: 10px !important;
}

.sendbird-button--danger,
.sendbird-button--primary {
  background-color: #5746ec !important;
  border-color: #5746ec !important;
}

.sendbird-icon-color--primary svg path {
  fill: #5746ec !important;
}

.sendbird-theme--light
  .sendbird-message-input
  .sendbird-message-input--textarea:focus {
  border: solid 1px #5746ec !important;
}

.sendbird-message-input__edit {
  background-color: #c40a360f !important;
}

.body-expart-div,
.select-option,
.customized-app {
  padding-left: 20px;
  padding-right: 20px;
}

.select-option .card-dropdown {
  width: 100%;
}

@media only screen and (max-width: 1900px) {
  .select-option .upperULSelectOne {
    padding-left: 0;
  }

  .select-option .ais-RefinementList-list {
    width: 26.9%;
  }

  .select-option .input_Range_Slider {
    padding-left: 50px;
    padding-right: 50px;
    position: absolute;
    width: 100%;
    top: 23%;
  }

  .select-option .input_Range_Slider .DefaultProgressBar_progressBar {
    height: 5px;
  }

  .select-option .input_Range_Slider .DefaultHandle_handle {
    border-radius: 50%;
  }

  .select-option .input_Range_Slider .DefaultHandle_handle__horizontal {
    top: -9px;
  }

  .select-option .input_Range_Slider .DefaultBackground_background__horizontal {
    height: 9px;
    border-radius: 5px;
  }

  .select-option .input_Range_Slider .rheostat-value {
    width: 75px;
  }
}

@media only screen and (max-width: 1440px) {
  .select-option .ais-RefinementList-list {
    width: 24.4%;
  }
}

@media only screen and (max-width: 1366px) {
  .select-option .ais-RefinementList-list {
    width: 23.7%;
  }
}

.createNotesEditor {
  position: relative;
}

.createNotesEditor .jodit-toolbar__box:not(:empty) {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  top: unset;
  z-index: 9;
}

.createNotesEditor .jodit-container:not(.jodit_inline) {
  border: none;
}

.createNotesEditor .jodit-status-bar {
  display: none;
}

.createNotesEditor .jodit-wysiwyg_mode,
.createNotesEditor .jodit-workplace {
  min-height: calc(100vh - 480px) !important;
}

.createNotesEditor .jodit-react-container {
  background-color: #ededf0;
  border-radius: 10px;
  padding: 10px;
}

.jodit-toolbar-editor-collection_mode_horizontal:after {
  background-color: transparent !important;
}

.nav-pills li {
  padding: 3px 1.5px;
}

.nav-pills li:first-child {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 1.5px;
  padding-left: 3px;
}

.nav-pills li:last-child {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 1.5px;
  padding-right: 3px;
}

.sendbird-place-holder {
  height: calc(100% - 230px) !important;
}

.ais-SearchBox-input {
  box-shadow: none;
  border: none;
}

.stylishEditor {
  border-radius: 10px;
  padding-bottom: 9px;
}

.commentTable {
}

.commentTable .tableHe {
  color: grey;
  font-weight: 500;
  margin-bottom: 20px;
  display: inline-block;
  width: 50%;
}

.commentTable .tableCo {
  font-weight: 500;
  margin-bottom: 20px;
  display: inline-block;
  color: #000;
  width: 50%;
}

.commentBody .userImage {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.createNotesEditor.commentSection .jodit-wysiwyg_mode,
.createNotesEditor.commentSection .jodit-workplace {
  min-height: calc(100vh - 680px) !important;
}

.ant-checkbox-wrapper span,
.locationSpeed span {
  text-transform: capitalize;
}

.transcriptNew {
  /* border-radius: 15px; */
  background-color: #fff;
}

.tras_smmry {
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 15px;
  margin-top: 2em;
}

.fw-bolder {
  font-weight: 500 !important;
}

.transcriptNew:hover {
  background-color: #fff;
}

.transcriptNew .fileIcons {
  color: #8294a5;
  font-size: 35px;
  cursor: pointer;
}

.transcriptNew .pContent {
  cursor: pointer;
}

.transcriptNew .pContent p {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
  color: #000;
}

.transcriptNew .pContent li {
  margin-right: 20px;
  display: flex;
  align-items: center;
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
}

.transcriptNew .moreIcon {
  font-size: 20px;
  cursor: pointer;
}

.moreOptions {
  background-color: transparent !important;
  color: #000;
  border: none !important;
  font-size: 25px;
  padding: 0;
}

.moreOptions svg {
  width: 1em !important;
  height: 1em !important;
}

.moreOptions:hover,
.moreOptions:focus,
.moreOptions:active {
  background-color: transparent !important;
  box-shadow: none !important;
  outline: none !important;
  color: #000 !important;
}

.moreOptions:hover .moreIcon {
  color: #000;
}

.moreOptions:after {
  content: unset;
}

.form-group .css-b62m3t-container {
  width: 100%;
}

.sendBttnMessage {
  position: absolute;
  z-index: 9;
  font-size: 20px;
  height: 30px;
  border-radius: 50px;
  width: 30px;
  border: none;
  background: #5746ec;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 6px;
  right: 10px;
}

.ckeditor_ProjectDetails .ck-editor__editable {
  height: 110px;
}

.createBttnNotes {
  position: absolute;
  top: 5px;
  right: 18px;
  z-index: 9;
  width: 120px;
}

.createBttnNotes button {
  width: 100%;
}

.speakerName {
  position: absolute;
  width: 30px;
  height: 30px;
  color: maroon;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 400;
}

.successModal {
  width: 100%;
  background-color: rgb(87, 70, 236);
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  display: block;
  padding: 8px;
  border-radius: 30px;
  margin-top: 50px;
}

.error.successModal {
  background-color: #df1e1e;
}

.successModal:hover {
  color: #fff;
}

.projectOverView {
}

.projectOverView ul {
  padding-left: 30px;
}

.projectOverView ul li {
  margin-bottom: 10px;
  list-style-type: disc;
  font-weight: 400;
}

.chatWithUs {
  position: fixed;
  bottom: 20px;
  right: 100px;
}

.chatWithUs img {
  height: 70px;
  text-align: right;
  margin-right: auto;
  transform: scaleX(-1) rotate(90deg) translate(0px, 100px);
}

.csvlink {
  color: white;
}

.css-1py0z5p {
  height: calc(100vh - 360px) !important;
}

.antDBttn {
  background-color: #5746ec !important;
  color: #fff !important;
  border-radius: 8px !important;
}

.antDBttn:hover {
  background-color: transparent !important;
  color: #5746ec !important;
}

.secondaryBttn {
  background-color: transparent;
  color: #5746eb;
  border: 1px solid #5746eb;
  font-weight: 500;
}

.bt_modal .modal-title h2 {
  font-size: 20px !important;
  font-weight: 500;
}

.bt_modal .modal-title p {
  font-size: 14px;
  font-weight: 400;
}

.bt_modal .form-group {
  margin-bottom: 15px;
}

.bt_modal label {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 16px;
  color: #000;
}
