.main-message{
    webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
}

.FullPageMessage-Message {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 300px;
    text-align: center;
}

.FullPageMessage-Message {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
}

.mb2 {
    margin-bottom: 8px;
}

.Text-color--gray400 {
    color: hsla(0,0%,10%,.5);
}

.Text-fontWeight--500 {
    font-weight: 500;
}

.Text-fontSize--16 {
    font-size: 16px;
}
.Text {
    margin: 0;
}

.mb3 {
    margin-bottom: 12px;
}

.FullPageMessage-Message {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 300px;
    text-align: center;
}

.Text-color--gray400 {
    color: hsla(0,0%,10%,.5);
}
.Text-fontWeight--400 {
    font-weight: 400;
}
.Text-fontSize--13 {
    font-size: 13px;
}

.Text {
    margin: 0;
}

.Link--primary {
    color: #5746ec;
}
.Link {
    cursor: pointer;
    text-decoration: none;
}
a {
    background-color: transparent;
}